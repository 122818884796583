import apiClient from 'services/apiClient';

import removeEmpty from '../utils/removeEmpty';

export async function getUserInfo() {
  return apiClient
    .get(`/user-info`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function getPermissions() {
  return apiClient
    .get(`/permissions`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function grantOperation(params: object) {
  const reqParams = removeEmpty(params);

  return apiClient
    .post(`/permissions/operations/grant`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function revokeOperation(params: object) {
  const reqParams = removeEmpty(params);

  return apiClient
    .post(`/permissions/operations/revoke`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function grantResource(params: object) {
  const reqParams = removeEmpty(params);

  return apiClient
    .post(`/permissions/resources/grant`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function revokeResource(params: object) {
  const reqParams = removeEmpty(params);

  return apiClient
    .post(`/permissions/resources/revoke`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
