import { TextBody } from '@cbhq/cds-web/typography';
import React, { ReactNode } from 'react';

type Props = {
  outerText: ReactNode;
  innerText: ReactNode;
};

const LabelBody = ({ outerText, innerText }: Props) => {
  return (
    <TextBody testID="label-caption" as="p">
      <strong>{outerText}: </strong>
      {innerText}
    </TextBody>
  );
};

export default LabelBody;
