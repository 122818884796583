export const SET_STATE = 'clearing/SET_STATE';
export const LOAD_CLEARING_DATA = 'clearing/LOAD_CLEARING_DATA';
export const LOAD_CLEARING_DATA_FAILED = 'clearing/LOAD_CLEARING_DATA_FAILED';
export const SHOW_CREATE_NCP_MODAL = 'clearing/SHOW_CREATE_NCP_MODAL';
export const HIDE_CREATE_NCP_MODAL = 'clearing/HIDE_CREATE_NCP_MODAL';
export const SHOW_CREATE_ITM_MODAL = 'clearing/SHOW_CREATE_ITM_MODAL';
export const HIDE_CREATE_ITM_MODAL = 'clearing/HIDE_CREATE_ITM_MODAL';
export const ADD_NEW_ITM_DETAILS = 'clearing/ADD_NEW_ITM_DETAILS';
export const ADD_NEW_NCP_DETAILS = 'clearing/ADD_NEW_NCP_DETAILS';
export const ADD_NEW_ITM_FULL_NAME = 'clearing/ADD_NEW_ITM_FULL_NAME';
export const ADD_NEW_NCP_FEE_GROUP = 'clearing/ADD_NEW_NCP_FEE_GROUP';
export const RESEND_PENDING_TRADES = 'clearing/RESEND_PENDING_TRADES';
export const RESEND_REJECTED_TRADES = 'clearing/RESEND_REJECTED_TRADES';
export const EDIT_REJECTED_TRADES = 'clearing/EDIT_REJECTED_TRADES';
export const EDIT_RESEND_TRADE = 'clearing/EDIT_RESEND_TRADE';
export const SHOW_EDIT_REJECTED_TRADE_MODAL = 'clearing/SHOW_EDIT_REJECTED_TRADE_MODAL';
export const HIDE_EDIT_REJECTED_TRADE_MODAL = 'clearing/HIDE_EDIT_REJECTED_TRADE_MODAL';

export interface CreateModalActionPayload {
  mappingType: string;
  showCreateMappingModal: boolean;
}

export interface ResendPendingTradesPayload {
  tradeIds: string[];
  resendAll: boolean;
}

interface SetClearingStateAction {
  type: typeof SET_STATE;
  payload: object;
}

interface LoadClearingDataAction {
  type: typeof LOAD_CLEARING_DATA;
  payload: object;
}

interface ShowCreateModalAction {
  type: typeof SHOW_CREATE_NCP_MODAL | typeof SHOW_CREATE_ITM_MODAL;
  payload: CreateModalActionPayload;
}

interface HideCreateModalAction {
  type: typeof HIDE_CREATE_NCP_MODAL | typeof HIDE_CREATE_ITM_MODAL;
  payload: CreateModalActionPayload;
}

export interface AddNewMappingAction {
  type: typeof ADD_NEW_ITM_DETAILS | typeof ADD_NEW_NCP_DETAILS;
  payload: object;
}

export interface AddNcpFeeGroup {
  type: typeof ADD_NEW_NCP_FEE_GROUP;
  payload: object;
}

export interface AddItmFullName {
  type: typeof ADD_NEW_ITM_FULL_NAME;
  payload: object;
}

export interface ResendPendingTrades {
  type: typeof RESEND_PENDING_TRADES;
  payload: ResendPendingTradesPayload;
}

export interface ResendRejectedTrades {
  type: typeof RESEND_REJECTED_TRADES;
  payload: string[];
}

export interface EditRejectedTrades {
  type: typeof EDIT_REJECTED_TRADES | typeof EDIT_RESEND_TRADE;
  payload: object;
}

interface ShowEditRejectedTradeModal {
  type: typeof SHOW_EDIT_REJECTED_TRADE_MODAL;
  payload: object;
}

interface HideEditRejectedTradeModal {
  type: typeof HIDE_EDIT_REJECTED_TRADE_MODAL;
  payload: object;
}

const actions = {
  SET_STATE,
  LOAD_CLEARING_DATA,
  LOAD_CLEARING_DATA_FAILED,
  SHOW_CREATE_ITM_MODAL,
  HIDE_CREATE_ITM_MODAL,
  SHOW_CREATE_NCP_MODAL,
  HIDE_CREATE_NCP_MODAL,
  ADD_NEW_ITM_DETAILS,
  ADD_NEW_NCP_DETAILS,
  ADD_NEW_NCP_FEE_GROUP,
  ADD_NEW_ITM_FULL_NAME,
  RESEND_PENDING_TRADES,
  RESEND_REJECTED_TRADES,
  EDIT_REJECTED_TRADES,
  EDIT_RESEND_TRADE,
  SHOW_EDIT_REJECTED_TRADE_MODAL,
  HIDE_EDIT_REJECTED_TRADE_MODAL,
};

export type ClearingAction =
  | SetClearingStateAction
  | LoadClearingDataAction
  | ShowCreateModalAction
  | HideCreateModalAction
  | AddNewMappingAction
  | AddItmFullName
  | AddNcpFeeGroup
  | ResendPendingTrades
  | ResendRejectedTrades
  | EditRejectedTrades
  | ShowEditRejectedTradeModal
  | HideEditRejectedTradeModal;

export default actions;
