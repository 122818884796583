import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actions, { SET_STATE } from 'redux/instruments/actions';
import editInstruments from 'services/editInstruments';
import getInstruments from 'services/getInstruments';
import { InstrumentInfo } from 'state/instrumentPageState';

import { setLoadingSpinner, unsetLoadingSpinner } from '../loadingSpinners';
import { RootState } from '../reducers';
import { setErrorMessage, setMessage } from '../setMessage';

type Params = {
  type: typeof actions.LOAD_INSTRUMENTS;
  payload: object;
};

export function* LOAD_INSTRUMENTS({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);
  const { response, error } = yield call(getInstruments, payload);
  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        instruments:
          response.data.length <= 1
            ? response.data
            : response.data.sort((a: any, b: any) => a.instrumentId - b.instrumentId),
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* EDIT_INSTRUMENTS({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);

  const { error, response } = yield call(editInstruments, payload);
  if (!error) {
    const state: RootState = yield select();
    const {
      instrumentIds,
      priceBand,
      priceBandMultiplier,
      lastEndOfDaySettlementPrice,
      maxOrderSize,
      marketOrderProtectionTicks,
      initialMargin,
      lowLimitPercentage,
      lowLimitPrice,
      highLimitPrice,
      highLimitPercentage,
    } = payload as any;
    const updatedInstruments = state.instruments.instruments.map((instr: InstrumentInfo) => {
      if (instrumentIds.includes(instr.instrumentId)) {
        return {
          ...instr,
          ...(priceBand && { priceBand }),
          ...(priceBandMultiplier && { priceBandMultiplier }),
          ...(lastEndOfDaySettlementPrice && { lastEndOfDaySettlementPrice }),
          ...(maxOrderSize && { maxOrderSize }),
          ...(marketOrderProtectionTicks && { marketOrderProtectionTicks }),
          ...(initialMargin && { initialMargin }),
          ...(lowLimitPercentage && { lowLimitPercentage }),
          ...(lowLimitPrice && { lowLimitPrice }),
          ...(highLimitPrice && { highLimitPrice }),
          ...(highLimitPercentage && { highLimitPercentage }),
        };
      }
      return instr;
    });

    yield put({
      type: actions.SET_STATE,
      payload: {
        instruments: updatedInstruments,
      },
    });
    yield setMessage(response, `Edited instruments ${instrumentIds}`);
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_INSTRUMENTS, LOAD_INSTRUMENTS),
    takeEvery(actions.EDIT_INSTRUMENTS, EDIT_INSTRUMENTS),
  ]);
}
