import '@cbhq/cds-web/globalStyles';
import '@cbhq/cds-web/globalStyles';

// app styles
import './global.scss';

import { ThemeProviderBaseProps } from '@cbhq/cds-web';
import { PortalProvider } from '@cbhq/cds-web/overlays/PortalProvider';
import { ThemeProvider } from '@cbhq/cds-web/system/ThemeProvider';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import Router from 'router';

import reducers from './redux/reducers';
import sagas from './redux/sagas';
import * as serviceWorker from './serviceWorker';

// middleware
const history = createBrowserHistory({});
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware];
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }
// prettier-ignore
// @ts-ignore
const composeEnhancers = process.env.NODE_ENV === 'development' ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose) : compose;
const store = createStore(reducers(history), composeEnhancers(applyMiddleware(...middlewares)));

sagaMiddleware.run(sagas);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history} />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
export { store, history };
