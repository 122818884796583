import { Auth0Client } from '@auth0/auth0-spa-js';
import { Auth0Config } from 'services/auth';

import {
  AUTH0_CONFIG_LOADED,
  AUTH0_LOADED,
  SetAuth0ClientAction,
  SetAuth0ConfigAction,
  SetUserStateAction,
  USER_PROFILE_LOADED,
} from './actions';

export type UserState = {
  authenticated: boolean;
  loading: boolean;
  auth0?: Auth0Client;
  auth0Config?: Auth0Config;
  profile: object;
  token?: string;
};

const initialState: UserState = {
  authenticated: false,
  loading: true,
  auth0: undefined,
  auth0Config: undefined,
  profile: {},
  token: undefined,
};

export default function authReducer(
  state: UserState = initialState,
  action: SetUserStateAction | SetAuth0ClientAction | SetAuth0ConfigAction,
) {
  switch (action.type) {
    case USER_PROFILE_LOADED:
      return { ...state, ...(action as SetUserStateAction).user };
    case AUTH0_LOADED:
      return { ...state, auth0: (action as SetAuth0ClientAction).auth0 };
    case AUTH0_CONFIG_LOADED:
      return { ...state, auth0Config: (action as SetAuth0ConfigAction).auth0Config };
    default:
      return state;
  }
}
