import { all } from 'redux-saga/effects';
import flashMessage from 'redux/application/sagas';

import activeConnections from './activeConnections/sagas';
import applicationSagas from './application/sagas';
import audit from './audit/sagas';
import user from './auth/sagas';
import clearing from './clearing/sagas';
import positions from './clearingPositions/sagas';
import events from './events/sagas';
import fills from './fills/sagas';
import firms from './firms/sagas';
import holidays from './holidays/sagas';
import instruments from './instruments/sagas';
import lmm from './lmm/sagas';
import menu from './menu/sagas';
import orderBook from './orderBook/sagas';
import orderDetails from './orderDetails/sagas';
import orders from './orders/sagas';
import ordersFills from './ordersFills/sagas';
import positionLimitSubscriptions from './positionLimitSubscriptions/sagas';
import products from './products/sagas';
import settings from './settings/sagas';
import settlements from './settlements/sagas';
import userManagement from './userManagement/sagas';
import userPermissions from './userPermissions/sagas';
import users from './users/sagas/userSagas';

export default function* rootSaga() {
  yield all([
    orders(),
    orderDetails(),
    ordersFills(),
    user(),
    fills(),
    firms(),
    users(),
    products(),
    instruments(),
    clearing(),
    positions(),
    positionLimitSubscriptions(),
    settlements(),
    userManagement(),
    holidays(),
    flashMessage(),
    applicationSagas(),
    menu(),
    settings(),
    audit(),
    userPermissions(),
    activeConnections(),
    orderBook(),
    events(),
    lmm(),
  ]);
}
