import { AuditState } from './reducers';

export const SET_STATE = 'audit/SET_STATE';
export const LOAD_AUDIT = 'audit/LOAD_AUDIT';

interface SetAuditStateAction {
  type: typeof SET_STATE;
  payload: Partial<AuditState>;
}

interface LoadAuditAction {
  type: typeof LOAD_AUDIT;
  payload: object;
}

const actions = {
  SET_STATE,
  LOAD_AUDIT,
};

export type AuditAction = SetAuditStateAction | LoadAuditAction;

export default actions;
