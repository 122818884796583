import { OrderStatus, STATUS } from 'fairxConstants';
// eslint-disable-next-line
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { setLoadingSpinner, unsetLoadingSpinner } from 'redux/loadingSpinners';
import { setErrorMessage } from 'redux/setMessage';
import cancelOrder from 'services/cancelOrder';
import getOrders from 'services/getOrders';
import { Order } from 'state/ordersPageState';

import { RootState } from '../reducers';
import actions, { SET_STATE } from './actions';

type Params = {
  type: typeof actions.LOAD_ORDERS;
  payload: object;
};

export function* LOAD_ORDERS({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(getOrders, payload);
  if (!error) {
    const nonWorkingEvenTypes: string[] = [STATUS.CANCELED, STATUS.FILLED, STATUS.REJECTED];
    const nonCanceledOrders: Order[] = [];
    const canceledOrders: Order[] = [];

    response.data.forEach((order: Order) => {
      if (nonWorkingEvenTypes.includes(order.eventType)) {
        canceledOrders.push(order);
      } else {
        nonCanceledOrders.push(order);
      }
    });
    yield put({
      type: actions.SET_STATE,
      payload: {
        orders: [...nonCanceledOrders, ...canceledOrders] as Array<Order>,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* CANCEL_ORDER({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);

  const { error } = yield call(cancelOrder, payload);
  if (!error) {
    const state: RootState = yield select();
    const updatedState = state.orders.orders.map((ord: Order) => {
      if (ord.id === (payload as any).orderId) {
        return { ...ord, orderStatus: OrderStatus.DONE, eventType: STATUS.CANCELED };
      }
      return ord;
    });
    yield put({
      type: actions.SET_STATE,
      payload: {
        orders: updatedState as Order[],
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      showCancelOrderModal: false,
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_ORDERS, LOAD_ORDERS),
    takeEvery(actions.CANCEL_ORDER, CANCEL_ORDER),
  ]);
}
