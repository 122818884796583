export enum MarketStatEnum {
  Exchange = 'INTERNAL',
  Index = 'EXTERNAL',
  Manual = 'OVERRIDE',
}

export const INTRA_DAY = 'INTRA_DAY';
export const END_OF_DAY = 'END_OF_DAY';
export const FINAL = 'FINAL';
export type SettlementType = string | undefined;

export const getCalculationMethod = (method: string) => {
  switch (method) {
    case MarketStatEnum.Exchange: {
      return 'Exchange Derived';
    }
    case MarketStatEnum.Index: {
      return 'Index Derived';
    }
    case MarketStatEnum.Manual: {
      return 'Manual';
    }
    default: {
      return '';
    }
  }
};

export type Settlement = {
  instrumentId: number;
  symbol: string;
  settlementPrice?: number;
  marketStatSource: MarketStatEnum;
  indexPrice?: number;
  bid?: number;
  offer?: number;
  open?: number;
  high?: number;
  low?: number;
  close?: number;
  vwapPrice: number;
  volume: number;
  percentChange?: number;
  prevDaySettlement?: number;
  timestamp: number;
  openInterest: number;
  settlementType: SettlementType;
};

export enum Settlements {
  endOfDay = 'End Of Day',
  intraday = 'Intraday',
  final = 'Final',
}
