export const SET_STATE = 'userManagement/SET_STATE';
export const LOAD_PERMISSIONS = 'userManagement/LOAD_PERMISSIONS';
export const LOAD_PERMISSIONS_FAILED = 'userManagement/LOAD_PERMISSIONS_FAILED';
export const GRANT_RESOURCE = 'userManagement/GRANT_RESOURCE';
export const REVOKE_RESOURCE = 'userManagement/REVOKE_RESOURCE';
export const GRANT_OPERATION = 'userManagement/GRANT_OPERATION';
export const REVOKE_OPERATION = 'userManagement/REVOKE_OPERATION';

export interface SetUserManagementStateAction {
  type: typeof SET_STATE;
  payload: object;
}

export interface LoadPermissionsAction {
  type: typeof LOAD_PERMISSIONS;
  payload: object;
}

export interface GrantResourceAction {
  type: typeof GRANT_RESOURCE;
  payload: object;
}

export interface RevokeResourceAction {
  type: typeof REVOKE_RESOURCE;
  payload: object;
}

export interface GrantOperationAction {
  type: typeof GRANT_OPERATION;
  payload: object;
}

export interface RevokeOperationAction {
  type: typeof REVOKE_OPERATION;
  payload: object;
}

const actions = {
  SET_STATE,
  LOAD_PERMISSIONS,
  LOAD_PERMISSIONS_FAILED,
  GRANT_RESOURCE,
  REVOKE_RESOURCE,
  GRANT_OPERATION,
  REVOKE_OPERATION,
};

export type UserManagementAction =
  | SetUserManagementStateAction
  | LoadPermissionsAction
  | GrantResourceAction
  | RevokeResourceAction
  | GrantOperationAction
  | RevokeOperationAction;

export default actions;
