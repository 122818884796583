import { all, call, put, takeEvery } from 'redux-saga/effects';
import { setLoadingSpinner, unsetLoadingSpinner } from 'redux/loadingSpinners';
import { setErrorMessage, setMessage } from 'redux/setMessage';
import {
  bulkTransferPortfolio,
  positionList,
  tradesList,
  transferPortfolio,
} from 'services/clearingPositions';
import { ClearingPositions, ClearingTrades } from 'state/clearingPositionsState';

import actions, { SET_STATE } from './actions';

type Params = {
  type: typeof actions.LOAD_CLEARING_POSITIONS | typeof actions.LOAD_TRADES;
  payload: object;
};

type TransferPayload = {
  values: object;
  positionType: string;
};

type TransferPortfolioParams = {
  type: typeof actions.TRANSFER_PORTFOLIO;
  payload: TransferPayload;
};

export function* loadClearingPositions({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);
  const { response, error } = yield call(positionList, payload);
  const clearingPositions = (response.data || []).map((po: ClearingPositions, index: any) => ({
    ...po,
    key: index,
  }));

  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        positions: clearingPositions,
      },
    });
  } else {
    yield setErrorMessage(error);
  }

  yield unsetLoadingSpinner(SET_STATE);
}

export function* loadTrades({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(tradesList, payload);
  const clearingTrades = (response.data || []).map((tr: ClearingTrades, index: any) => ({
    ...tr,
    key: index,
  }));

  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        trades: clearingTrades,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* transferPortfolioSaga({ payload }: TransferPortfolioParams) {
  yield setLoadingSpinner(SET_STATE);

  const { error } = yield call(transferPortfolio, payload.values);
  if (error) {
    yield setErrorMessage(error);
  }

  yield unsetLoadingSpinner(SET_STATE);
}

export function* bulkTransferPortfolioSaga({ payload }: any) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(bulkTransferPortfolio, payload.values);
  if (!error) {
    yield setMessage(response, `Successfully transferred ${payload.positionType}`, '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CLEARING_POSITIONS, loadClearingPositions),
    takeEvery(actions.LOAD_TRADES, loadTrades),
    takeEvery(actions.TRANSFER_PORTFOLIO, transferPortfolioSaga),
    takeEvery(actions.BULK_TRANSFER_PORTFOLIO, bulkTransferPortfolioSaga),
  ]);
}
