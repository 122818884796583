import removeEmpty from '../utils/removeEmpty';
import apiClient from './apiClient';

export async function updateFirmDetails(params: any) {
  const reqParams = removeEmpty(params);

  const { id } = params;
  return apiClient
    .post(`/firms/${id}`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function addFirmDetails(params: any) {
  const reqParams = removeEmpty(params);

  return apiClient
    .post(`/firms`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
