import { Button } from '@cbhq/cds-web/buttons';
import { Divider, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextTitle4 } from '@cbhq/cds-web/typography/TextTitle4';
import { DEFAULT_PAGE_ROWS } from 'fairxConstants';
import React, { useEffect, useState } from 'react';

import ButtonList from '../ButtonList';
import { AgGridWrapper } from './AgGridWrapper';

const Table = (props) => {
  const {
    defaultColDef,
    title,
    buttons = [],
    columns,
    data = [],
    loading,
    fieldId,
    className = '',
    setExportData,
    rowSelection = null,
    cellSelection,
    scroll = null,
    showClearFiltersButton,
    pagination,
    summaryData,
    hideColumn,
    height,
    isRowSelectable,
    onRowClicked,
  } = props;

  const [filtersData, setFiltersData] = useState({});
  const [filtersList, setFiltersList] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);

  useEffect(() => {
    const filteredColumns = props.columns.filter((column) => column);
    const fieldName = filteredColumns && filteredColumns[0].field ? 'field' : 'dataIndex';
    const newTableColumns = filteredColumns.map((column) => {
      column.filteredValue =
        filtersData[column[fieldName]] || filtersData[column[fieldName]] || null;
      return column;
    });
    const newFiltersList = [];
    newTableColumns.forEach((column) => {
      if (filtersData[column[fieldName]]) {
        newFiltersList.push(
          `${column.fullTextTitle || column.title} (${filtersData[column[fieldName]].join(', ')})`,
        );
      }
    });
    setFiltersList(newFiltersList);
    setTableColumns(newTableColumns);
  }, [filtersData, props]);

  const clearFilters = () => {
    const newTableColumns = props.columns.map((column) => {
      column.filteredValue = null;
      return column;
    });
    setTableColumns(newTableColumns);
    setFiltersList([]);
  };

  return (
    <VStack bordered height="100%" width="100%">
      <HStack spacing={2} width="100%">
        <div style={{ flex: 1 }}>
          <TextTitle4 as="p">{title}</TextTitle4>
        </div>
        {showClearFiltersButton && (
          <Button
            key={99}
            onPress={clearFilters}
            variant="primary"
            disabled={filtersList.length === 0}
          >
            Clear filters
          </Button>
        )}
        {buttons && <ButtonList buttons={buttons} />}
      </HStack>
      <Divider direction="horizontal" width="100%" />
      <HStack spacing={5} height="80%" width="100%">
        <TextTitle4 as="p">
          {filtersList.length > 0 && `Filters: ${filtersList.join(' | ')}`}
        </TextTitle4>
        <AgGridWrapper
          defaultColDef={defaultColDef}
          className={`table ${className}`}
          columns={tableColumns}
          rowData={data}
          loading={loading}
          rowSelection={rowSelection}
          cellSelection={cellSelection}
          pagination={pagination}
          paginationPageSize={DEFAULT_PAGE_ROWS}
          setExportData={setExportData}
          summaryData={summaryData}
          hideColumn={hideColumn}
          height={height}
          isRowSelectable={isRowSelectable}
          onRowClicked={onRowClicked}
        />
      </HStack>
    </VStack>
  );
};

export default Table;
