import apiClient from 'services/apiClient';
import removeEmpty from 'utils/removeEmpty';

export default async function getProductsInstrumentsInformation(params: object) {
  const reqParams = removeEmpty(params);
  const { expiredFilter } = reqParams;

  return apiClient
    .get(`/product-instrument-info?expiredFilter=${expiredFilter}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
