import { LeadMarketMakerUser } from 'state/lmmPageState';
import { Status } from 'state/sharedEnums';

import apiClient from './apiClient';

export async function getAllLeadMarketMakerUsers() {
  return apiClient
    .get('/lead-market-maker-users/')
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

// TODO: Would be a nice feature to add in the future.
export async function getLeadMarketMakerUsersByProductAndTradingUser(params?: {
  productId?: number;
  tradingUserId?: number;
}) {
  const updatedParam = {
    productId: (params || {}).productId,
    tradingUserId: (params || {}).tradingUserId,
  };
  return apiClient
    .get(`/lead-market-maker-users/${updatedParam.productId}/${updatedParam.tradingUserId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function addLeadMarketMakerUser(params: {
  productId: number;
  tradingUserId: number;
  status?: Status;
}) {
  return apiClient
    .post('/lead-market-maker-users', params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function deleteLeadMarketMakerUser(params: { lmmu: LeadMarketMakerUser }) {
  const { lmmu } = params;
  return apiClient
    .post(`/lead-market-maker-users/${lmmu.id}/delete/`, lmmu)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
