import { put } from 'redux-saga/effects';

import generalActions from './application/actions';

const HTTP_STATUS_CODE_OK = 200;
const HTTP_STATUS_CODE_UNAUTHORIZED = 401;
const HTTP_STATUS_CODE_BAD_REQUEST = 400;
const HTTP_STATUS_CODE_GATEWAY_TIMED_OUT = 504;

const HTTP_STATUS_CODE_NAMES = new Map([
  [HTTP_STATUS_CODE_GATEWAY_TIMED_OUT, 'Gateway Timed out'],
  [HTTP_STATUS_CODE_BAD_REQUEST, 'Bad Request'],
]);

export function* setFlashMessage(payload: object) {
  yield put({
    type: generalActions.SET_FLASH_MESSAGE,
    payload,
  });
}

function getErrorMessage(response: any) {
  const url = `${response.config.baseURL}${response.config.url.replace('/', '')}`;
  const message = `${
    response.statusText === '' ? HTTP_STATUS_CODE_NAMES[response.status] : response.statusText
  } ( ${response.status} ) at URL ${url}`;
  const description = `Description: "${
    typeof response.data === 'string' ? response.data : response.data.message || response.data.title
  }"`;

  return { flashMessage: { message, description, type: 'error' } };
}

export function* setMessage(response: any, message?: string, description?: string, type?: string) {
  if (
    response.status !== HTTP_STATUS_CODE_OK ||
    response.status !== HTTP_STATUS_CODE_UNAUTHORIZED
  ) {
    yield setFlashMessage(getErrorMessage(response));
  }
  if (response.status === HTTP_STATUS_CODE_OK) {
    yield setFlashMessage({ flashMessage: { message, description, type } });
  }
}

export function* setErrorMessage(error: any) {
  const { response } = error;

  if (response && response.status !== HTTP_STATUS_CODE_UNAUTHORIZED) {
    yield setFlashMessage(getErrorMessage(response));
  }
}
