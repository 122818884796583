import removeEmpty from '../utils/removeEmpty';
import apiClient from './apiClient';

export async function getHolidayList() {
  return apiClient
    .get('/holidays')
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function addNewHoliday(params: object) {
  const reqParams = removeEmpty(params);
  return apiClient
    .post(`/holidays`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
