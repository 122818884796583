import apiClient from 'services/apiClient';

import removeEmpty from '../utils/removeEmpty';

export default async function addSelfMatchPreventions(params: object) {
  const reqParams = removeEmpty(params);

  return apiClient
    .post(`/self-match-preventions/`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
