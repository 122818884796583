// eslint-disable-next-line
import { Order } from 'state/ordersPageState';

import actions, { OrderAction } from './actions';

export interface OrdersState {
  loading: boolean;
  orders: Array<Order>;
  showCancelOrderModal: boolean;
  selectedOrders: Array<Order>;
}

export const initialState: OrdersState = {
  loading: false,
  orders: [],
  showCancelOrderModal: false,
  selectedOrders: [],
};

export default function ordersReducer(
  state: OrdersState = initialState,
  action: OrderAction,
): OrdersState {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SHOW_MODAL:
      return { ...state, showCancelOrderModal: true, ...action.payload };
    case actions.HIDE_MODAL:
      return { ...state, showCancelOrderModal: false, ...action.payload };
    default:
      return state;
  }
}
