import removeEmpty from '../utils/removeEmpty';
import apiClient from './apiClient';

export default async function getInstruments(params: object) {
  const reqParams = removeEmpty(params);
  const { expiredFilter } = reqParams;

  return apiClient
    .post(`/instruments/details?expiredFilter=${expiredFilter}`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
