import clearingApiClient from './clearingApiClient';

export async function positionList(params: object) {
  return clearingApiClient
    .post(`/positions/list`, params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function tradesList(params: object) {
  return clearingApiClient
    .post(`/positions/trades`, params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function transferPortfolio(params: object) {
  return clearingApiClient
    .post(`/positions/transfer`, params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function bulkTransferPortfolio(params: []) {
  return clearingApiClient
    .post(`/positions/bulk-transfer`, params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
