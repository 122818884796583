import { OrderDetails } from 'state/orderDetailsPageState';

import actions, { OrderDetailsAction } from './actions';

export interface OrderDetailsState {
  loading: boolean;
  orderId: number;
  events: OrderDetails[];
}

export const initialState: OrderDetailsState = {
  loading: false,
  orderId: 0,
  events: [],
};

export default function orderDetailsReducer(
  state: OrderDetailsState = initialState,
  action: OrderDetailsAction,
): OrderDetailsState {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
