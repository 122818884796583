import actions, { HolidaysAction } from 'redux/holidays/actions';
import { Holiday } from 'state/holidayState';

export interface HolidaysState {
  loading: boolean;
  holidays: Holiday[];
}

export const initialState: HolidaysState = {
  loading: false,
  holidays: [],
};

export default function holidaysReducer(
  state: HolidaysState = initialState,
  action: HolidaysAction,
): HolidaysState {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
