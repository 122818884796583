// Application pages
export const ORDERS_PAGE = '/fairx/orders';
export const ORDER_DETAILS_PAGE = '/fairx/order-details';
export const FILLS_PAGE = '/fairx/fills';
export const ORDERS_FILLS_PAGE = '/fairx/orders-fills';
export const PRODUCTS_PAGE = '/fairx/products';
export const PRODUCT_DETAILS_PAGE = '/fairx/product-details';
export const INSTRUMENTS_PAGE = '/fairx/instruments';
export const INSTRUMENT_DETAILS_PAGE = '/fairx/instrument-details';
export const USERS_PAGE = '/fairx/users';
export const FIRMS_PAGE = '/fairx/firms';
export const CLEARING_PAGE = '/fairx/clearing';
export const POSITIONS_PAGE = '/fairx/positions';
export const POSITION_LIMIT_SUBSCRIPTIONS_PAGE = '/fairx/position-limit-subscriptions';
export const SETTLEMENTS_PAGE = '/fairx/settlements';
export const AUDIT_PAGE = '/fairx/audit';
export const ACTIVE_CONNECTIONS_PAGE = '/fairx/active-connections';
export const USER_MANAGEMENT_PAGE = '/fairx/user-management';
export const ACTIVE_CONNECTIONS = '/fairx/active-connections';
export const ORDER_BOOK_PAGE = '/fairx/order-book';
export const TRANSITIONS_PAGE = '/fairx/transitions';
export const EVENT_VIEWER = '/fairx/event-viewer';
export const ORDER_BOOK_RECREATION_PAGE = '/fairx/order-book-recreation';
export const ADMIN_CLI = '/fairx/admin-cli';
export const LMM = '/fairx/lmm';

export const DASHBOARD_ANALYTICS_PAGE = '/dashboard/analytics';

// Systems pages
export const LOGIN_PAGE = '/login';
export const FORGOT_PASSWORD_PAGE = '/auth/forgot-password';
export const REGISTER_PAGE = '/auth/register';
export const LOCKSCREEN_PAGE = '/auth/lockscreen';
export const NOT_FOUND_ERROR_PAGE = '/system/404';
export const INTERNAL_ERROR_PAGE = '/system/500';

export const SYSTEM_PAGES = {
  LOGIN_PAGE,
  FORGOT_PASSWORD_PAGE,
  REGISTER_PAGE,
  LOCKSCREEN_PAGE,
  NOT_FOUND_ERROR_PAGE,
  INTERNAL_ERROR_PAGE,
};
export const PAGES = {
  ORDERS_PAGE,
  ORDER_DETAILS_PAGE,
  FILLS_PAGE,
  ORDERS_FILLS_PAGE,
  PRODUCTS_PAGE,
  PRODUCT_DETAILS_PAGE,
  INSTRUMENTS_PAGE,
  INSTRUMENT_DETAILS_PAGE,
  FIRMS_PAGE,
  USERS_PAGE,
  CLEARING_PAGE,
  POSITIONS_PAGE,
  POSITION_LIMIT_SUBSCRIPTIONS_PAGE,
  SETTLEMENTS_PAGE,
  AUDIT_PAGE,
  ACTIVE_CONNECTIONS_PAGE,
  USER_MANAGEMENT_PAGE,
  DASHBOARD_ANALYTICS_PAGE,
  ORDER_BOOK_PAGE,
  TRANSITIONS_PAGE,
  EVENT_VIEWER,
  ORDER_BOOK_RECREATION_PAGE,
  ADMIN_CLI,
  LMM,
  ...SYSTEM_PAGES,
};

export const LANDING_PAGE = ORDERS_PAGE;
