import { STATUS } from 'fairxConstants';
import * as Eff from 'redux-saga/effects';
import { setLoadingSpinner, unsetLoadingSpinner } from 'redux/loadingSpinners';
import { setErrorMessage } from 'redux/setMessage';
import amendFills from 'services/amendFills';
import cancelFills from 'services/cancelFills';
import getFills from 'services/getFills';
import { Fill, FillsState } from 'state/fillPageState';

import { RootState } from '../reducers';
import actions, { AmendTradesAction, SET_STATE } from './actions';

const takeEvery: any = Eff.takeEvery;
const call: any = Eff.call;
const put: any = Eff.put;
const select: any = Eff.select;
const all: any = Eff.all;

type Params = {
  type: typeof actions.LOAD_FILLS | typeof actions.AMEND_TRADE;
  payload: object;
};

export function* LOAD_FILLS({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);
  const { response, error } = yield call(getFills, payload);
  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        fills: response.data,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* AMEND_TRADE({ payload }: AmendTradesAction) {
  yield setLoadingSpinner(SET_STATE);
  const { error } = yield call(amendFills, payload);
  if (!error) {
    const state: RootState = yield select();
    const { matchId, fillPrice, leg1fillPrice, leg2fillPrice } = payload as any;
    const updatedFills = (state as any).fills.fills.map((fill: Fill) => {
      if (fill.matchId === matchId) {
        return {
          ...fill,
          price: fillPrice,
          ...(leg1fillPrice &&
            leg2fillPrice && {
              leg1Price: leg1fillPrice,
              leg2Price: leg2fillPrice,
            }),
        };
      }
      return fill;
    });

    yield put({
      type: actions.SET_STATE,
      payload: {
        showAmendBustModal: false,
        currentFill: undefined,
        fills: updatedFills,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* CANCEL_TRADE({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);

  const { error } = yield call(cancelFills, payload);
  if (!error) {
    const state: RootState = yield select();
    const updatedFills = (state.fills as FillsState).fills.map((fill: Fill) => {
      if (fill.matchId === (payload as any).matchId) {
        return { ...fill, status: STATUS.CANCELED };
      }

      return fill;
    });
    yield put({
      type: actions.SET_STATE,
      payload: {
        fills: updatedFills,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      showCancelTradeBustModal: false,
      currentFill: {},
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FILLS, LOAD_FILLS),
    takeEvery(actions.AMEND_TRADE, AMEND_TRADE),
    takeEvery(actions.CANCEL_TRADE, CANCEL_TRADE),
  ]);
}
