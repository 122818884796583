import { MarketOrderBook, OrderBook } from 'state/orderBookPageState';

import actions, { OrderBookAction } from './actions';

export interface OrderBookState {
  loading: boolean;
  orderBook: OrderBook[];
  marketOrderBook: MarketOrderBook[];
}

export const initialState: OrderBookState = {
  loading: false,
  orderBook: [],
  marketOrderBook: [],
};

export default function orderBookReducer(
  state: OrderBookState = initialState,
  action: OrderBookAction,
): OrderBookState {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
