import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actions, {
  AddItmFullName,
  AddNcpFeeGroup,
  AddNewMappingAction,
  EditRejectedTrades,
  ResendPendingTrades,
  ResendRejectedTrades,
  SET_STATE,
} from 'redux/clearing/actions';
import { ClearingState } from 'redux/clearing/reducers';
import { setLoadingSpinner, unsetLoadingSpinner } from 'redux/loadingSpinners';
import { setErrorMessage, setMessage } from 'redux/setMessage';
import { addItm, addItmDetails, addNcp, addNcpFeeGroup } from 'services/accountMapping';
import {
  editRejectedTrades,
  resendPendingTrades,
  resendRejectedTrades,
} from 'services/clearingTrades';
import getClearingData from 'services/getClearingData';
import {
  MappingType,
  TradeReport,
  TradeReportStatus,
  TradeReports,
} from 'state/clearingDataPageState';
import numberComparator from 'utils/comparators/numberComparator';

import { RootState } from '../reducers';

const updateTrades: Function = (state: ClearingState, data: object) => {
  const changedTradeIndex = state.rejectedTrades.findIndex(
    (r) => r.trade.tradeId === (data as TradeReport).trade.tradeId,
  );
  const rejectedTrades = state.rejectedTrades.map((tr, index) =>
    index === changedTradeIndex ? data : tr,
  );
  const newState = {
    ...state,
    rejectedTrades,
    tradeReports: { ...state.tradeReports, [(data as any).trade.tradeId]: data },
  };

  return newState;
};

export function* loadClearingDataSaga() {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(getClearingData);
  if (!error) {
    const { ncpList, itmList, itmDetailsList, ncpFeeGroupList, trades } = response;
    const tradeReports: TradeReports = trades.reason ? 'Error' : trades;
    const tradeReportArr: TradeReport[] = Object.values(tradeReports) || [];
    const rejectedTrades: TradeReport[] = tradeReportArr
      .filter((tr: TradeReport) => tr.status === TradeReportStatus.Rejected)
      .map((tr: TradeReport) => ({ ...tr, key: String(tr.trade.tradeId) }));
    const pendingTrades: TradeReport[] = tradeReportArr
      .filter((tr: TradeReport) =>
        [TradeReportStatus.Pending, TradeReportStatus.Queued].includes(tr.status),
      )
      .map((tr: TradeReport) => ({ ...tr, key: String(tr.trade.tradeId) }));

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        tradeReports,
        rejectedTrades,
        pendingTrades,
        ncpList: ncpList.reason
          ? 'Error'
          : ncpList.sort((a: any, b: any) =>
              numberComparator(a.lastUpdatedTime, b.lastUpdatedTime, 'desc'),
            ),
        itmList: itmList.reason
          ? 'Error'
          : itmList.sort((a: any, b: any) =>
              numberComparator(a.lastUpdatedTime, b.lastUpdatedTime, 'desc'),
            ),
        itmDetailsList: itmDetailsList.reason ? 'Error' : itmDetailsList,
        ncpFeeGroupList: ncpFeeGroupList.reason ? 'Error' : ncpFeeGroupList,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* addNewMappingSaga({ payload }: AddNewMappingAction) {
  const [feeGroup, fullName] = [(payload as any).feeGroup, (payload as any).fullName];
  const isNcp: boolean = (payload as any).mappingType === MappingType.NCP;
  const mappingCreateService = isNcp ? addNcp : addItm;
  const { response, error } = yield call(mappingCreateService, payload);

  const { name } = response.data;

  if (!error) {
    const state: RootState = yield select();
    const { clearing }: { clearing: ClearingState } = state;

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        showCreateMappingModal: false,
        mappingType: '',
        ...(isNcp && {
          ncpList: [...clearing.ncpList, response.data].sort((a: any, b: any) =>
            numberComparator(a.lastUpdatedTime, b.lastUpdatedTime, 'desc'),
          ),
        }),
        ...(!isNcp && {
          itmList: [...clearing.itmList, response.data].sort((a: any, b: any) =>
            numberComparator(a.lastUpdatedTime, b.lastUpdatedTime, 'desc'),
          ),
        }),
      },
    });
    yield setMessage(
      response,
      `Added new ${(payload as any).mappingType} ${(payload as any).name}`,
      '',
      'info',
    );
    if (isNcp) {
      yield put({
        type: actions.ADD_NEW_NCP_FEE_GROUP,
        payload: { ncpCode: name, feeGroup },
      });
    }

    if (!isNcp) {
      yield put({
        type: actions.ADD_NEW_ITM_FULL_NAME,
        payload: { code: name, name: fullName },
      });
    }
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* addNewNcpFeeGroupSaga({ payload }: AddNcpFeeGroup) {
  const { error } = yield call(addNcpFeeGroup, payload);

  if (error) {
    yield setErrorMessage(error);
  }
}

export function* addNewItmFullNameSaga({ payload }: AddItmFullName) {
  const { error } = yield call(addItmDetails, payload);

  if (error) {
    yield setErrorMessage(error);
  }
}

export function* resendPendingTradesSaga({ payload }: ResendPendingTrades) {
  const { error } = yield call(resendPendingTrades, payload);

  if (error) {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* resendRejectedTradesSaga({ payload }: ResendRejectedTrades) {
  const { error } = yield call(resendRejectedTrades, payload);

  if (error) {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* editRejectedTradeSaga({ payload }: EditRejectedTrades) {
  const { response, error } = yield call(editRejectedTrades, payload);

  if (!error) {
    const state: RootState = yield select();
    const newState = updateTrades(state.clearing, response.data);
    yield put({
      type: actions.SET_STATE,
      payload: {
        ...newState,
        showEditRejectedTradeModal: false,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* editAndResendRejectedTradeSaga({ payload }: EditRejectedTrades) {
  const { response, error } = yield call(editRejectedTrades, payload);

  if (!error) {
    const { tradeId: editedTradeId } = response.data.trade;
    const { error: resendRejectedTradeError } = yield call(resendRejectedTrades, [editedTradeId]);

    if (!resendRejectedTradeError) {
      const state: RootState = yield select();
      const newState = updateTrades(state.clearing, response.data);

      yield put({
        type: actions.SET_STATE,
        payload: {
          ...newState,
          showEditRejectedTradeModal: false,
        },
      });
    } else {
      yield setErrorMessage(resendRejectedTradeError);
    }
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CLEARING_DATA, loadClearingDataSaga),
    takeEvery(actions.ADD_NEW_ITM_DETAILS, addNewMappingSaga),
    takeEvery(actions.ADD_NEW_NCP_DETAILS, addNewMappingSaga),
    takeEvery(actions.ADD_NEW_NCP_FEE_GROUP, addNewNcpFeeGroupSaga),
    takeEvery(actions.ADD_NEW_ITM_FULL_NAME, addNewItmFullNameSaga),
    takeEvery(actions.RESEND_PENDING_TRADES, resendPendingTradesSaga),
    takeEvery(actions.RESEND_REJECTED_TRADES, resendRejectedTradesSaga),
    takeEvery(actions.EDIT_REJECTED_TRADES, editRejectedTradeSaga),
    takeEvery(actions.EDIT_RESEND_TRADE, editAndResendRejectedTradeSaga),
  ]);
}
