import Loader from 'components/layout/Loader';
import NProgress from 'nprogress';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AppLayout from './App';
import AuthLayout from './Auth';
import PublicLayout from './Public';

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  app: AppLayout,
};

@withRouter
@connect(({ user }) => ({ user }))
class Layout extends React.PureComponent {
  previousPath = '';

  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
    } = this.props;
    const {
      location: { pathname: prevPathname },
    } = prevProps;
    if (pathname !== prevPathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      user,
    } = this.props;

    // NProgress Management
    const currentPath = pathname + search;
    if (currentPath !== this.previousPath) {
      NProgress.start();
    }

    setTimeout(() => {
      NProgress.done();
      this.previousPath = currentPath;
    }, 300);

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/' || /^\/(system)(?=\/|$)/i.test(pathname)) {
        return 'public';
      }
      if (/^\/(auth|login)(?=\/|$)/i.test(pathname)) {
        return 'auth';
      }
      return 'app';
    };

    const Container = Layouts[getLayout()];
    // const isUserAuthorized = user && user.authenticated
    // const isAuthLayout = getLayout() === 'auth'

    const BootstrappedLayout = () => {
      // show loader when user in check authorization process, not authorized yet and not on login pages
      if (!user.authenticated && user.loading) {
        return <Loader />;
      }
      // redirect to login page if current is not login page and user not authorized
      // if (!isAuthLayout && !isUserAuthorized) {
      //   return <></>
      // }
      // in other case render previously set layout
      return <Container>{children}</Container>;
    };

    return (
      <Fragment>
        <Helmet
          titleTemplate="%s | Coinbase Derivatives Admin"
          title="Coinbase Derivatives Admin"
        />
        {BootstrappedLayout()}
      </Fragment>
    );
  }
}

export default Layout;
