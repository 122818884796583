import { AdminUserRole } from '../../fairxConstants';
import actions, { UserPermissionsAction } from './actions';

export type UserPermissions = {
  adminUserRole: AdminUserRole;
  adminUserId?: number;
  adminOperations: string[];
  failed?: boolean;
};

const initialState: UserPermissions = {
  adminUserRole: 'TRADING_USER',
  adminUserId: 0,
  adminOperations: [],
  failed: false,
};

export default function userPermissionsReducer(
  state: UserPermissions = initialState,
  action: UserPermissionsAction,
) {
  switch (action.type) {
    case actions.USER_PERMISSIONS_SUCCESS:
      return { ...state, ...action.payload };
    case actions.USER_PERMISSIONS_FAIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
