import { ForwardedRef, SharedProps } from '@cbhq/cds-common/types';
import { NavigationIconProps } from '@cbhq/cds-web/icons/NavigationIcon';
import { HStack } from '@cbhq/cds-web/layout/HStack';
import { useSidebarContext } from '@cbhq/cds-web/navigation/SidebarContext';
import { Pressable } from '@cbhq/cds-web/system/Pressable';
import { TextHeadline } from '@cbhq/cds-web/typography';
import React, { ReactNode, forwardRef, memo } from 'react';

export type SidebarItemProps = {
  icon?: NavigationIconProps['name'];
  imgSrcPath?: string;
  title: string;
  onPress?: () => void;
  collapsed?: boolean;
  active?: boolean;
  tooltipContent?: ReactNode;
} & SharedProps;

const SidebarItemWrapper = memo(
  forwardRef(
    (
      { icon, title, onPress, collapsed, active, testID, imgSrcPath }: SidebarItemProps,
      ref: ForwardedRef<HTMLButtonElement>,
    ) => {
      const color = active ? 'primary' : 'foreground';
      const { collapsed: computedCollapsed } = useSidebarContext();
      const isCollapsed = collapsed ?? computedCollapsed;

      const content = (
        <Pressable
          backgroundColor="primaryWash"
          borderRadius="rounded"
          transparentWhileInactive={!active}
          as="button"
          onPress={onPress}
          width="100%"
          ref={ref}
          testID={testID}
        >
          <HStack gap={2} spacing={2} alignItems="center">
            {imgSrcPath && (
              <img alt={title} src={imgSrcPath} style={{ height: '24px', width: '24px' }} />
            )}
            {!isCollapsed && (
              <TextHeadline as="h2" color={color}>
                {title}
              </TextHeadline>
            )}
          </HStack>
        </Pressable>
      );

      return content;
    },
  ),
);

export default SidebarItemWrapper;
