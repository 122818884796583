import { Auth0Client } from '@auth0/auth0-spa-js';
import { Auth0Config } from 'services/auth';

import { UserState } from './reducers';

export const USER_PROFILE_LOADED = 'USER_PROFILE_LOADED';
export const AUTH0_LOADED = 'AUTH0_LOADED';
export const AUTH0_CONFIG_LOADED = 'AUTH0_CONFIG_LOADED';
export const HANDLE_AUTHENTICATION_CALLBACK = 'HANDLE_AUTHENTICATION_CALLBACK';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export interface SetUserStateAction {
  type: typeof USER_PROFILE_LOADED;
  user: Partial<UserState>;
}

export interface SetAuth0ClientAction {
  type: typeof AUTH0_LOADED;
  auth0: Auth0Client;
}

export interface SetAuth0ConfigAction {
  type: typeof AUTH0_CONFIG_LOADED;
  auth0Config: Auth0Config;
}
