import { NoopFn } from '@cbhq/cds-web';
import { DataType } from 'components/common/formWrapper/types';
import { HTMLAttributes } from 'react';

export enum Status {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Deleted = 'DELETED',
}
export enum UserRole {
  Trader = 'TRADER',
  MarketMaker = 'MARKET_MAKER',
}
export enum FirmRole {
  FCM = 'FCM',
  TradingFirm = 'TRADING_FIRM',
}

export enum ResourceType {
  FCM = 'FCM',
  FIRM = 'FIRM',
  TRADING_USER = 'TRADING_USER',
  ACCOUNT = 'ACCOUNT',
}

export enum DropCopySubscriptionType {
  None = 'NONE',
  Trades = 'TRADES_ONLY',
  All = 'ALL_MESSAGES',
}
export enum AdminUserRole {
  Exchange = 'EXCHANGE',
  ExchangeRead = 'EXCHANGE_READ',
  Firm = 'FIRM',
  FCM = 'FCM',
  TradingUser = 'TRADING_USER',
}

export enum ExpiredFilter {
  NONE = 'NONE',
  TWO_WEEKS = 'TWO_WEEKS',
  TRADE_WEEK = 'TRADE_WEEK',
  NON_EXPIRED = 'NON_EXPIRED',
}

export enum FillReason {
  AGGRESSOR = 'AGGRESSOR',
  LMM = 'LMM',
  FIFO = 'FIFO',
}

export enum SubscriptionLevel {
  Firm = 'FIRM',
  FCM = 'FCM',
  TradingUser = 'TRADING_USER',
}

export type DateTime = {
  date: Date;
  time: Time;
};

export type Date = {
  year: number;
  month: number;
  day: number;
};

export type Time = {
  hour: number;
  minute: number;
  second: number;
};

export interface WrapperProps extends HTMLAttributes<any> {
  onFinish?: (values: any) => void | Function;
}

export type Values = {
  type: string;
  payload: object;
};

export enum Clearing {
  TRADES = 'Trades',
  ACCOUNT_MAPPING = 'Account Mapping',
}

export const noop: NoopFn = () => {};

// Helper functions for the above Enums.
export const getResourceTypeFromFirmRole = (firmRole: FirmRole | undefined) => {
  if (firmRole === FirmRole.TradingFirm) {
    return ResourceType.FIRM;
  }
  if (firmRole === FirmRole.FCM) {
    return ResourceType.FCM;
  }
  return undefined;
};
export const getResourceTypeFromAdminUserRole = (adminUserRole: AdminUserRole | undefined) => {
  if (adminUserRole === AdminUserRole.Firm) {
    return ResourceType.FIRM;
  }
  if (adminUserRole === AdminUserRole.FCM) {
    return ResourceType.FCM;
  }
  if (adminUserRole === AdminUserRole.TradingUser) {
    return ResourceType.TRADING_USER;
  }
  return undefined;
};

export const nonDisplayedField = {
  dataType: DataType.NULL,
  name: 'NULL',
};
