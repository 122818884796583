import { NoopFn } from '@cbhq/cds-web';
import { Button } from '@cbhq/cds-web/buttons/Button';
import { Modal, ModalBody, ModalBodyProps, ModalFooter, ModalHeader } from '@cbhq/cds-web/overlays';
import React, { ReactNode } from 'react';

type PopupProps = {
  visible: boolean;
  onClose: NoopFn;
  title: string;
  onCancel: React.MouseEventHandler<Element> | NoopFn;
  onConfirm: React.MouseEventHandler<Element> | NoopFn;
  primaryText?: string;
  secondaryText?: string;
  showButtons: boolean;
};

const Popup = ({
  visible,
  onClose,
  title,
  children,
  onCancel,
  onConfirm,
  primaryText,
  secondaryText,
  showButtons,
}: PopupProps & { children: ReactNode }) => {
  return (
    <>
      <Modal visible={visible} onRequestClose={onClose}>
        <ModalHeader title={title} />
        <ModalBody>{children}</ModalBody>
        {showButtons && (
          <ModalFooter
            primaryAction={
              <Button compact onPress={onConfirm}>
                {!primaryText ? 'Yes' : primaryText}
              </Button>
            }
            secondaryAction={
              <Button compact variant="secondary" onPress={onCancel}>
                {!secondaryText ? 'No' : secondaryText}
              </Button>
            }
          />
        )}
      </Modal>
    </>
  );
};

export default Popup;

Popup.defaultProps = {
  primaryText: '',
  secondaryText: '',
};
