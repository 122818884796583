import { HolidaysState } from 'redux/holidays/reducers';
import { Holiday } from 'state/holidayState';

export const SET_STATE = 'holidays/SET_STATE';
export const LOAD_HOLIDAYS = 'holidays/LOAD_HOLIDAYS';
export const ADD_HOLIDAY = 'holidays/ADD_HOLIDAY';

interface SetHolidaysAction {
  type: typeof SET_STATE;
  payload: Partial<HolidaysState>;
}

interface LoadHolidaysAction {
  type: typeof LOAD_HOLIDAYS;
  payload: object;
}

interface AddHolidayAction {
  type: typeof ADD_HOLIDAY;
  payload: Partial<Holiday>;
}

const actions = {
  SET_STATE,
  LOAD_HOLIDAYS,
  ADD_HOLIDAY,
};

export type HolidaysAction = SetHolidaysAction | LoadHolidaysAction | AddHolidayAction;

export default actions;
