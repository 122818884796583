import getFormattedTime from './renderHelper/getFormattedTime';

const replacer = (key: any, value: any) => (value === null || value === undefined ? '' : value);

const formatDataForExport = (headers: any[], data: any[]) => {
  // We check whether obj.title is an object since we use React element(HTML tags) to render really long header names.
  // If we use React elements for title, we add a fullTextTitle to denote the regular header name.
  const excelHeaders = headers
    .map((obj) => (typeof obj.title !== 'string' ? obj.fullTextTitle || '' : obj.title))
    .join(',');

  const excelRows = data
    .map((row) =>
      headers
        .map((field) => {
          if (field.type && field.type === 'timestamp') {
            const formattedTime = getFormattedTime({
              time: row[field.key],
              showTimezone: true,
              isUTC: false,
            });
            return JSON.stringify(formattedTime, replacer);
          }
          return JSON.stringify(row[field.key], replacer);
        })
        .join(','),
    )
    .join('\r\n');

  return `${excelHeaders}\r\n${excelRows}`;
};

export default formatDataForExport;
