import { SetState } from '@cbhq/cds-web';
import { Moment } from 'moment';
import { ChangeEventHandler, ReactNode } from 'react';

export type DynamicFieldState = {
  [key: string]: any;
};

export enum DataType {
  STRING = 'string',
  NUMBER = 'number',
  DATE_RANGE = 'dateRange',
  DATE = 'date',
  DATE_AND_TIME = 'date_and_time',
  DATE_TIME_NOW = 'date_time_now',
  PASSWORD = 'password',
  SELECT = 'select',
  SELECT_WILDCARD = 'select_wildcard',
  SINGLE_SELECT = 'single_select',
  CHECKBOX = 'checkbox',
  MULTI_WILDCARD = 'multi_wildcard',
  MULTI_WILDCARD_COLON = 'multi_wildcard_colon',
  LABEL_CAPTION = 'label_caption',
  NULL = 'null',
}

export interface LabelCaptionOptions {
  outerText: ReactNode;
  innerText: ReactNode;
}

export interface CheckboxOptions {
  checked?: boolean;
  onChangeChecked?: ChangeEventHandler<HTMLInputElement>;
}

export interface SelectOptions {
  onChangeSelection?: Function;
  multiple?: boolean;
  options?: any[];
}

export interface DateOptions {
  format?: string;
  onChangeDate?: Function;
  disabledDate?: (current: Moment) => boolean;
}

export interface NumberOptions {
  addonAfter?: string;
  step: number;
  onChangeInput?: Function;
}

export interface TextOptions {
  onChangeInput?: Function;
}

export interface FormField {
  name: string;
  label?: string;
  placeholder?: string;
  dataType: string;
  testID?: string;
  format?: string;
  rules?: any;
  hasFeedback?: boolean;
  validationFn?: Function;
  required?: boolean;
  conditionalRequired?: Function;
  defaultValue?: any[] | any;
  dependency?: string;
  dependencyCondition?: Function;
  disabled?: boolean;
  dateOptions?: DateOptions;
  numberOptions?: NumberOptions;
  textOptions?: TextOptions;
  checkboxOptions?: CheckboxOptions;
  selectOptions?: SelectOptions;
  labelCaptionOptions?: LabelCaptionOptions;
}
