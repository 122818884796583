import { omit } from 'lodash';
import apiClient from 'services/apiClient';
import removeEmpty from 'utils/removeEmpty';

export async function getNcps(params: object) {
  const reqParams = removeEmpty(params);
  return apiClient
    .get(`/ncps`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function getItms(params: object) {
  const reqParams = removeEmpty(params);
  return apiClient
    .get(`/itms`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function getItmDetails(params: object) {
  const reqParams = removeEmpty(params);
  return apiClient
    .get(`/itm-details`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function getNcpFeeGroups(params: object) {
  const reqParams = removeEmpty(params);
  return apiClient
    .get(`/ncp-fee-groups`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function addNcp(params: object) {
  const reqParams = omit(removeEmpty(params), 'mappingType');
  return apiClient
    .post(`/ncps`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function addItm(params: object) {
  const reqParams = omit(removeEmpty(params), 'mappingType');
  return apiClient
    .post(`/itms`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function addNcpFeeGroup(params: object) {
  const reqParams = removeEmpty(params);

  return apiClient
    .post(`/ncp-fee-groups`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function addItmDetails(params: object) {
  const reqParams = removeEmpty(params);
  return apiClient
    .post(`/itm-details`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
