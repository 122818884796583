import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import style from './style.module.scss';

const mapStateToProps = ({ settings }) => ({ settings });

@connect(mapStateToProps)
class Footer extends React.Component {
  render() {
    const {
      settings: { isContentNoMaxWidth },
    } = this.props;
    return (
      <div
        className={classNames(style.footer, {
          [style.footerFullWidth]: isContentNoMaxWidth,
        })}
      >
        <div className={style.inner} />
      </div>
    );
  }
}

export default Footer;
