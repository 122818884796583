import removeEmpty from '../utils/removeEmpty';
import apiClient from './apiClient';

async function updateFirmProductGroup(params: object) {
  const reqParams = removeEmpty(params);

  return apiClient
    .post(`/firm-product-limits/product-group`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export default updateFirmProductGroup;
