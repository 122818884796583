import axios from 'axios';

export const DEV_ENVIRONMENT = 'development';
export const DEV_URL = process.env.REACT_APP_DEV_BACKEND_URL;

const apiClient = axios.create({
  baseURL: process.env.NODE_ENV === DEV_ENVIRONMENT ? `${DEV_URL}/api/` : '/api/',
});

export default apiClient;
