import { TextBody } from '@cbhq/cds-web/typography';
import auth0 from 'auth0-js';
import ButtonList from 'components/common/ButtonList';
import Popup from 'components/common/Popup';
import React, { useState } from 'react';

interface RequestEmailProps {
  onSubmit?: Function;
  userEmail: string;
  auth0Config: any;
  toggleShowChangePasswordModal: () => void;
}

export const RequestPwdChgEmail = ({
  userEmail: email,
  auth0Config,
  toggleShowChangePasswordModal,
}: RequestEmailProps) => {
  const [showEmailSentModal, setShowEmailSentModal] = useState<boolean>(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState<boolean>(false);
  const [message, setMessage] = useState<string | undefined>('');

  const { clientId: clientID, domain, stack } = auth0Config;
  const webAuth = new auth0.WebAuth({
    domain,
    clientID,
  });

  const handlePwdBtnClick = () => {
    webAuth.changePassword({ connection: `${stack}-admin-db`, email }, (err, res) => {
      if (err) {
        setMessage(err.description);
        setShowErrorMsgModal(true);
      } else {
        setShowEmailSentModal(true);
        setMessage(res);
      }
    });
  };

  const buttons = [
    {
      key: 0,
      label: 'No',
      visible: true,
      disabled: false,
      variant: 'secondary',
      testID: 'cancel',
      execute: toggleShowChangePasswordModal,
    },
    {
      key: 1,
      label: 'Yes',
      visible: true,
      disabled: false,
      variant: 'primary',
      testID: 'reset-password-through-email',
      execute: handlePwdBtnClick,
    },
  ];

  return (
    <>
      {!showEmailSentModal && !showErrorMsgModal && (
        <div>
          <TextBody as="p"> Would you like to reset your password through an email? </TextBody>
          <ButtonList buttons={buttons} layout="vertical" />
        </div>
      )}

      {showEmailSentModal && (
        <Popup
          onClose={() => setShowEmailSentModal(false)}
          onCancel={() => setShowEmailSentModal(false)}
          visible={showEmailSentModal}
          onConfirm={() => {}}
          showButtons={false}
          title="Email Confirmation"
        >
          <TextBody as="p"> {message} </TextBody>
        </Popup>
      )}

      {showErrorMsgModal && (
        <Popup
          onClose={() => setShowErrorMsgModal(false)}
          onCancel={() => setShowErrorMsgModal(false)}
          visible={showErrorMsgModal}
          onConfirm={() => {}}
          showButtons={false}
          title="Error Sending Email"
        >
          <TextBody as="p"> Reason: {message} </TextBody>
        </Popup>
      )}
    </>
  );
};
export default RequestPwdChgEmail;
