import { OrdersFillsState } from './reducers';

export const SET_STATE = 'ordersFills/SET_STATE';
export const LOAD_TRADES = 'ordersFills/LOAD_TRADES';

interface SetOrdersFillsStateAction {
  type: typeof SET_STATE;
  payload: Partial<OrdersFillsState>;
}

interface LoadTradesAction {
  type: typeof LOAD_TRADES;
  payload: object;
}

const actions = {
  SET_STATE,
  LOAD_TRADES,
};

export type OrdersFillsAction = SetOrdersFillsStateAction | LoadTradesAction;

export default actions;
