const convertEnteredValue = (value: string | undefined) => {
  let enteredValue;

  if (value) enteredValue = Number(value);
  if (!enteredValue) enteredValue = null;

  return enteredValue;
};

export default convertEnteredValue;
