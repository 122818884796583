import { Auth0Client, User } from '@auth0/auth0-spa-js';
import * as Effects from 'redux-saga/effects';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { USER_PERMISSIONS_REQUEST } from 'redux/userPermissions/actions';
import {
  Auth0Config,
  checkAuthentication,
  getAuth0Client,
  getAuth0Config,
  handleAuthentication,
  signIn,
  signOut,
} from 'services/auth';

import { RootState } from '../reducers';
import {
  AUTH0_CONFIG_LOADED,
  AUTH0_LOADED,
  HANDLE_AUTHENTICATION_CALLBACK,
  LOGIN,
  USER_PROFILE_LOADED,
} from './actions';

export type AuthenticatedUser = {
  loading: boolean;
  authenticated: boolean;
  profile?: User | undefined;
  token?: string;
};

const call: any = Effects.call;

export function* checkAuth() {
  try {
    const auth0Config: Auth0Config = yield call(getAuth0Config);
    yield put({ type: AUTH0_CONFIG_LOADED, auth0Config });

    let user: AuthenticatedUser;
    if (auth0Config.authRequired) {
      const auth0: Auth0Client = yield call(getAuth0Client, auth0Config);
      yield put({ type: AUTH0_LOADED, auth0 });
      user = yield call(checkAuthentication, auth0);
    } else {
      user = {
        authenticated: true,
        loading: false,
      };
    }
    yield put({ type: USER_PROFILE_LOADED, user });

    if (user.authenticated) {
      yield put({
        type: USER_PERMISSIONS_REQUEST,
      });
    }
  } catch (e) {
    yield put({
      type: USER_PROFILE_LOADED,
      user: {
        authenticated: false,
        loading: false,
      },
    });
  }
}

export function* parseHash() {
  const state: RootState = yield select();
  // @ts-ignore
  const user = yield call(handleAuthentication, state.user.auth0);
  yield put({ type: USER_PROFILE_LOADED, user });
  if (user.authenticated) {
    // Load all data once the user authentication is successful.
    yield put({
      type: USER_PERMISSIONS_REQUEST,
    });
  }
}

export function* handleAuthenticationCallback() {
  yield call(parseHash);
}

export function* handleLogin() {
  const state: RootState = yield select();
  // @ts-ignore

  yield call(signIn, state.user.auth0);
}

export function* handleLogout() {
  const state: RootState = yield select();
  // @ts-ignore
  yield call(signOut, state.user.auth0);
}

export default function* rootSaga() {
  yield all([
    takeEvery(HANDLE_AUTHENTICATION_CALLBACK, handleAuthenticationCallback),
    takeEvery(LOGIN, handleLogin),
    checkAuth(), // run once on app load to check user auth
  ]);
}
