import { Button, ButtonGroup } from '@cbhq/cds-web/buttons';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import React from 'react';

interface ButtonListProps {
  buttons: any[];
  layout?: 'vertical' | 'horizontal';
}

export const ButtonList = (props: ButtonListProps) => {
  const { buttons } = props;
  const LayoutComponent = props.layout === 'vertical' ? VStack : HStack;

  return (
    <LayoutComponent gap={2} alignItems="flex-end">
      <ButtonGroup accessibilityLabel="List of Buttons">
        {buttons &&
          buttons
            .filter((button) => button.visible || button.visible === undefined)
            .map((button) => (
              <Button
                key={button.key}
                onPress={button.execute}
                disabled={button.disabled}
                variant={button.variant}
                testID={button.testID || button.key}
                type={button.type}
                compact
              >
                {button.label}
              </Button>
            ))}
      </ButtonGroup>
    </LayoutComponent>
  );
};

export default ButtonList;
