import { UserPermissions } from './reducers';

export const USER_PERMISSIONS_REQUEST = 'user/USER_PERMISSIONS_REQUEST';
export const USER_PERMISSIONS_SUCCESS = 'user/USER_PERMISSIONS_SUCCESS';
export const USER_PERMISSIONS_FAIL = 'user/USER_PERMISSIONS_FAIL';

const actions = {
  USER_PERMISSIONS_REQUEST,
  USER_PERMISSIONS_SUCCESS,
  USER_PERMISSIONS_FAIL,
};

export interface LoadUserPermissionsAction {
  type: typeof USER_PERMISSIONS_REQUEST;
  payload: object;
}

export interface SetUserPermissionsAction {
  type: typeof USER_PERMISSIONS_SUCCESS;
  payload: Partial<UserPermissions>;
}

export type UserPermissionsAction = LoadUserPermissionsAction | SetUserPermissionsAction;

export default actions;
