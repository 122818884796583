import { permissionsConstants } from 'fairxConstants';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actions, {
  AddNewFirmAction,
  DisableFirmProductAction,
  DisableFirmProductGroupAction,
  KillSessionsAction,
  LoadFirmsProductsAction,
  SET_STATE,
  UnlockTradingAction,
  UpdateFirmDetailsAction,
  UpdateFirmProductAction,
  UpdateFirmProductGroupAction,
} from 'redux/firms/actions';
import { FirmsState } from 'redux/firms/reducers';
import { setErrorMessage, setMessage } from 'redux/setMessage';
import userActions from 'redux/users/actions';
import disableFirmProductGroup from 'services/disableFirmProductGroup';
import disableFirmProductLimits from 'services/disableFirmProductLimits';
import { addFirmDetails, updateFirmDetails } from 'services/firmDetails';
import getFirmPositions from 'services/getFirmPositions';
import getFirmProductLimitsByFirm from 'services/getFirmProductLimitsByFirm';
import getPositions from 'services/getPositions';
import killActiveSessions from 'services/killActiveSessions';
import unlockTrading from 'services/unlockTrading';
import updateFirmProductGroup from 'services/updateFirmProductGroup';
import updateFirmProductLimits from 'services/updateFirmProductLimits';
import {
  Firm,
  FirmPosition,
  FirmPositions,
  FirmProduct,
  FirmProductGroup,
} from 'state/firmPageState';
import { ExpiredFilter } from 'state/sharedEnums';
import { TradingUser } from 'state/usersPageState';
import { isFCM } from 'utils/firmsUtil';
import { isExchangeAdminUser } from 'utils/isExchangeUser';

import { setLoadingSpinner, unsetLoadingSpinner } from '../loadingSpinners';
import { RootState } from '../reducers';

type Params = {
  type:
    | typeof actions.LOAD_FIRMS_POSITIONS
    | typeof actions.UPDATE_FIRM_DETAILS
    | typeof actions.LOAD_POSITIONS;
  payload: object;
};

export function* loadFirmsPositions({ payload = { expiredFilter: ExpiredFilter.NONE } }: Params) {
  yield setLoadingSpinner(SET_STATE);

  const state: RootState = yield select();
  const { adminUserRole, adminOperations } = state.userPermissions;
  const hasFirmsPagePermissions =
    isExchangeAdminUser(adminUserRole) ||
    !!adminOperations.find(
      (op: string) =>
        op === permissionsConstants.actions.SESSION_KILL ||
        op === permissionsConstants.actions.RISK,
    );

  const { response, error } = yield call(getFirmPositions, payload, hasFirmsPagePermissions);
  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        firmPositions: response,
        firms: Object.values(response),
        fcmList: Object.values(response).filter(
          (firm: any) => firm.firmDetails && isFCM(firm.firmDetails),
        ),
        loading: false,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* loadPositions({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(getPositions, payload);
  if (!error) {
    // TODO: Write an updateState module to take care of changed fields.
    const state: RootState = yield select();
    const { firmName } = payload as any;

    const { firmPositions }: { firmPositions: FirmPositions } = state.firms;
    const oldFirm: FirmPosition = firmPositions[firmName];

    const positions = response.data;

    const getUpdatedPositions = () => {
      return Object.keys(positions).reduce((attrs, key) => {
        const position = positions[key];
        return {
          ...attrs,
          [key]: {
            ...positions[key],
            totalFuturesLong:
              position.longWorkingPosition +
              position.longFilledPosition -
              position.shortFilledPosition,
            totalFuturesShort:
              position.shortWorkingPosition +
              position.shortFilledPosition -
              position.longFilledPosition,
          },
        };
      }, {});
    };

    const updatedFirm = { ...oldFirm, positions: getUpdatedPositions() };
    const newFirms = { ...firmPositions, [firmName]: updatedFirm };

    yield put({
      type: actions.SET_STATE,
      payload: {
        firmPositions: newFirms,
        loading: false,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* loadFirmProducts({ payload }: LoadFirmsProductsAction) {
  yield setLoadingSpinner(SET_STATE);
  const { response, error } = yield call(getFirmProductLimitsByFirm, payload);

  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        firmProducts: response.data,
        loading: false,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* updateFirmDetailsSaga({ payload }: UpdateFirmDetailsAction) {
  yield setLoadingSpinner(SET_STATE);
  const { response, error } = yield call(updateFirmDetails, payload);
  if (!error) {
    // Update the state with the modified firm,
    // after successfully updating details on the server.
    const state: RootState = yield select();
    const { firmPositions } = state.firms;
    const firmName = (payload as Firm).name;
    const oldFirm: FirmPosition = firmPositions[firmName];
    const updatedFirm = { ...oldFirm, firmDetails: payload as Firm };
    const newFirms = { ...(firmPositions as any), [firmName]: updatedFirm };

    yield put({
      type: actions.SET_STATE,
      payload: {
        firmPositions: newFirms,
        loading: false,
      },
    });
    yield setMessage(response, `Firm "${payload.name}" was updated.`, '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* updateFirmProductGroupSaga({ payload }: UpdateFirmProductGroupAction) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(updateFirmProductGroup, payload);

  if (!error) {
    const state: RootState = yield select();
    const { firmProducts } = state.firms;
    const updatedProductGroup = response.data;
    const { firmId, productGroup, positionLimit } = updatedProductGroup;

    const updatedFirmProducts: (FirmProduct | FirmProductGroup)[] = firmProducts;
    const index = updatedFirmProducts.findIndex(
      (prod: FirmProductGroup | FirmProduct) =>
        prod.productGroup === updatedProductGroup.productGroup,
    );
    const firmProductLimits = updatedFirmProducts[index].firmProductLimits;
    updatedFirmProducts.splice(index, 1);

    const newProductGroup = { firmId, firmProductLimits, positionLimit, productGroup };
    updatedFirmProducts.push(newProductGroup);

    yield put({
      type: SET_STATE,
      payload: {
        firmProducts: updatedFirmProducts,
      },
    });

    yield setMessage(response, `Firm Product Group was successfully updated.`, '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* updateFirmProductSaga({ payload }: UpdateFirmProductAction) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(updateFirmProductLimits, payload);

  if (!error) {
    const state: RootState = yield select();
    const { firmProducts } = state.firms as FirmsState;
    let updatedProduct = response.data;
    const { firmId, productId, positionLimit, productName } = updatedProduct;

    const updatedFirmProducts = [...firmProducts];

    updatedFirmProducts.forEach((product: FirmProduct | FirmProductGroup) => {
      const item: FirmProduct =
        product?.firmProductLimits?.find(
          (prodLimit: FirmProduct) => prodLimit.productId === productId,
        ) || ({} as FirmProduct);
      if (item !== ({} as FirmProduct)) {
        const index = updatedFirmProducts.indexOf(item);
        updatedFirmProducts.splice(index, 1);
        updatedProduct = { firmId, positionLimit, productId, productName };
        updatedFirmProducts.push(updatedProduct);
      }
    });

    yield put({
      type: SET_STATE,
      payload: {
        firmProducts: updatedFirmProducts,
      },
    });

    yield setMessage(response, `Firm Product was successfully updated.`, '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* disableFirmProductSaga({ payload }: DisableFirmProductAction) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(disableFirmProductLimits, payload);

  if (!error) {
    const state: RootState = yield select();
    const { firmProducts } = state.firms;
    let updatedProduct: Partial<FirmProduct | FirmProductGroup> = response.data;
    const { firmId, positionLimit, productId } = updatedProduct;

    const updatedFirmProducts: Partial<FirmProduct | FirmProductGroup>[] = [...firmProducts];

    updatedFirmProducts.forEach((product: Partial<FirmProduct | FirmProductGroup>) => {
      const item: Partial<FirmProduct | FirmProductGroup> =
        product?.firmProductLimits?.find(
          (prodLimit: FirmProduct) => prodLimit.productId === productId,
        ) || ({} as FirmProduct);
      if (item !== ({} as FirmProduct)) {
        const index: number = updatedFirmProducts.indexOf(item);
        updatedFirmProducts.splice(index, 1);
        updatedProduct = { firmId, positionLimit, productId };
        updatedFirmProducts.push(updatedProduct);
      }
    });

    yield put({
      type: SET_STATE,
      payload: {
        firmProducts: updatedFirmProducts,
      },
    });

    yield setMessage(response, `Firm Product was successfully disabled.`, '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* disableFirmProductGroupSaga({ payload }: DisableFirmProductGroupAction) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(disableFirmProductGroup, payload);

  if (!error) {
    const state: RootState = yield select();
    const { firmProducts }: { firmProducts: (FirmProductGroup | FirmProduct)[] } = state.firms;
    const updatedProductGroup = response.data;
    const { firmId, productGroup, positionLimit, firmProductLimits } = updatedProductGroup;

    const updatedFirmProducts = firmProducts;
    const index = updatedFirmProducts.findIndex(
      (prod: FirmProductGroup | FirmProduct) =>
        prod.productGroup === updatedProductGroup.productGroup,
    );
    updatedFirmProducts.splice(index, 1);

    const newProductGroup = { firmId, positionLimit, productGroup, firmProductLimits };
    updatedFirmProducts.push(newProductGroup);

    yield put({
      type: SET_STATE,
      payload: {
        firmProducts: updatedFirmProducts,
      },
    });

    yield setMessage(response, `Firm Product Group was successfully enabled`, '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* addNewFirm({ payload }: AddNewFirmAction) {
  const { response, error } = yield call(addFirmDetails, payload);

  if (!error) {
    // Update the state with the modified firm,
    // after successfully updating details on the server.
    const state: RootState = yield select();
    const { firmPositions } = state.firms;
    const { data } = response;
    const newFirms = {
      ...(firmPositions as FirmPositions),
      [payload.name]: { firmDetails: data, firmName: payload.name, positions: {} },
    };

    yield put({
      type: actions.SET_STATE,
      payload: {
        firmPositions: newFirms,
        loading: false,
        showAddNewFirmModal: false,
      },
    });
    yield setMessage(response, `Firm "${payload.name}" was successfully added.`, '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* killSwitch({ payload }: KillSessionsAction) {
  const { response, error } = yield call(killActiveSessions, payload);
  const { numUsersAffected, numOrdersCanceled } = response.data;
  const flashMessage = `Successfully triggered kill switch. The number of users affected were ${numUsersAffected}. The number of orders canceled were ${numOrdersCanceled}.`;

  const state: RootState = yield select();

  const { users } = state;
  const allTradingUsers: TradingUser[] = Object.values(users.allTradingUsers);
  const oldUser = allTradingUsers.find((user: TradingUser) => user.id === payload.tradingUserId);
  const updatedUser = { ...oldUser, adminTradingLock: true };
  const updatedAllTradingUsers = {
    ...users.allTradingUsers,
    ...(oldUser && { [oldUser.name]: updatedUser }),
  };

  if (!error) {
    yield put({
      type: userActions.SET_STATE,
      payload: {
        loading: false,
        allTradingUsers: updatedAllTradingUsers,
      },
    });
    yield setMessage(response, flashMessage, '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield setMessage(response, flashMessage, '', 'info');

  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
      allTradingUsers: updatedAllTradingUsers,
    },
  });
}

export function* unlockTradingSaga({ payload }: UnlockTradingAction) {
  const { response, error } = yield call(unlockTrading, payload);
  const { numUsersAffected, numOrdersCanceled } = response.data;
  const flashMessage = `Unlocked Trade. The number of users affected were ${numUsersAffected}. The number of orders canceled were ${numOrdersCanceled}.`;

  if (!error) {
    if (response.status !== 200) {
      yield setMessage(response, flashMessage, '', 'info');
    } else {
      yield unsetLoadingSpinner(SET_STATE);
    }
  } else {
    yield setErrorMessage(error);
  }

  yield unsetLoadingSpinner(SET_STATE);
  yield setMessage(response, flashMessage, '', 'info');
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FIRMS_POSITIONS, loadFirmsPositions),
    takeEvery(actions.UPDATE_FIRM_DETAILS, updateFirmDetailsSaga),
    takeEvery(actions.UPDATE_FIRM_PRODUCT_GROUP, updateFirmProductGroupSaga),
    takeEvery(actions.UPDATE_FIRM_PRODUCT, updateFirmProductSaga),
    takeEvery(actions.DISABLE_FIRM_PRODUCT, disableFirmProductSaga),
    takeEvery(actions.DISABLE_FIRM_PRODUCT_GROUP, disableFirmProductGroupSaga),
    takeEvery(actions.ADD_NEW_FIRM, addNewFirm),
    takeEvery(actions.LOAD_POSITIONS, loadPositions),
    takeEvery(actions.LOAD_FIRMS_PRODUCTS, loadFirmProducts),
    takeEvery(actions.KILL_SESSIONS, killSwitch),
    takeEvery(actions.UNLOCK_TRADING, unlockTradingSaga),
  ]);
}
