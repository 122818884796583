import { permissionsConstants } from 'fairxConstants';
import { PAGES } from 'pageConstants';

const { subjects, actions } = permissionsConstants;

export interface MenuItem {
  title: string;
  testID: string;
  icon: string;
  imgSrcPath: string;
  key: string;
  url: string;
  resource: string;
  permittedActions: string[];
}

export default async function getMenuData() {
  return [
    {
      title: 'Orders',
      testID: 'orders-menu',
      icon: 'invoice',
      imgSrcPath: '/resources/images/icons/order.png',
      key: 'fairxOrders',
      url: PAGES.ORDERS_PAGE,
      resource: subjects.ORDER,
      permittedActions: [actions.READ, actions.WRITE],
    },
    {
      title: 'Fills',
      testID: 'fills-menu',
      icon: 'chartPie',
      imgSrcPath: '/resources/images/icons/fill.png',
      key: 'fairxFills',
      url: PAGES.FILLS_PAGE,
      resource: subjects.FILL,
      permittedActions: [actions.READ],
    },
    {
      title: 'Orders & Fills',
      testID: 'orders-fills-menu',
      icon: 'chartPie',
      imgSrcPath: '/resources/images/icons/order.png',
      key: 'fairxOrdersFills',
      url: PAGES.ORDERS_FILLS_PAGE,
      resource: subjects.ORDER_FILL,
      permittedActions: [actions.READ, actions.WRITE],
    },
    {
      title: 'Order Book',
      testID: 'order-book-menu',
      icon: 'chartPie',
      imgSrcPath: '/resources/images/icons/order-book.png',
      key: 'orderBook',
      url: PAGES.ORDER_BOOK_PAGE,
      resource: subjects.ORDER_BOOK,
    },
    {
      title: 'Products',
      icon: 'chartPie',
      testID: 'products-menu',
      imgSrcPath: '/resources/images/icons/products.png',
      key: 'fairxProducts',
      url: PAGES.PRODUCTS_PAGE,
      resource: subjects.PRODUCT,
    },
    {
      title: 'Instruments',
      testID: 'instruments-menu',
      icon: 'chartPie',
      imgSrcPath: '/resources/images/icons/products.png',
      key: 'fairxInstruments',
      url: PAGES.INSTRUMENTS_PAGE,
      resource: subjects.INSTRUMENT,
    },
    {
      title: 'Active Connections',
      testID: 'active-connections-menu',
      icon: 'chartPie',
      imgSrcPath: '/resources/images/icons/active-connections.png',
      key: 'activeConnections',
      url: PAGES.ACTIVE_CONNECTIONS_PAGE,
      resource: subjects.ACTIVE_CONNECTIONS,
    },
    {
      title: 'Clearing',
      testID: 'clearing-menu',
      icon: 'chartPie',
      imgSrcPath: '/resources/images/icons/clearing.png',
      key: 'clearing',
      url: PAGES.CLEARING_PAGE,
      resource: subjects.CLEARING,
    },
    {
      title: 'Positions',
      testID: 'positions-menu',
      icon: 'chartPie',
      imgSrcPath: '/resources/images/icons/positions.png',
      key: 'positions',
      url: PAGES.POSITIONS_PAGE,
      resource: subjects.POSITION,
    },
    {
      title: 'Position Limit Alerts',
      testID: 'position-limit-alerts-menu',
      icon: 'chartPie',
      imgSrcPath: '/resources/images/icons/position-limit-subscription.png',
      key: 'positionLimitSubscriptions',
      url: PAGES.POSITION_LIMIT_SUBSCRIPTIONS_PAGE,
      resource: subjects.TRANSITIONS,
    },
    {
      title: 'Settlements',
      testID: 'settlements-menu',
      icon: 'chartPie',
      imgSrcPath: '/resources/images/icons/settlement.png',
      key: 'settlements',
      url: PAGES.SETTLEMENTS_PAGE,
      resource: subjects.SETTLEMENT,
    },
    {
      title: 'Firms',
      testID: 'firms-menu',
      icon: 'chartPie',
      imgSrcPath: '/resources/images/icons/firms.png',
      key: 'fairxFirms',
      url: PAGES.FIRMS_PAGE,
      resource: subjects.FIRM,
      permittedActions: [actions.READ, actions.RISK, actions.SESSION_KILL],
    },
    {
      title: 'Users',
      testID: 'users-menu',
      icon: 'chartPie',
      imgSrcPath: '/resources/images/icons/users.png',
      key: 'fairxUsers',
      url: PAGES.USERS_PAGE,
      resource: subjects.USER,
    },
    {
      title: 'User Management',
      testID: 'user-management-menu',
      icon: 'chartPie',
      imgSrcPath: '/resources/images/icons/user-management.png',
      key: 'userManagement',
      url: PAGES.USER_MANAGEMENT_PAGE,
      resource: subjects.USER_MANAGEMENT,
    },
    {
      title: 'Lead Market Makers',
      imgSrcPath: '/resources/images/icons/lmm.png',
      key: 'lmm',
      url: PAGES.LMM,
      resource: subjects.LMM,
    },
    {
      title: 'Audit Trail',
      testID: 'audit-trail-menu',
      icon: 'chartPie',
      imgSrcPath: '/resources/images/icons/audit.png',
      key: 'audit',
      url: PAGES.AUDIT_PAGE,
      resource: subjects.AUDIT,
    },
    {
      title: 'Transitions',
      icon: 'chartPie',
      testID: 'transitions-menu',
      imgSrcPath: '/resources/images/icons/transitions.png',
      key: 'transitions',
      url: PAGES.TRANSITIONS_PAGE,
      resource: subjects.TRANSITIONS,
    },
    {
      title: 'Order Book Recreation',
      testID: 'order-book-recreation-menu',
      icon: 'chartPie',
      imgSrcPath: '/resources/images/icons/order-book.png',
      key: 'orderBookRecreation',
      url: PAGES.ORDER_BOOK_RECREATION_PAGE,
      resource: subjects.ORDER_BOOK_RECREATION,
    },
    {
      title: 'Admin CLI',
      imgSrcPath: '/resources/images/icons/terminal.png',
      key: 'adminCli',
      url: PAGES.ADMIN_CLI,
      resource: subjects.ADMIN_CLI,
    },
    {
      title: 'Event Viewer',
      testID: 'event-viewer-menu',
      icon: 'chartPie',
      imgSrcPath: '/resources/images/icons/events.png',
      key: 'eventViewer',
      url: PAGES.EVENT_VIEWER,
      resource: subjects.EVENT_VIEWER,
    },
  ];
}
