import '@ant-design/compatible/assets/index.css';

import { Spacer } from '@cbhq/cds-web/layout/Spacer';
import { VStack } from '@cbhq/cds-web/layout/VStack';
import HolidayList from 'exchange/HolidayList';
import React from 'react';

export const HolidayViewer = () => {
  return (
    <VStack>
      <Spacer vertical={2} />
      <HolidayList />
    </VStack>
  );
};

export default HolidayViewer;
