import { FillsState } from './reducers';

export const SET_STATE = 'fills/SET_STATE';
export const LOAD_FILLS = 'fills/LOAD_FILLS';
export const LOAD_FILLS_FAILED = 'fills/LOAD_FILLS_FAILED';
export const SHOW_MODAL = 'fills/SHOW_MODAL';
export const HIDE_MODAL = 'fills/HIDE_MODAL';
export const AMEND_TRADE = 'fills/AMEND_TRADE';
export const CANCEL_TRADE = 'fills/CANCEL_TRADE';

export const MODAL_TYPE = ['AMEND_TRADE', 'CANCEL_TRADE_BUST'];

export interface AmendTradeParam {
  adminUserId: number;
  matchId: number;
  fillPrice: number;
  leg1fillPrice: number;
  leg2fillPrice: number;
}

interface SetFillsStateAction {
  type: typeof SET_STATE;
  payload: Partial<FillsState>;
}

interface LoadFillsAction {
  type: typeof LOAD_FILLS;
  payload: object;
}

interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: object;
}

interface HideModalAction {
  type: typeof HIDE_MODAL;
  payload: object;
}

export interface AmendTradesAction {
  type: typeof AMEND_TRADE;
  payload: Partial<AmendTradeParam>;
}

interface CancelTradeAction {
  type: typeof CANCEL_TRADE;
  payload: object;
}

const actions = {
  SET_STATE,
  LOAD_FILLS,
  LOAD_FILLS_FAILED,
  SHOW_MODAL,
  HIDE_MODAL,
  AMEND_TRADE,
  CANCEL_TRADE,
};

export type FillAction =
  | SetFillsStateAction
  | LoadFillsAction
  | ShowModalAction
  | HideModalAction
  | AmendTradesAction
  | CancelTradeAction;

export default actions;
