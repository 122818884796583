import { FirmsState } from 'redux/firms/reducers';
import { Firm, ProductGroup } from 'state/firmPageState';

export const SET_STATE = 'firms/SET_STATE';
export const LOAD_FIRMS_POSITIONS = 'firms/LOAD_FIRMS_POSITIONS';
export const LOAD_POSITIONS = 'firms/LOAD_POSITIONS';
export const LOAD_FIRMS_POSITIONS_FAILED = 'firms/LOAD_FIRMS_POSITIONS_FAILED';
export const LOAD_FIRMS_PRODUCTS = 'firms/LOAD_FIRMS_PRODUCTS';
export const SET_SEARCH_TEXT = 'firms/SET_SEARCH_TEXT';
export const UPDATE_FIRM_DETAILS = 'firms/UPDATE_FIRM_DETAILS';
export const UPDATE_FIRM_PRODUCT = 'firms/UPDATE_FIRM_PRODUCT';
export const UPDATE_FIRM_PRODUCT_GROUP = 'firms/UPDATE_FIRM_PRODUCT_GROUP';
export const DISABLE_FIRM_PRODUCT = 'firms/DISABLE_FIRM_PRODUCT';
export const DISABLE_FIRM_PRODUCT_GROUP = 'firms/DISABLE_FIRM_PRODUCT_GROUP';
export const SHOW_ADD_NEW_FIRM_MODAL = 'firms/SHOW_ADD_NEW_FIRM_MODAL';
export const HIDE_ADD_NEW_FIRM_MODAL = 'firms/HIDE_ADD_NEW_FIRM_MODAL';
export const ADD_NEW_FIRM = 'firms/ADD_NEW_FIRM';
export const KILL_SESSIONS = 'firms/KILL_SESSIONS';
export const UNLOCK_TRADING = 'firms/UNLOCK_TRADING';
export const SHOW_MODAL = 'firms/SHOW_MODAL';
export const HIDE_MODAL = 'firms/HIDE_MODAL';

export interface SearchParam {
  value: string;
}

export interface FirmProductGroup {
  firmId: number;
  productGroup: ProductGroup;
}

export interface FirmProduct {
  firmId: number;
  productId: number;
  positionLimit: number;
}

interface SetFirmsStateAction {
  type: typeof SET_STATE;
  payload: Partial<FirmsState>;
}

interface LoadFirmsAction {
  type: typeof LOAD_FIRMS_POSITIONS;
  payload: object;
}

export interface LoadFirmsProductsAction {
  type: typeof LOAD_FIRMS_PRODUCTS;
  payload: object;
}

interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: object;
}

interface HideModalAction {
  type: typeof HIDE_MODAL;
  payload: object;
}

interface SetSearchTextAction {
  type: typeof SET_SEARCH_TEXT;
  payload: SearchParam;
}

interface LoadPositionsAction {
  type: typeof LOAD_POSITIONS;
  payload: object;
}

export interface AddNewFirmAction {
  type: typeof ADD_NEW_FIRM;
  payload: Firm;
}

export interface UpdateFirmDetailsAction {
  type: typeof UPDATE_FIRM_DETAILS;
  payload: Firm;
}

export interface UpdateFirmProductAction {
  type: typeof UPDATE_FIRM_PRODUCT;
  payload: FirmProduct;
}

export interface UpdateFirmProductGroupAction {
  type: typeof UPDATE_FIRM_PRODUCT_GROUP;
  payload: FirmProductGroup;
}

export interface DisableFirmProductGroupAction {
  type: typeof DISABLE_FIRM_PRODUCT_GROUP;
  payload: object;
}

export interface DisableFirmProductAction {
  type: typeof DISABLE_FIRM_PRODUCT;
  payload: object;
}

export interface AddNewFirmModalAction {
  type: typeof SHOW_ADD_NEW_FIRM_MODAL | typeof HIDE_ADD_NEW_FIRM_MODAL;
  payload: object;
}

type KillSessionsParams = {
  adminUserId: number;
  fcmId: number;
  firmId: number;
  tradingUserId: number;
  onlyCancelNoTradingLock: boolean;
};

export interface KillSessionsAction {
  type: typeof KILL_SESSIONS;
  payload: KillSessionsParams;
}

export interface UnlockTradingAction {
  type: typeof UNLOCK_TRADING;
  payload: object;
}

const actions = {
  SET_STATE,
  LOAD_FIRMS_POSITIONS,
  LOAD_FIRMS_POSITIONS_FAILED,
  LOAD_POSITIONS,
  LOAD_FIRMS_PRODUCTS,
  SET_SEARCH_TEXT,
  UPDATE_FIRM_DETAILS,
  UPDATE_FIRM_PRODUCT_GROUP,
  UPDATE_FIRM_PRODUCT,
  DISABLE_FIRM_PRODUCT_GROUP,
  DISABLE_FIRM_PRODUCT,
  SHOW_ADD_NEW_FIRM_MODAL,
  HIDE_ADD_NEW_FIRM_MODAL,
  ADD_NEW_FIRM,
  KILL_SESSIONS,
  UNLOCK_TRADING,
  SHOW_MODAL,
  HIDE_MODAL,
};

export type FirmsAction =
  | SetFirmsStateAction
  | LoadFirmsAction
  | LoadFirmsProductsAction
  | UpdateFirmDetailsAction
  | UpdateFirmProductAction
  | DisableFirmProductAction
  | DisableFirmProductGroupAction
  | UpdateFirmProductGroupAction
  | LoadPositionsAction
  | SetSearchTextAction
  | AddNewFirmModalAction
  | AddNewFirmAction
  | KillSessionsAction
  | UnlockTradingAction
  | HideModalAction
  | ShowModalAction;

export default actions;
