import actions, { EventsAction } from './actions';

export interface EventsState {
  loading: boolean;
  events: unknown;
}

export const initialState: EventsState = {
  loading: false,
  events: [],
};

export default function eventsReducer(
  state: EventsState = initialState,
  action: EventsAction,
): EventsState {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state };
    default:
      return state;
  }
}
