import { permissionsConstants } from 'fairxConstants';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { setLoadingSpinner, unsetLoadingSpinner } from 'redux/loadingSpinners';
import { setErrorMessage, setMessage } from 'redux/setMessage';
import addSelfMatchPreventions from 'services/addSelfMatchPreventions';
import deleteSelfMatchPreventions from 'services/deleteSelfMatchPreventions';
import getSelfMatchPreventions from 'services/getSelfMatchPreventions';
import getUsers from 'services/getUsers';
import {
  addAdminUser,
  addDropCopyUser,
  addMarketDataUser,
  addTradingUser,
  updateAdminUser,
  updateDropCopyUser,
  updateMarketDataUser,
  updateTradingUser,
  updateUserCredentials,
} from 'services/users/users';
import { AdminUser, DropCopyUser, MarketDataUser, TradingUser } from 'state/usersPageState';
import { isExchangeAdminUser } from 'utils/isExchangeUser';

import { RootState } from '../../reducers';
import actions, {
  AddAdminUserAction,
  AddDropCopyUserAction,
  AddMarketDataUserAction,
  AddNewSmpAction,
  AddTradingUserAction,
  ChangePasswordAction,
  LoadUsersAction,
  RemoveSmpAction,
  SET_STATE,
  UpdateAdminUserAction,
  UpdateDropCopyUserAction,
  UpdateMarketDataUserAction,
  UpdateTradingUserAction,
} from '../actions';
import dropCopySagas from './dropCopySubscriptionSagas';
import getDropCopySubscriptionsForDropCopyUser from './getDropCopySubscriptionsForDropCopyUser';

const userAction = { ADDED: 'added', UPDATED: 'updated' };

export function* LOAD_USERS({ payload }: LoadUsersAction) {
  yield setLoadingSpinner(SET_STATE);

  const state: RootState = yield select();
  const hasKillSessionPermission = state.userPermissions.adminOperations.includes(
    permissionsConstants.actions.SESSION_KILL,
  );

  const { response, error } = yield call(
    getUsers,
    payload,
    hasKillSessionPermission,
    isExchangeAdminUser(state.userPermissions.adminUserRole),
  );

  if (!error) {
    const {
      tradingUsers,
      dropCopyUsers,
      adminUsers,
      allMarketDataUsers,
      allDropCopySubscriptions,
      dropCopySubscriptions,
      dropCopyFirmSubscriptions,
      dropCopyFcmSubscriptions,
    } = response;

    yield put({
      type: actions.SET_STATE,
      payload: {
        allTradingUsers: (tradingUsers || []).reduce(
          (map: Record<string, TradingUser>, user: TradingUser) => {
            return { ...map, [user.name]: { ...user, key: user.id } };
          },
          {},
        ),
        allDropCopyUsers: (dropCopyUsers || []).reduce(
          (map: Record<string, DropCopyUser>, user: DropCopyUser) => {
            return {
              ...map,
              [user.name]: {
                ...user,
                key: user.id,
                allDropCopySubscriptions: getDropCopySubscriptionsForDropCopyUser(
                  allDropCopySubscriptions,
                  user.id,
                ),
              },
            };
          },
          {},
        ),
        allAdminUsers: (adminUsers || []).reduce(
          (map: Record<string, AdminUser>, user: AdminUser) => {
            return { ...map, [user.name]: { ...user, key: user.id } };
          },
          {},
        ),
        allMarketDataUsers: (allMarketDataUsers || []).reduce(
          (map: Record<string, MarketDataUser>, user: MarketDataUser) => {
            return { ...map, [user.name]: { ...user, key: user.id } };
          },
          {},
        ),
        allDropCopySubscriptions: allDropCopySubscriptions || [],
        dropCopySubscriptions,
        dropCopyFirmSubscriptions,
        dropCopyFcmSubscriptions,
      },
    });
  } else {
    yield setErrorMessage(error);
  }

  unsetLoadingSpinner(SET_STATE);
}

export function* LOAD_SELF_MATCH_PREVENTIONS() {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(getSelfMatchPreventions);
  if (!error) {
    yield put({
      type: SET_STATE,
      payload: {
        loading: false,
        selfMatchPreventions: response.data,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  unsetLoadingSpinner(SET_STATE);
}

export function* ADD_SELF_MATCH_PREVENTIONS_SAGA({ payload }: AddNewSmpAction) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(addSelfMatchPreventions, payload);
  if (!error) {
    const state: RootState = yield select();
    const updatedSelfMatchPreventions = [response.data, ...state.users.selfMatchPreventions];
    yield put({
      type: SET_STATE,
      payload: {
        selfMatchPreventions: updatedSelfMatchPreventions,
      },
    });
    yield setMessage(response, `Successfully added new self match prevention`, '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* REMOVE_SELF_MATCH_PREVENTIONS_SAGA({ payload }: RemoveSmpAction) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(deleteSelfMatchPreventions, payload);
  const smpId = response && response.data.smpId;

  if (!error) {
    const state: RootState = yield select();
    const updatedSelfMatchPreventions = [...state.users.selfMatchPreventions];
    const index = updatedSelfMatchPreventions.findIndex((smp: any) => smp.smpId === smpId);
    updatedSelfMatchPreventions.splice(index, 1);

    yield put({
      type: SET_STATE,
      payload: {
        selfMatchPreventions: updatedSelfMatchPreventions,
      },
    });

    yield setMessage(response, 'Successfully removed self match prevention', '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* ADD_TRADING_USER({ payload }: AddTradingUserAction) {
  const { response, error } = yield call(addTradingUser, payload);
  const tradingUser = response && response.data;

  yield setTradingUser(
    response,
    error,
    { ...tradingUser, key: response.data.id } as TradingUser,
    userAction.ADDED,
  );
}

export function* UPDATE_TRADING_USER({ payload }: UpdateTradingUserAction) {
  const { response, error } = yield call(updateTradingUser, payload);
  const tradingUser = response && response.data;

  yield setTradingUser(response, error, tradingUser as TradingUser, userAction.UPDATED);
}

function* setTradingUser(response: any, error: any, user: TradingUser, action: any) {
  if (!error) {
    const state: RootState = yield select();
    const { allTradingUsers } = state.users;

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        allTradingUsers: { ...allTradingUsers, [user.name]: user },
      },
    });
    yield LOAD_SELF_MATCH_PREVENTIONS();
    yield setMessage(response, `Trading User "${user.name}" was ${action}`, '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* ADD_DROP_COPY_USER({ payload }: AddDropCopyUserAction) {
  const { response, error } = yield call(addDropCopyUser, payload);
  const dropCopyUser = response && response.data;

  if (!error) {
    yield setDropCopyUser(response, dropCopyUser as DropCopyUser, userAction.ADDED);
  } else {
    yield setErrorMessage(error);
  }
}

export function* UPDATE_DROP_COPY_USER({ payload }: UpdateDropCopyUserAction) {
  const { response, error } = yield call(updateDropCopyUser, payload);
  const updatedDropCopyUser = response && response.data;

  if (!error) {
    yield setDropCopyUser(response, updatedDropCopyUser as DropCopyUser, userAction.UPDATED);
  } else {
    yield setErrorMessage(error);
  }
}

function* setDropCopyUser(response: any, user: DropCopyUser, action: any) {
  const state: RootState = yield select();
  const { allDropCopyUsers } = state.users;

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      allDropCopyUsers: {
        ...allDropCopyUsers,
        [user.name]: { ...user, dropCopySubscriptions: undefined },
      },
    },
  });

  yield setMessage(response, `Drop Copy User "${user.name}" was ${action}`, '', 'info');
}

export function* ADD_ADMIN_USER({ payload }: AddAdminUserAction) {
  const { response, error } = yield call(addAdminUser, payload);
  const adminUser = response && response.data;

  yield setAdminUser(response, error, adminUser as AdminUser, userAction.ADDED);
}

export function* UPDATE_ADMIN_USER({ payload }: UpdateAdminUserAction) {
  const { response, error } = yield call(updateAdminUser, payload);
  const updatedAdminUser = response && response.data;

  yield setAdminUser(response, error, updatedAdminUser as AdminUser, userAction.UPDATED);
}

export function* ADD_MARKET_DATA_USER({ payload }: AddMarketDataUserAction) {
  const { response, error } = yield call(addMarketDataUser, payload);
  const markteDataUser = response && response.data;

  yield setMarketDataUser(response, error, markteDataUser as MarketDataUser, userAction.ADDED);
}

export function* UPDATE_MARKET_DATA_USER({ payload }: UpdateMarketDataUserAction) {
  const { response, error } = yield call(updateMarketDataUser, payload);
  const updatedMarkteDataUser = response && response.data;

  yield setMarketDataUser(
    response,
    error,
    updatedMarkteDataUser as MarketDataUser,
    userAction.UPDATED,
  );
}

function* setAdminUser(response: any, error: any, user: AdminUser, action: any) {
  if (!error) {
    const state: RootState = yield select();
    const { allAdminUsers } = state.users;

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        allAdminUsers: { ...allAdminUsers, [user.name]: user },
      },
    });
    yield setMessage(response, `Admin User "${user.name}" was ${action}`, '', 'info');
  } else {
    yield setErrorMessage(error);
  }
}

function* setMarketDataUser(response: any, error: any, user: MarketDataUser, action: any) {
  if (!error) {
    const state: RootState = yield select();
    const { allMarketDataUsers } = state.users;

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        allMarketDataUsers: { ...allMarketDataUsers, [user.name]: user },
      },
    });
    yield setMessage(response, `Market Data User "${user.name}" was ${action}`, '', 'info');
  } else {
    yield setErrorMessage(error);
  }
}

export function* updateUserCredentialsSaga({ payload }: ChangePasswordAction) {
  const { response, error } = yield call(updateUserCredentials, payload);

  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
    yield setMessage(response, 'Password updated.', '', 'info');
  } else {
    yield setErrorMessage(error);
  }
}

const userSagas = [
  takeEvery(actions.LOAD_USERS, LOAD_USERS),
  takeEvery(actions.LOAD_SELF_MATCH_PREVENTIONS, LOAD_SELF_MATCH_PREVENTIONS),
  takeEvery(actions.ADD_SELF_MATCH_PREVENTIONS, ADD_SELF_MATCH_PREVENTIONS_SAGA),
  takeEvery(actions.REMOVE_SELF_MATCH_PREVENTIONS, REMOVE_SELF_MATCH_PREVENTIONS_SAGA),
  takeEvery(actions.ADD_TRADING_USER, ADD_TRADING_USER),
  takeEvery(actions.UPDATE_TRADING_USER, UPDATE_TRADING_USER),
  takeEvery(actions.ADD_DROP_COPY_USER, ADD_DROP_COPY_USER),
  takeEvery(actions.UPDATE_DROP_COPY_USER, UPDATE_DROP_COPY_USER),
  takeEvery(actions.ADD_ADMIN_USER, ADD_ADMIN_USER),
  takeEvery(actions.UPDATE_ADMIN_USER, UPDATE_ADMIN_USER),
  takeEvery(actions.ADD_MARKET_DATA_USER, ADD_MARKET_DATA_USER),
  takeEvery(actions.UPDATE_MARKET_DATA_USER, UPDATE_MARKET_DATA_USER),
  takeEvery(actions.CHANGE_USER_PASSWORD, updateUserCredentialsSaga),
];
const dropCopySubscriptionsSagas = dropCopySagas();

export default function* rootSaga() {
  yield all([...userSagas, ...dropCopySubscriptionsSagas]);
}
