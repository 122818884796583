import { all, call, put, takeEvery } from 'redux-saga/effects';
import { setErrorMessage } from 'redux/setMessage';
import getOrderDetails from 'services/getOrderDetails';

import { setLoadingSpinner, unsetLoadingSpinner } from '../loadingSpinners';
import actions, { SET_STATE } from './actions';

type Params = {
  type: typeof actions.LOAD_ORDER_DETAILS;
  payload: object;
};

export function* LOAD_ORDER_DETAILS({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);
  const { response, error } = yield call(getOrderDetails, payload);
  if (!error) {
    yield put({
      type: 'orderDetails/SET_STATE',
      payload: {
        events: response.data,
        orderId: ((response.data || [])[0] || {}).orderId,
        loading: false,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_ORDER_DETAILS, LOAD_ORDER_DETAILS)]);
}
