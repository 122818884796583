import '@ant-design/compatible/assets/index.css';

import { VStack } from '@cbhq/cds-web/layout/VStack';
import { TabNavigation } from '@cbhq/cds-web/tabs/TabNavigation';
import { TextTitle3 } from '@cbhq/cds-web/typography/TextTitle3';
import Table from 'components/common/table';
import { AgGridTableColumnProps } from 'components/common/table/AgGridWrapper';
import React, { useState } from 'react';
import { Instrument } from 'state/productPageState';
import getFormattedTime, { SHORT_DATE_FORMAT } from 'utils/renderHelper/getFormattedTime';

import InstrumentInformation from '../instruments/InstrumentInformation';

const getWord = (str: string) => (str ? str.charAt(0) + str.slice(1).toLowerCase() : str);

interface InstrumentInformationListProps {
  list: Instrument[];
  showDataInTable: boolean;
}

export const InstrumentInformationList = (props: InstrumentInformationListProps) => {
  const { list, showDataInTable } = props;
  const firstInstrumentId = list && list.length > 0 ? list[0].instrumentId : -1;
  const [curTab, setCurTab] = useState<string>(`${firstInstrumentId}`);
  const [currInstrument, setCurrInstrument] = useState<Instrument | undefined>(() =>
    list.find((l: Instrument) => l.instrumentId === firstInstrumentId),
  );

  const instrumentInfoColumn: AgGridTableColumnProps[] = [
    {
      headerName: 'Symbol',
      field: 'symbol',
      key: 'symbol',
      sortable: true,
    },
    {
      headerName: 'Contract',
      field: 'contractName',
      key: 'contractName',
      sortable: true,
    },
    {
      headerName: 'Expiry Date',
      field: 'lastTradingSessionDate',
      key: 'lastTradingSessionDate',
      sortable: true,
      render: (date: number) => (
        <span>
          {getFormattedTime({
            time: date,
            showTimezone: true,
            isUTC: false,
            dateFormat: SHORT_DATE_FORMAT,
          })}
        </span>
      ),
    },
  ];

  const handleTabChange = (value: any) => {
    setCurTab(value);
    const instr = list.find((l: Instrument) => l.instrumentId === Number(curTab));
    setCurrInstrument(instr);
  };

  const tabs = (list || []).map((def: Instrument) => ({
    id: `${def.instrumentId}`,
    label: def.symbol,
  }));

  return (
    <VStack bordered borderRadius="rounded" width="80%">
      <VStack spacing={2} width="100%" borderedBottom>
        <TextTitle3 as="p">Instruments</TextTitle3>
      </VStack>
      <VStack>
        {!showDataInTable && (
          <>
            <TabNavigation
              spacingTop={1}
              variant="secondary"
              tabs={tabs}
              value={curTab}
              onChange={(value: any) => {
                handleTabChange(value);
              }}
            />
            <InstrumentInformation instrument={currInstrument} showTitle={false} />
          </>
        )}
        {showDataInTable && <Table columns={instrumentInfoColumn} size="small" rowData={list} />}
      </VStack>
    </VStack>
  );
};

export default InstrumentInformationList;
