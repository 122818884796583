import React from 'react';
import actions, { LoadAllDataAction } from 'redux/application/actions';

import { FlashMessageType } from '../../fairxConstants';

export type FlashMessageState = {
  message: string | React.ReactNode;
  description?: string | React.ReactNode;
  type: FlashMessageType | string;
};

export interface ApplicationState {
  flashMessage: FlashMessageState;
  pageName?: string;
  versionInfo?: string;
}

export const initialState: ApplicationState = {
  flashMessage: {
    message: '',
    description: '',
    type: 'info',
  } as FlashMessageState,
  pageName: '',
  versionInfo: '',
};

export default function reducer(
  state: ApplicationState = initialState,
  action: LoadAllDataAction,
): ApplicationState {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.DISMISS_FLASH_MESSAGE:
      return { ...state, flashMessage: { message: '', description: '', type: '' } };
    case actions.SET_FLASH_MESSAGE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
