import { useToggler } from '@cbhq/cds-web';
import { AvatarButton } from '@cbhq/cds-web/buttons';
import { SelectOption } from '@cbhq/cds-web/controls';
import { HStack } from '@cbhq/cds-web/layout';
import {
  PopoverMenu,
  PopoverTrigger,
  PopoverTriggerWrapper,
  SectionTitle,
} from '@cbhq/cds-web/overlays';
import { TextHeadline } from '@cbhq/cds-web/typography/TextHeadline';
import { TextTitle1 } from '@cbhq/cds-web/typography/TextTitle1';
import { TextTitle4 } from '@cbhq/cds-web/typography/TextTitle4';
import RequestPwdChgEmail from 'components/common/modals/requestPasswordChangeEmail';
import Popup from 'components/common/Popup';
import style from 'components/layout/TopBar/style.module.scss';
import AddHolidayForm from 'exchange/AddHolidayForm';
import { HolidayViewer } from 'exchange/HolidayViewer';
import React, { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { ADD_HOLIDAY } from 'redux/holidays/actions';
import { RootState } from 'redux/reducers';
import { signOut } from 'services/auth';
import { isExchangeAdminUser } from 'utils/isExchangeUser';

const mapState = ({ user, userPermissions, application }: RootState) => ({
  versionInfo: application.versionInfo,
  user,
  userPermissions,
});

const mapDispatch = {
  onAddHoliday: (values: object) => ({
    type: ADD_HOLIDAY,
    payload: values,
  }),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const ProfileMenu = (props: PropsFromRedux) => {
  const { user, userPermissions, onAddHoliday, versionInfo } = props;

  const [showHolidayListModal, setShowHolidayListModal] = useState<boolean>(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);
  const [showAddHolidayForm, setShowAddHolidayForm] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [visible, togglePopoverMenuVisibility] = useToggler(false);

  const openHolidayForm = () => {
    setShowAddHolidayForm(true);
    toggleShowHolidayListModal();
  };
  const closeHolidayForm = () => {
    setShowAddHolidayForm(false);
  };

  const toggleShowHolidayListModal = () => {
    setShowHolidayListModal(!showHolidayListModal);
  };

  const toggleShowChangePasswordModal = () => {
    setShowChangePasswordModal(!showChangePasswordModal);
  };

  const renderRequestPwdChgEmailView = () => {
    return (
      <Popup
        onClose={toggleShowChangePasswordModal}
        onCancel={toggleShowChangePasswordModal}
        visible={showChangePasswordModal}
        onConfirm={() => {}}
        showButtons={false}
        title="Change Password"
      >
        <RequestPwdChgEmail
          userEmail={email}
          auth0Config={user.auth0Config}
          toggleShowChangePasswordModal={toggleShowChangePasswordModal}
        />
      </Popup>
    );
  };

  const onLogout = () => {
    const {
      user: { auth0 },
    } = props;
    if (auth0 !== undefined) signOut(auth0);
  };

  let { profile } = user;
  if (profile === undefined) {
    profile = { name: undefined, email: '', picture: '' };
  }
  const { name, email, picture } = profile as any;
  const { adminUserId } = userPermissions;

  const handleMenuValueChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
    },
    [setValue],
  );

  const navigationOptions = [
    ...(isExchangeAdminUser(userPermissions.adminUserRole)
      ? [
          {
            value: 'holidayList',
            name: 'List of Holidays',
            onPress: toggleShowHolidayListModal,
          },
        ]
      : []),
    ...(adminUserId
      ? [
          {
            value: 'changePassword',
            name: 'Change Password',
            onPress: toggleShowChangePasswordModal,
          },
        ]
      : []),
    {
      value: 'logout',
      name: 'Logout',
      onPress: () => onLogout(),
    },
  ];

  const stack = user?.auth0Config?.stack || '';
  const env = ['prod', 'prod-dr', 'int', 'staging', 'qa'].includes(stack) ? stack : 'dev';

  const stackTitle = (
    <HStack>
      <TextTitle1
        as="h1"
        transform="uppercase"
        dangerouslySetClassName={`${style.topbar} ${style[env]}`}
      >
        {stack}
      </TextTitle1>
      <TextTitle4 as="p" dangerouslySetClassName={`${style.topbar} ${style[env]}`} spacing={2}>
        (v{versionInfo})
      </TextTitle4>
    </HStack>
  );

  return (
    <>
      {stackTitle}
      <PopoverMenu
        width={400}
        openMenu={togglePopoverMenuVisibility.toggleOn}
        closeMenu={togglePopoverMenuVisibility.toggleOff}
        visible={visible}
        onChange={handleMenuValueChange}
        value={value}
      >
        <PopoverTriggerWrapper>
          <PopoverTrigger>
            <AvatarButton src={picture} alt="First and Last Initials">
              <TextHeadline as="p">{name}</TextHeadline>
            </AvatarButton>
          </PopoverTrigger>
        </PopoverTriggerWrapper>
        <SectionTitle text={`Hello, ${name} (${email})`} />
        {navigationOptions.map((opts: any) => {
          const { name, value: optionValue, description, onPress } = opts;
          return (
            <SelectOption
              key={name}
              value={optionValue}
              title={name}
              description={description}
              onPress={onPress}
            />
          );
        })}
      </PopoverMenu>

      {showChangePasswordModal && renderRequestPwdChgEmailView()}

      {showHolidayListModal && (
        <Popup
          onClose={toggleShowHolidayListModal}
          onCancel={toggleShowHolidayListModal}
          visible={showHolidayListModal}
          onConfirm={openHolidayForm}
          title="List of Holidays"
          showButtons
          primaryText="Add a Holiday"
          secondaryText="Cancel"
        >
          <HolidayViewer />
        </Popup>
      )}
      {showAddHolidayForm && (
        <Popup
          onClose={closeHolidayForm}
          onCancel={closeHolidayForm}
          visible={showAddHolidayForm}
          onConfirm={() => {}}
          showButtons={false}
          title="Add Holiday Form"
        >
          <AddHolidayForm onSubmit={onAddHoliday} onCancel={closeHolidayForm} />
        </Popup>
      )}
    </>
  );
};

export default connector(ProfileMenu);
