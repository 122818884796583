import apiClient from 'services/apiClient';

export default async function getUsers(
  params: object,
  hasKillSessionPermission: boolean,
  isExchangeAdmin: boolean,
) {
  return Promise.all([
    ...(isExchangeAdmin || hasKillSessionPermission ? [apiClient.get('/users', params)] : []),
    ...(isExchangeAdmin
      ? [
          apiClient.get('/drop-copy-users'),
          apiClient.get('/admin-users'),
          apiClient.get('/market-data-users'),
          apiClient.get('/drop-copy-subscriptions'),
          apiClient.get('/drop-copy-firm-subscriptions'),
          apiClient.get('/drop-copy-fcm-subscriptions'),
        ]
      : []),
  ])
    .then((response) => {
      const tradingUsers = (response[0] || {}).data;
      const dropCopyUsers = (response[1] || {}).data;
      const adminUsers = (response[2] || {}).data;
      const marketDataUsers = (response[3] || {}).data;
      const dropCopySubscriptions = (response[4] || {}).data;
      const dropCopyFirmSubscriptions = (response[5] || {}).data;
      const dropCopyFcmSubscriptions = (response[6] || {}).data;

      return {
        response: {
          tradingUsers,
          dropCopyUsers,
          adminUsers,
          allMarketDataUsers: marketDataUsers,
          allDropCopySubscriptions: [
            ...dropCopySubscriptions,
            ...dropCopyFcmSubscriptions,
            ...dropCopyFirmSubscriptions,
          ],
          dropCopySubscriptions,
          dropCopyFirmSubscriptions,
          dropCopyFcmSubscriptions,
        },
      };
    })
    .catch((error) => ({ error }));
}
