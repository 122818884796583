import '@ant-design/compatible/assets/index.css';

import { Table, TableBody, TableCell, TableHeader, TableRow } from '@cbhq/cds-web/tables';
import moment from 'moment';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { LOAD_HOLIDAYS } from 'redux/holidays/actions';
import { RootState } from 'redux/reducers';
import { SHORT_DATE_FORMAT2 } from 'utils/renderHelper/getFormattedTime';

import { Holiday, HolidayDate } from '../state/holidayState';

const mapState = (state: RootState) => ({
  holidays: (state as any).holidays,
});

const mapDispatch = {
  onLoadHolidays: () => ({ type: LOAD_HOLIDAYS }),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const HolidayList = ({ onLoadHolidays, holidays }: PropsFromRedux) => {
  React.useEffect(() => {
    onLoadHolidays();
  }, [onLoadHolidays]);

  const renderDate = (date: HolidayDate) => {
    if (date) {
      const momentDateStr = moment()
        .year(date.year)
        .month(date.month - 1)
        .date(date.day)
        .format(SHORT_DATE_FORMAT2);
      return <span>{momentDateStr}</span>;
    }
    return <span />;
  };

  return (
    <Table bordered tableLayout="auto" testID="holiday-list-table">
      <TableHeader testID="holiday-list-table-header">
        <TableRow>
          <TableCell title="Holiday" width="33%" />
          <TableCell title="Date" width="33%" />
          <TableCell title="Country" width="33%" />
        </TableRow>
      </TableHeader>
      <TableBody testID="holiday-list-table-body">
        {holidays.holidays.map((holiday: Holiday) => {
          return (
            <TableRow key={`row--${holiday.name}--${holiday.date.year}`}>
              <TableCell title={holiday.name} />
              <TableCell>{renderDate(holiday.date)}</TableCell>
              <TableCell title={holiday.country} />
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default connector(HolidayList);
