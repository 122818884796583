import actions, { ProductsAction } from 'redux/products/actions';
import { ProductsState } from 'state/productPageState';

import { SearchParam } from '../firms/actions';

export const initialState: ProductsState = {
  loading: false,
  allProducts: [],
  scheduledProductsTransitionList: [],
  symbols: [],
  searchText: '',
};

export default function reducer(
  state: ProductsState = initialState,
  action: ProductsAction,
): ProductsState {
  switch (action.type) {
    case actions.SET_SEARCH_TEXT:
      return { ...state, searchText: (action.payload as SearchParam).value };
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
