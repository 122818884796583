import apiClient from './apiClient';

export async function getSettlementData(params: object) {
  return apiClient
    .post(`/settlements`, params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function overrideSettlementPrices(params: object) {
  return apiClient
    .post(`/instruments/override-settlement-price`, params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
