import {
  PositionLimitFcmSubscription,
  PositionLimitFirmSubscription,
} from 'state/positionLimitSubscriptionsPage';

import actions, { PositionLimitSubscriptionsAction } from './actions';

export interface PositionLimitSubscriptionsState {
  loading: boolean;
  showCreateSubscriptionModal: boolean;
  showConfirmRemoveModal: boolean;
  positionLimitFcmSubscriptions: PositionLimitFcmSubscription[];
  positionLimitFirmSubscriptions: PositionLimitFirmSubscription[];
  selectedRow: PositionLimitFcmSubscription | PositionLimitFirmSubscription;
}

export const initialState: PositionLimitSubscriptionsState = {
  loading: false,
  showCreateSubscriptionModal: false,
  showConfirmRemoveModal: false,
  positionLimitFcmSubscriptions: [],
  positionLimitFirmSubscriptions: [],
  selectedRow: {} as PositionLimitFirmSubscription | PositionLimitFcmSubscription,
};

export default function positionLimitSubscriptionsReducer(
  state: PositionLimitSubscriptionsState = initialState,
  action: PositionLimitSubscriptionsAction,
): PositionLimitSubscriptionsState {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SHOW_CREATE_SUBSCRIPTION_MODAL:
      return { ...state, showCreateSubscriptionModal: true, ...action.payload };
    case actions.HIDE_CREATE_SUBSCRIPTION_MODAL:
      return { ...state, showCreateSubscriptionModal: false, ...action.payload };
    case actions.SHOW_CONFIRM_REMOVE_MODAL:
      return {
        ...state,
        showConfirmRemoveModal: true,
        selectedRow: (action.payload as any).selectedRow,
      };
    case actions.HIDE_CONFIRM_REMOVE_MODAL:
      return {
        ...state,
        showConfirmRemoveModal: false,
        selectedRow: {} as PositionLimitFirmSubscription | PositionLimitFcmSubscription,
      };
    default:
      return state;
  }
}
