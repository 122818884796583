import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';
import axios from 'axios';
import { AuthenticatedUser } from 'redux/auth/sagas';

import apiClient, { DEV_ENVIRONMENT, DEV_URL } from './apiClient';
import clearingApiClient from './clearingApiClient';

export type Auth0Config = {
  authRequired: boolean;
  domain: string;
  clientId: string;
  stack?: string;
};

export async function getAuth0Config(): Promise<Auth0Config> {
  if (process.env.NODE_ENV === DEV_ENVIRONMENT) {
    if (process.env.REACT_APP_AUTH0_DOMAIN) {
      return {
        authRequired: true,
        // @ts-ignore
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        // @ts-ignore
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      };
    }
    const response = await axios.get(`${DEV_URL}/auth0-config`);
    return response.data as Auth0Config;
  }
  const response = await axios.get(`/auth0-config`);
  return response.data as Auth0Config;
}

export async function getAuth0Client(auth0Config: Auth0Config) {
  const auth0 = await createAuth0Client({
    domain: auth0Config.domain,
    client_id: auth0Config.clientId,
    redirect_uri: `${window.location.origin}/implicit/callback`,
    responseType: 'token id_token',
    useRefreshTokens: true,
  });
  apiClient.interceptors.request.use(
    async (config) => {
      await auth0.getTokenSilently();
      const claims = await auth0.getIdTokenClaims();
      // eslint-disable-next-line no-underscore-dangle
      const idToken = claims?.__raw;
      config.headers.common.Authorization = `Bearer ${idToken}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  clearingApiClient.interceptors.request.use(
    async (config) => {
      await auth0.getTokenSilently();
      const claims = await auth0.getIdTokenClaims();
      // eslint-disable-next-line no-underscore-dangle
      const idToken = claims?.__raw;
      config.headers.common.Authorization = `Bearer ${idToken}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return auth0;
}

export async function getAuth0Token(auth0: Auth0Client) {
  await auth0.getTokenSilently();
  const claims = await auth0.getIdTokenClaims();
  // eslint-disable-next-line no-underscore-dangle
  return claims?.__raw;
}

export async function handleAuthentication(auth0: Auth0Client) {
  await auth0.handleRedirectCallback();
  const profile = await auth0.getUser();
  const isAuthenticated = await auth0.isAuthenticated();
  return {
    loading: false,
    authenticated: isAuthenticated,
    profile,
  };
}

export async function checkAuthentication(auth0: Auth0Client): Promise<AuthenticatedUser> {
  await auth0.checkSession();
  const profile = await auth0.getUser();
  const isAuthenticated = await auth0.isAuthenticated();
  const token = await getAuth0Token(auth0);
  return {
    loading: false,
    authenticated: isAuthenticated,
    profile,
    token,
  };
}

export function signIn(auth0: Auth0Client) {
  if (auth0) {
    auth0.loginWithRedirect();
  }
}

export async function signOut(auth0: Auth0Client) {
  const auth0Config = await getAuth0Config();
  auth0.logout({
    returnTo: window.location.origin,
    client_id: auth0Config.clientId,
  });
}
