import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { HANDLE_AUTHENTICATION_CALLBACK } from 'redux/auth/actions';

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const Callback = connect(mapStateToProps)(({ dispatch, user }) => {
  if (user.authenticated) {
    return <Redirect to="/" />;
  }
  dispatch({ type: HANDLE_AUTHENTICATION_CALLBACK });

  return <div className="text-center">Loading user profile.</div>;
});

export default Callback;
