interface ButtonsProps {
  searchButtonDisabled: boolean;
  onReset: () => void;
}

const defaultButtons = ({ onReset, searchButtonDisabled }: ButtonsProps) => {
  return [
    {
      key: 0,
      label: 'Clear',
      execute: onReset,
      disabled: false,
      visible: true,
      variant: 'secondary',
      type: 'button',
    },
    {
      key: 1,
      label: 'Search',
      type: 'submit',
      disabled: searchButtonDisabled,
      visible: true,
    },
  ];
};

export default defaultButtons;
