import { HStack } from '@cbhq/cds-web/layout';
import { TextHeadline } from '@cbhq/cds-web/typography/TextHeadline';
import { TextLabel1 } from '@cbhq/cds-web/typography/TextLabel1';
import { TextLabel2 } from '@cbhq/cds-web/typography/TextLabel2';
import React, { ReactNode } from 'react';

interface DetailRowProps {
  label?: string | number | ReactNode;
  value?: string | number | ReactNode;
  children?: any;
  topRow?: boolean;
  bottomRow?: boolean;
}

const DetailRow = ({ label, value, children, topRow, bottomRow }: DetailRowProps) => (
  <HStack
    as="div"
    spacingTop={topRow ? 3 : 0}
    spacingStart={3}
    spacingBottom={bottomRow ? 3 : 0}
    justifyContent="space-between"
  >
    <TextLabel1 as="span" spacingEnd={3}>
      {label}
    </TextLabel1>
    <TextLabel2 as="span">{value}</TextLabel2>
    {children}
  </HStack>
);

export default DetailRow;

DetailRow.defaultProps = {
  label: '',
  value: '',
  bottomRow: true,
  topRow: false,
};
