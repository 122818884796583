import { InstrumentInfo } from 'state/instrumentPageState';

import actions from '../instruments/actions';
import { InstrumentsAction } from './actions';

export type InstrumentsState = {
  loading: boolean;
  instruments: InstrumentInfo[];
  showConfirmAmendModal: boolean;
};

export const initialState: InstrumentsState = {
  loading: false,
  instruments: [],
  showConfirmAmendModal: false,
};

export default function instrumentsReducer(
  state: InstrumentsState = initialState,
  action: InstrumentsAction,
): InstrumentsState {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SHOW_MODAL:
      return { ...state, showConfirmAmendModal: true, ...action.payload };
    case actions.HIDE_MODAL:
      return { ...state, showConfirmAmendModal: false, ...action.payload };
    default:
      return state;
  }
}
