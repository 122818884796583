import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import { IsRowSelectable, RowClickedEvent } from 'ag-grid-community';
import { AgGridColumnProps, AgGridReact, AgGridReactProps } from 'ag-grid-react';
import Loader from 'components/layout/Loader';
import React, { CSSProperties } from 'react';
import { EditRow, InstrumentInfo } from 'state/instrumentPageState';
import convertEnteredValue from 'utils/convertEnteredValue';
import updateRowData from 'utils/updateRowData';
import validateCell from 'utils/validateCell';

export interface AgGridProps {
  defaultColDef: DefaultColDefProps;
  columns: AgGridTableColumnProps[];
  className: string;
  rowData: EditRow[] | InstrumentInfo[];
  loading: boolean;
  rowKey: string;
  rowSelection?: rowSelectionProps;
  cellSelection?: cellSelectionProps;
  setExportData?: Function;
  paginationPageSize?: number;
  pagination?: boolean;
  summaryData: SummaryDataProps;
  hideColumn?: HiddenColumnProps;
  height?: number;
  isRowSelectable?: IsRowSelectable;
  onRowClicked?: (event: RowClickedEvent) => void;
}

interface HiddenColumnProps {
  field: string;
  show: boolean;
}

interface DefaultColDefProps {
  flex: number;
  minWidth: number;
  resizable: boolean;
  headerCheckboxSelection: boolean;
  checkboxSelection: boolean;
}

export interface AgGridTableColumnProps {
  field?: string; // field is not needed for Action columns
  headerName: string;
  headerClass?: string;
  key?: string;
  sortable?: boolean;
  filter?: boolean;
  editable?: boolean;
  type?: string;
  width?: number;
  children?: AgGridTableColumnProps[];
  render?: Function;
  headerCheckboxSelection?: boolean;
  checkboxSelection?: boolean;
  pinnedRowCellRenderer?: string;
  pinnedRowCellRendererParams?: CSSStyleProps;
  cellClassRules?: CellClassRules;
}

interface CellClassRules {
  'cell-pass': (params: any) => boolean;
}

export interface ExtendedColumnProps {
  key?: string;
}

interface CSSStyleProps {
  style: CSSProperties;
}

interface rowSelectionProps {
  onChange: Function;
}

interface cellSelectionProps {
  onChange: Function;
}

export interface SummaryDataProps {
  showTop: boolean;
  showBottom: boolean;
  pinnedRowData: Object[];
  frameworkComponents: Object;
}

export interface ColDefProps {
  field?: string;
  headerName: string;
  headerClass?: string;
  sortable?: boolean;
  filter?: boolean;
  width?: number;
  children?: AgGridTableColumnProps[];
  floatingFilter?: boolean;
  checkboxSelection?: boolean;
  cellRendererFramework?: Function;
}

type ColumnProps = {
  aggFunc: null;
  colId: string;
  flex: null;
  hide: boolean;
  pinned: null;
  pivot: boolean;
  pivotIndex: null;
  rowGroup: boolean;
  rowGroupIndex: null;
  sort: null;
  sortIndex: null;
  width: number;
};

export class AgGridWrapper extends React.Component<AgGridProps> {
  public colDefs: ColDefProps[];

  public tableName: string;

  public gridApi: AgGridReactProps = {};

  public gridColumnApi: AgGridColumnProps = {};

  constructor(props: AgGridProps) {
    super(props);
    this.colDefs = [];
    this.tableName = props.className.split(' ').join('-');
  }

  static getRowStyle = (params: any) => {
    return params.node.rowPinned ? { background: '#abc', fontSize: '1rem' } : {};
  };

  static cellRenderer = (params: any, renderFunc: Function, dataIndex: string) => {
    if (renderFunc.length === 2) {
      return renderFunc(params.value, params.data);
    }
    const newParams = dataIndex ? params.value : params.data;
    return renderFunc(newParams);
  };

  onGridReady = (params: any) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const columnState = JSON.parse(localStorage.getItem(this.tableName) || '[]');
    const gridColumnState = JSON.stringify(params.columnApi.getColumnState());

    if (columnState) {
      params.columnApi.applyColumnState(columnState);
    }
    params.api.sizeColumnsToFit();
    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  onRowDataChanged = (params: any) => {
    const hideColumn = this.props.hideColumn;
    if (hideColumn) {
      const { field, show } = hideColumn;
      params.columnApi.setColumnVisible(field, show);
      params.api.sizeColumnsToFit();
    }
  };

  onSelectionChanged = (params: any) => {
    const { rowKey, rowSelection } = this.props;
    if (!rowSelection) return;

    const selectedNodes = params.api.getSelectedNodes();
    rowSelection.onChange(
      selectedNodes.map((row: any) => row.data[rowKey]),
      selectedNodes.map((row: any) => row.data),
    );
  };

  onCellValueChanged = (params: any) => {
    const { rowData, cellSelection } = this.props;
    if (!cellSelection) return;

    const columnEdited = params.column.colId;
    let enteredValue = params.newValue;

    enteredValue = convertEnteredValue(enteredValue);

    const newRowData = [...rowData];
    const editIndex = newRowData.findIndex((row) => row.productId === null);
    const isValidCellValue = validateCell(enteredValue);

    const updatedRowData = updateRowData(
      newRowData,
      editIndex,
      enteredValue,
      isValidCellValue,
      columnEdited,
    );
    cellSelection.onChange(updatedRowData);
  };

  onColumnMoved = (params: any) => {
    const columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem(this.tableName, columnState);
  };

  onFilterChanged = (params: any) =>
    this.props.setExportData &&
    this.props.setExportData(params.api.rowModel.rowsToDisplay.map((node: any) => node.data));

  componentDidUpdate() {
    const { columns, rowSelection } = this.props;

    const columnState = JSON.parse(localStorage.getItem(this.tableName) || '[]');
    const persistedColumns: (ColumnProps & ColDefProps)[] = [];
    columnState.forEach((col: ColumnProps) => {
      columns.forEach((column: ColDefProps) => {
        if (column.field === col.colId) {
          persistedColumns.push({
            ...col,
            ...column,
          });
        }
      });
    });

    if (this.colDefs.length) {
      return;
    }

    const createColumns = (cols: ((ColumnProps & ColDefProps) | AgGridTableColumnProps)[]) => {
      return cols.map((column: AgGridTableColumnProps, index: number) => {
        const {
          field,
          headerName,
          headerClass,
          sortable,
          filter,
          editable,
          checkboxSelection,
          width,
          children,
          render: renderColumn,
          cellClassRules,
        } = column;

        return {
          field,
          headerName,
          headerClass,
          sortable,
          filter,
          editable,
          width,
          children,
          cellClassRules,
          floatingFilter: filter,
          checkboxSelection: checkboxSelection || (rowSelection && index === 0),
          cellRendererFramework:
            renderColumn && field
              ? (params: any) => AgGridWrapper.cellRenderer(params, renderColumn, field)
              : undefined,
        };
      });
    };

    const colDefs =
      persistedColumns.length !== 0 ? createColumns(persistedColumns) : createColumns(columns);
    this.colDefs = colDefs;
  }

  render() {
    const {
      rowData,
      loading,
      pagination,
      paginationPageSize,
      className,
      summaryData,
      defaultColDef,
      isRowSelectable,
      onRowClicked,
    } = this.props;

    const { showTop, showBottom, pinnedRowData, frameworkComponents } = summaryData || {};

    const topSummary = showTop ? pinnedRowData : [];
    const bottomSummary = showBottom ? pinnedRowData : [];

    return (
      <div
        className={`ag-theme-balham ${className}`}
        style={{ width: '100%', height: this.props.height || 600 }}
      >
        {loading ? (
          <Loader />
        ) : (
          <AgGridReact
            columnDefs={this.colDefs}
            rowData={rowData}
            rowHeight={50}
            rowSelection="multiple"
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            defaultColDef={defaultColDef || { resizable: true }}
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
            onColumnMoved={this.onColumnMoved}
            enableCellTextSelection
            onFilterChanged={this.onFilterChanged}
            pinnedTopRowData={topSummary}
            pinnedBottomRowData={bottomSummary}
            frameworkComponents={frameworkComponents}
            onRowDataChanged={this.onRowDataChanged}
            onCellValueChanged={this.onCellValueChanged}
            isRowSelectable={isRowSelectable}
            onRowClicked={onRowClicked}
          />
        )}
      </div>
    );
  }
}
