import sortedUniq from 'lodash/sortedUniq';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { setLoadingSpinner, unsetLoadingSpinner } from 'redux/loadingSpinners';
import actions, { SET_STATE, UpdateProductLimitsAction } from 'redux/products/actions';
import { setErrorMessage, setMessage } from 'redux/setMessage';
import getProductsInstrumentsInformation from 'services/getProductsInstrumentsInformation';
import {
  cancelScheduledProductsTransitionsState,
  loadScheduledProductsTradingStateTransitionsList,
  scheduleProductsTransitionsState,
} from 'services/productsTradingStateTransitions';
import updateProductLimits from 'services/updateProductLimits';
import {
  ProductInstrumentInfo,
  ScheduledProductTradingStateTransition,
} from 'state/productPageState';
import stringComparator from 'utils/comparators/stringComparator';

import { RootState } from '../reducers';

type Params = {
  type: typeof actions.LOAD_PRODUCTS;
  payload: object;
};

export function* LOAD_PRODUCTS({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);
  const { response, error } = yield call(getProductsInstrumentsInformation, payload);
  if (!error) {
    const symbols = sortedUniq(
      response.data
        .map((prod: ProductInstrumentInfo) => prod.instrumentList.map((ins) => ins.symbol))
        .flat(),
    );

    const allProducts = response.data
      .map((p: ProductInstrumentInfo) => ({
        ...p,
        priceBandVal: p.priceBand * p.tickSize,
      }))
      .sort((a: ProductInstrumentInfo, b: ProductInstrumentInfo) =>
        stringComparator(a.productCode, b.productCode),
      );

    yield put({
      type: actions.SET_STATE,
      payload: {
        allProducts,
        symbols,
        loading: false,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* UPDATE_PRODUCT_LIMITS({ payload }: UpdateProductLimitsAction) {
  const { error } = yield call(updateProductLimits, payload);

  if (!error) {
    const state: RootState = yield select();
    const { allProducts } = state.products;
    const {
      maxOrderSize,
      priceBand,
      priceLimitRange,
      initialMargin,
      marketOrderProtectionTicks,
    } = payload as any;
    const oldProduct =
      allProducts.find(
        (prod: ProductInstrumentInfo) => prod.productId === (payload as any).productId,
      ) || ({} as ProductInstrumentInfo);

    const updatedProduct = {
      ...oldProduct,
      maxOrderSize,
      priceBand,
      priceLimitRange,
      initialMargin,
      marketOrderProtectionTicks,
      priceBandVal: priceBand * oldProduct?.tickSize,
    };
    const newProducts = allProducts.map((prod: ProductInstrumentInfo) =>
      prod.productId === oldProduct?.productId ? updatedProduct : prod,
    );

    yield put({
      type: actions.SET_STATE,
      payload: {
        allProducts: newProducts,
        loading: false,
      },
    });
  } else {
    yield setErrorMessage(error);
  }

  yield unsetLoadingSpinner(SET_STATE);
}

export function* LOAD_SCHEDULED_PRODUCTS_TRANSITIONS_LIST({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);
  const { response, error } = yield call(loadScheduledProductsTradingStateTransitionsList, payload);
  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        scheduledProductsTransitionList: response.data,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* CANCEL_SCHEDULED_PRODUCT_TRADING_STATE_TRANSITION_SAGA({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);
  const state: RootState = yield select();

  const { error, response } = yield call(
    cancelScheduledProductsTransitionsState,
    (payload as any).transitionId,
  );
  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        scheduledProductsTransitionList: state.products.scheduledProductsTransitionList.filter(
          (spt: ScheduledProductTradingStateTransition) =>
            spt.transitionId !== Number((payload as any).transitionId),
        ),
      },
    });
    yield setMessage(response, `Canceled transition ${(payload as any).transitionId}`);
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* SCHEDULE_PRODUCT_TRADING_STATE_TRANSITION_SAGA({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);
  const state: RootState = yield select();
  const { response, error } = yield call(scheduleProductsTransitionsState, payload);
  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        scheduledProductsTransitionList: [
          ...state.products.scheduledProductsTransitionList,
          response.data,
        ],
      },
    });
    yield setMessage(response, `Added new transition for product ${(payload as any).productId}`);
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_PRODUCTS, LOAD_PRODUCTS),
    takeEvery(actions.UPDATE_PRODUCT_LIMITS, UPDATE_PRODUCT_LIMITS),
    takeEvery(
      actions.LOAD_SCHEDULED_PRODUCTS_TRANSITIONS_LIST,
      LOAD_SCHEDULED_PRODUCTS_TRANSITIONS_LIST,
    ),
    takeEvery(
      actions.SCHEDULE_PRODUCT_TRADING_STATE_TRANSITION,
      SCHEDULE_PRODUCT_TRADING_STATE_TRANSITION_SAGA,
    ),
    takeEvery(
      actions.CANCEL_SCHEDULED_PRODUCT_TRADING_STATE_TRANSITION,
      CANCEL_SCHEDULED_PRODUCT_TRADING_STATE_TRANSITION_SAGA,
    ),
  ]);
}
