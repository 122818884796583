import formatDataForExport from './formatDataForExport';

const DOWNLOAD_MIME_TYPE = 'text/csv';

export const exportFile = (data: any, fileName: string) => {
  const blob = new Blob([data], {
    type: DOWNLOAD_MIME_TYPE,
  });
  const blobUrl = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', blobUrl);
  link.setAttribute('download', fileName);
  link.setAttribute('header', 'Content-Disposition: attachment');

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFile = (headers: object[], rows: any, fileName: string) => {
  const formattedData = formatDataForExport(headers, rows);
  exportFile(formattedData, fileName);
};
