import { AllDropCopySubscriptionsArray, AnyDropCopySubscription } from 'state/usersPageState';

const getDropCopySubscriptionsForDropCopyUser = (
  dropCopySubscriptions: AllDropCopySubscriptionsArray,
  dropCopyUserId: number,
) => {
  return dropCopySubscriptions.filter(
    (dcs: AnyDropCopySubscription) => dcs?.dropCopyUserId === dropCopyUserId,
  );
};

export default getDropCopySubscriptionsForDropCopyUser;
