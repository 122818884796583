import capitalizeText from './capitalizeText';

const createPageName = (url: string) => {
  const urlInfo = url.split('/');
  let titleList: string | string[] = urlInfo[2].split('-');
  let title;

  if (titleList.length === 1) {
    title = capitalizeText(titleList.join());
    return title;
  }
  if (titleList.length >= 2) {
    titleList = titleList.map((string: any) => capitalizeText(string));
    title = titleList.join(' ');
  }
  return title;
};

export default createPageName;
