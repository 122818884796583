import removeEmpty from 'utils/removeEmpty';

import apiClient from './apiClient';

export default async function getEvents(params: object) {
  const reqParams = removeEmpty(params);
  return apiClient
    .post('/event-ticker', reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
