import { OrderDetailsState } from './reducers';

export const SET_STATE = 'orderDetails/SET_STATE';
export const LOAD_ORDER_DETAILS = 'orderDetails/LOAD_ORDER_DETAILS';
export const LOAD_ORDER_DETAILS_FAILED = 'orderDetails/LOAD_ORDER_DETAILS_FAILED';

interface SetOrderDetailsStateAction {
  type: typeof SET_STATE;
  payload: Partial<OrderDetailsState>;
}

interface LoadOrderDetailsAction {
  type: typeof LOAD_ORDER_DETAILS;
  payload: object;
}

const actions = {
  SET_STATE,
  LOAD_ORDER_DETAILS,
  LOAD_ORDER_DETAILS_FAILED,
};

export type OrderDetailsAction = SetOrderDetailsStateAction | LoadOrderDetailsAction;

export default actions;
