import {
  isValidColonInput,
  isValidInput,
  isValidNumberInput,
  isValidWildcard,
} from 'utils/validators';

import { DataType } from './types';

const getFormValidationFunction = (dataType: string, fn: Function | undefined) => {
  if (fn) {
    return fn;
  }
  switch (dataType) {
    case DataType.NUMBER:
      return isValidNumberInput;
    case DataType.STRING:
      return isValidInput;
    case DataType.MULTI_WILDCARD:
      return isValidWildcard;
    case DataType.MULTI_WILDCARD_COLON:
      return isValidColonInput;
    case DataType.DATE:
    case DataType.DATE_AND_TIME:
    case DataType.DATE_RANGE:
    case DataType.SELECT:
    case DataType.SINGLE_SELECT:
    case DataType.SELECT_WILDCARD:
    case DataType.CHECKBOX:
    case DataType.DATE_TIME_NOW:
    case DataType.LABEL_CAPTION:
      // no validation required right now.
      return () => true;
    default:
      return isValidInput;
  }
};

export default getFormValidationFunction;
