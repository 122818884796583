import { Status } from 'state/sharedEnums';

export enum MappingType {
  NCP = 'NCP',
  ITM = 'ITM',
}

export enum ParticipantType {
  Client = 'CLIENT',
  House = 'HOUSE',
}

export enum PositionType {
  Net = 'NET',
  Gross = 'GROSS',
}

export enum FeeGroup {
  OTHER = 'OTHER',
  INTERNAL_USE_ONLY = 'INTERNAL_USE_ONLY',
  FCM = 'FCM',
  LEAD_MARKET_MAKER = 'LEAD_MARKET_MAKER',
  MARKET_MAKER = 'MARKET_MAKER',
  NON_PRO = 'NON_PRO',
  PRO = 'PRO',
}

export type Itm = {
  id: number;
  ncpId: string;
  name: string;
  lastUpdatedTime: number;
  positionType: PositionType;
};

export type ItmDetails = {
  code: string;
  name: string;
};

export type Ncp = {
  id: number;
  name: string;
  status: Status;
  lastUpdatedTime: number;
  fcmId: number | string;
  positionType: PositionType;
};

export type NcpFeeGroup = {
  ncpCode: string;
  feeGroup: FeeGroup;
};

export enum TradeReportStatus {
  Pending = 'PENDING',
  Queued = 'QUEUED',
  Rejected = 'REJECTED',
  Cleared = 'CLEARED',
  Canceled = 'CANCELED',
}

export enum ReportTransactionType {
  New = 'NEW',
  Cancel = 'CANCEL',
  Replace = 'REPLACE',
}

export interface NodalInstrument {
  productSymbol: string;
  maturityDate: object;
  securityType: string;
  actionOnFarLeg: boolean;
}

export interface TradeSide {
  side: string;
  subAccount: string;
  itmName: string;
  ncpName: string;
  clearingFirmCode: string;
  traderId: string;
  sessionId: string;
  lmxOrderId: string;
  clientOrderId: string;
  fillId: string;
}

export enum TradeAckStatus {
  Cleared = 0,
  Rejected = 1,
  Queued = 2,
}

export type TradeReportAck = {
  tradeReportId: number;
  tradeId: string;
  nodalTradeId: string;
  tradeAckStatus: TradeAckStatus;
  rejectReason: string;
  tradeAckTime: object;
};

export type Trade = {
  tradeId: string;
  tradeMatchId: string;
  quantity: number;
  price: number;
  securityId: string;
  instrument: NodalInstrument;
  farInstrument: NodalInstrument;
  buySide: TradeSide;
  sellSide: TradeSide;
  tradeDateTime: object;
  tradeSourceSequence: number;
};

export type TradeReport = {
  tradeReportId: number;
  transactionType: ReportTransactionType;
  previouslyReported: boolean;
  trade: Trade;
  status: TradeReportStatus;
  key: string;
  ack: TradeReportAck;
};

export type FlattenedTradeReport = {
  tradeReportId: number;
  transactionType: ReportTransactionType;
  previouslyReported: boolean;
  tradeId: string;
  tradeMatchId: string;
  contract: string;
  quantity: number;
  price: number;
  buySideItm: string;
  buySideNcp: string;
  buySideClearingFirmCode: string;
  sellSideItm: string;
  sellSideNcp: string;
  sellSideClearingFirmCode: string;
  tradeDateTime: string;
  status: TradeReportStatus;
  key: string;
  ack: TradeReportAck;
}

export type TradeReports = Record<string, TradeReport>;
