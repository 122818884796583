import axios from 'axios';

import { DEV_ENVIRONMENT } from './apiClient';

const DEV_URL = process.env.REACT_APP_DEV_BACKEND_CLEARING_URL;

const clearingApiClient = axios.create({
  baseURL: process.env.NODE_ENV === DEV_ENVIRONMENT ? `${DEV_URL}/clearing/api/` : '/clearing/api/',
});

export default clearingApiClient;
