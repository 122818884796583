import { put } from 'redux-saga/effects';

export function* setLoadingSpinner(action: any) {
  yield put({
    type: action,
    payload: {
      loading: true,
    },
  });
}

export function* unsetLoadingSpinner(action: any) {
  yield put({
    type: action,
    payload: {
      loading: false,
    },
  });
}
