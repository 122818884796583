import removeEmpty from '../utils/removeEmpty';
import apiClient from './apiClient';

export default async function getTrades(params: object) {
  const reqParams = removeEmpty(params);
  return apiClient
    .post(`/fills/trades`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
