import { all, call, put, takeEvery } from 'redux-saga/effects';
import { setLoadingSpinner, unsetLoadingSpinner } from 'redux/loadingSpinners';
import { setErrorMessage } from 'redux/setMessage';
import getMarketOrderBook from 'services/getMarketOrderBook';
import getOrderBook from 'services/getOrderBook';

import actions, { LoadOrderBookAction, SET_STATE } from './actions';

export function* loadOrderBook() {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(getOrderBook);

  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        orderBook: response.data,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* loadOrderBookRecreation({ payload }: LoadOrderBookAction) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(getMarketOrderBook, payload);

  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        marketOrderBook: response.data,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_ORDER_BOOK, loadOrderBook),
    takeEvery(actions.LOAD_ORDER_BOOK_RECREATION, loadOrderBookRecreation),
  ]);
}
