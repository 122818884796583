import actions, { ClearingAction, CreateModalActionPayload } from 'redux/clearing/actions';
import {
  Itm,
  ItmDetails,
  Ncp,
  NcpFeeGroup,
  TradeReport,
  TradeReports,
} from 'state/clearingDataPageState';

export interface ClearingState {
  loading: boolean;
  ncpList: Ncp[];
  itmList: Itm[];
  itmDetailsList: ItmDetails[];
  ncpFeeGroupList: NcpFeeGroup[];
  showCreateMappingModal: boolean;
  mappingType: string;
  tradeReports: TradeReports;
  rejectedTrades: TradeReport[];
  pendingTrades: TradeReport[];
  showEditRejectedTradeModal: boolean;
}

export const initialState: any = {
  loading: false,
  ncpList: [],
  itmList: [],
  itmDetailsList: [],
  ncpFeeGroupList: [],
  showCreateMappingModal: false,
  mappingType: '',
  tradeReports: {} as TradeReports,
  rejectedTrades: [],
  pendingTrades: [],
  showEditRejectedTradeModal: false,
};

export default function clearingReducers(
  state: ClearingState = initialState,
  action: ClearingAction,
): ClearingState {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SHOW_CREATE_ITM_MODAL:
    case actions.SHOW_CREATE_NCP_MODAL:
      return {
        ...state,
        showCreateMappingModal: true,
        mappingType: (action.payload as CreateModalActionPayload).mappingType,
      };
    case actions.HIDE_CREATE_ITM_MODAL:
    case actions.HIDE_CREATE_NCP_MODAL:
      return { ...state, showCreateMappingModal: false, mappingType: '' };
    case actions.SHOW_EDIT_REJECTED_TRADE_MODAL:
      return { ...state, showEditRejectedTradeModal: true };
    case actions.HIDE_EDIT_REJECTED_TRADE_MODAL:
      return { ...state, showEditRejectedTradeModal: false };
    default:
      return state;
  }
}
