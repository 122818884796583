import apiClient from './apiClient';

async function updateFirmProductLimits(params: object) {
  const reqParams = params;

  return apiClient
    .post(`/firm-product-limits/product`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export default updateFirmProductLimits;
