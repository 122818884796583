import { NavigationTitle } from '@cbhq/cds-web/navigation';
import { NavigationBar } from '@cbhq/cds-web/navigation/NavigationBar';
import UserMenu from 'components/layout/TopBar/UserMenu/index';
import React from 'react';
import { useSelector } from 'react-redux';

const TopBar = () => {
  const [pageName] = useSelector(({ application }) => [application.pageName]);

  return (
    <>
      <NavigationBar start={<NavigationTitle>{pageName}</NavigationTitle>} end={<UserMenu />} />
    </>
  );
};

export default TopBar;
