import apiClient from 'services/apiClient';

import removeEmpty from '../utils/removeEmpty';

export default async function getPositions(params: object) {
  const reqParams = removeEmpty(params);

  const { expiredFilter } = reqParams;
  const { firmId } = params as any;

  return apiClient
    .get(`/positions/${firmId}?expiredFilter=${expiredFilter}`, params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
