export const SET_STATE = 'settlements/SET_STATE';
export const LOAD_SETTLEMENT_DATA = 'settlements/LOAD_SETTLEMENT_DATA';
export const LOAD_SETTLEMENT_DATA_FAILED = 'settlements/LOAD_SETTLEMENT_DATA_FAILED';
export const LOAD_INTRADAY_SETTLEMENTS = 'settlements/LOAD_INTRADAY_SETTLEMENTS';
export const LOAD_END_OF_DAY_SETTLEMENTS = 'settlements/LOAD_END_OF_DAY_SETTLEMENTS';
export const LOAD_FINAL_SETTLEMENTS = 'settlements/LOAD_FINAL_SETTLEMENTS';

export const SHOW_MODAL = 'settlements/SHOW_MODAL';
export const HIDE_MODAL = 'settlements/HIDE_MODAL';
export const OVERRIDE_SETTLEMENT_PRICES = 'settlements/OVERRIDE_SETTLEMENT_PRICES';

interface SetSettlementsStateAction {
  type: typeof SET_STATE;
  payload: object;
}

interface LoadSettlementsDataAction {
  type: typeof LOAD_SETTLEMENT_DATA;
  payload: object;
}

interface LoadIntradaySettlementsAction {
  type: typeof LOAD_INTRADAY_SETTLEMENTS;
  payload: object;
}

interface LoadFinalSettlementsAction {
  type: typeof LOAD_FINAL_SETTLEMENTS;
  payload: object;
}

interface LoadEndOfDaySettlementsAction {
  type: typeof LOAD_END_OF_DAY_SETTLEMENTS;
  payload: object;
}

interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: object;
}

interface HideModalAction {
  type: typeof HIDE_MODAL;
  payload: object;
}

interface OverrideSettlementPricesAction {
  type: typeof OVERRIDE_SETTLEMENT_PRICES;
  payload: object;
}

const actions = {
  SET_STATE,
  LOAD_SETTLEMENT_DATA,
  LOAD_SETTLEMENT_DATA_FAILED,
  LOAD_INTRADAY_SETTLEMENTS,
  LOAD_END_OF_DAY_SETTLEMENTS,
  LOAD_FINAL_SETTLEMENTS,
  SHOW_MODAL,
  HIDE_MODAL,
  OVERRIDE_SETTLEMENT_PRICES,
};

export type SettlementsAction =
  | SetSettlementsStateAction
  | LoadSettlementsDataAction
  | LoadIntradaySettlementsAction
  | LoadEndOfDaySettlementsAction
  | LoadFinalSettlementsAction
  | ShowModalAction
  | HideModalAction
  | OverrideSettlementPricesAction;

export default actions;
