import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getUserInfo } from 'services/permissions';

import generalActions from '../application/actions';
import actions from './actions';

export function* loadUserInfo() {
  const { response, error } = yield call(getUserInfo);
  if (!error) {
    yield put({
      type: actions.USER_PERMISSIONS_SUCCESS,
      payload: response.data,
    });

    // Load all data only after the user's permissions are loaded.
    yield put({
      type: generalActions.LOAD_ALL_DATA,
      payload: {},
    });
  } else {
    // We must allow user to logout in case he is not authorized to hit /user-info endpoint
    yield put({
      type: actions.USER_PERMISSIONS_FAIL,
      payload: { failed: true },
    });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.USER_PERMISSIONS_REQUEST, loadUserInfo)]);
}
