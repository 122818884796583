import { UsersState } from 'redux/users/reducers';
import { FirmPosition } from 'state/firmPageState';
import { AdminUser, SelfMatchPreventions } from 'state/usersPageState';
import getPercentageChange from 'utils/getPercentageChange';
import { getSearchFieldErrorMessage } from 'utils/getSearchFieldErrorMessage';

/** Validation Helpers used in Form Rule Functions */
export const isValidNumberInput = (input: any) => {
  if (!input) {
    return true;
  }
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(input)) {
    return false;
  }
  return true;
};

export const isValidInput = (input: any) => {
  // check for <script> tags or any other invalid input.

  if (!input) {
    // Allow empty input fields.
    return true;
  }

  if (Array.isArray(input)) {
    return input.every((inp: any) => validateInput(inp));
  }
  return validateInput(input);
};

const validateInput = (input: any) => {
  // Allow letter, numbers, and hyphens inside the input.
  // Hyphens should be allowed because clientOrderId, and symbol(product) contains hyphens.
  const LETTER_NUMBER = /^[a-zA-Z0-9*\ _.-]+$/;
  return !!(input !== '' && input.match(LETTER_NUMBER));
};

export const isValidWildcard = (input: any) => {
  // check for <script> tags or any other invalid input.

  if (!input) {
    // Allow empty input fields.
    return true;
  }

  if (Array.isArray(input)) {
    return input.every((inp: any) => validateWildcard(inp));
  }
  return validateWildcard(input);
};

const validateWildcard = (input: any) => {
  // Allow letter, numbers, and hyphens inside the input.
  // Hyphens should be allowed because clientOrderId, and symbol(product) contains hyphens.
  const LETTER_NUMBER_COMMA = /^[a-zA-Z0-9,*\ _.-]+$/;
  return !!(input !== '' && input.match(LETTER_NUMBER_COMMA));
};

export const isValidColonInput = (input: any) => {
  if (!input) return true;
  if (Array.isArray(input)) return input.every((inp: any) => validateColonInput(inp));
  return validateColonInput(input);
};

const validateColonInput = (input: any) => {
  const COLON = /^[\w:*\ ,.-]+$/;
  return !!(input !== '' && input.match(COLON));
};

export const isUniqueUserName = (
  username: string | undefined,
  users: UsersState | any | undefined,
) => {
  if (!users || !username) {
    return false;
  }

  const { allTradingUsers, allDropCopyUsers, allMarketDataUsers } = users;
  return (
    allTradingUsers[username] === undefined &&
    allDropCopyUsers[username] === undefined &&
    allMarketDataUsers[username] === undefined
  );
};

export const isUniqueAdminUserName = (
  username: string,
  allAdminUsers: Record<string, AdminUser>,
) => {
  if (!username || !allAdminUsers) {
    return false;
  }

  return allAdminUsers[username] === undefined;
};

export const isValidEmailAddress = (input: any) => {
  const emailRegexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegexPattern.exec(input);
};

/** Form Rule Functions */
export const isEmailValidUnique = (allAdminUsers: Record<string, AdminUser>) => ({
  validator(rule: any, value: any) {
    if (!isValidEmailAddress(value)) {
      return Promise.reject(new Error('Enter a valid email address.'));
    }
    if (!isUniqueAdminUserName(value, allAdminUsers)) {
      return Promise.reject(new Error('This Username already exists. Choose another username'));
    }
    return Promise.resolve();
  },
});

export const isInputEmpty = () => ({
  validator(rule: any, value: any) {
    if (value === '') {
      return Promise.reject(new Error(getSearchFieldErrorMessage((rule as any).field)));
    }
    return Promise.resolve();
  },
});

export const isInputValid = () => ({
  validator(rule: any, value: any) {
    if (isValidInput(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(getSearchFieldErrorMessage((rule as any).field)));
  },
});

export const isInputValidNum = () => ({
  validator(rule: any, value: any) {
    if (isValidNumberInput(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(getSearchFieldErrorMessage((rule as any).field)));
  },
});

export const isNameValid = (mappingType: string) => ({
  validator(rule: any, value: any) {
    if (isValidInput(value)) {
      if ((value as string).length !== 3) {
        return Promise.reject(new Error(`${mappingType} names must be 3 characters`));
      }
      return Promise.resolve();
    }
    return Promise.reject(new Error(getSearchFieldErrorMessage((rule as any).field)));
  },
});

export const isItmNameValid = () => ({
  validator(rule: any, value: any) {
    if (isValidInput(value)) {
      if ((value as string).length > 20) {
        return Promise.reject(new Error(`Itm name cannot exceed 20 characters`));
      }
      return Promise.resolve();
    }
    return Promise.reject(new Error(getSearchFieldErrorMessage((rule as any).field)));
  },
});

export const isSmpIdValid = () => ({
  validator(rule: any, value: any) {
    if (isValidInput(value)) {
      if (String(value).startsWith('0')) {
        return Promise.reject(new Error('Smp Id cannot start with 0'));
      }
    }

    if (isValidInput(value)) {
      if ((value as string).length !== 8) {
        return Promise.reject(new Error(`Smp Id must be equal to 8 characters`));
      }

      return Promise.resolve();
    }
    return Promise.reject(new Error(getSearchFieldErrorMessage((rule as any).field)));
  },
});

export const isUniqueSmpId = (
  allSmps: SelfMatchPreventions[],
  currentSmpId: string | undefined,
) => ({
  validator(rule: any, value: any) {
    const smpIdExists = allSmps.find(
      (smp: SelfMatchPreventions) => smp.smpId === Number(currentSmpId),
    );
    if (smpIdExists) {
      return Promise.reject(new Error('The SMP ID already exists.'));
    }
    return Promise.resolve();
  },
});

export const isPositionLimitValid = () => ({
  validator(rule: any, value: any) {
    if (value < 0) {
      return Promise.reject(new Error('Position Limit cannot be less than $0'));
    }
    if (isValidNumberInput(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(getSearchFieldErrorMessage((rule as any).field)));
  },
});

export const isSenderCompIdValid = (firmCode: string | undefined, sessionCode: any) => ({
  validator(rule: any, value: any) {
    if (value !== undefined && isValidInput(value)) {
      if (value.length > 6) {
        return Promise.reject(new Error('Sender Comp Id cannot be more than 6 characters'));
      }
      if (value !== `${firmCode}${sessionCode}`) {
        return Promise.reject(
          new Error('Sender Comp Id should be a combination of Firm Code and Session Code'),
        );
      }
      return Promise.resolve();
    }
    return Promise.reject(new Error(getSearchFieldErrorMessage((rule as any).field)));
  },
});

export const isUserNameUnique = (users: UsersState) => ({
  validator(rule: any, value: any) {
    if (!isUniqueUserName(value, users)) {
      return Promise.reject(new Error('This Username already exists. Choose another username'));
    }
    if (isValidInput(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(getSearchFieldErrorMessage((rule as any).field)));
  },
});

export const getIsFirmCodeValid = (firmCode: string) => {
  if (isValidInput(firmCode)) {
    if (firmCode.length !== 3) {
      return 'Firm code must be 3 characters';
    }
  }
  return '';
};

export const getIsFirmNameUnique = (allFirms: FirmPosition[], firmName: string) => {
  const firm = allFirms.find((firm: FirmPosition) => firm.firmDetails.name === firmName);
  if (firm) {
    return 'Firm name is already in use.';
  }
  return '';
};

export const isAuth0GoodPassword = () => ({
  validator(rule: any, value: any) {
    // Good password definition for Auth0 passwords:
    // at least 8 characters including at least 3 of the following 4 types of characters:
    // a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*).

    const goodPasswordPattern = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
    if (!goodPasswordPattern.exec(value)) {
      return Promise.reject(
        new Error(`Entered password should follow the pattern:
        Have at least 8 characters including at least 3 of the following 4 types of characters:
        a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*)
      `),
      );
    }
    return Promise.resolve();
  },
});

export const getValidOverrideSettlementPriceError = (
  prevSettlementPrice: number | undefined,
  value?: any,
) => {
  if (isValidNumberInput(value) || value > 0 || value !== null) {
    if (Math.abs(getPercentageChange(prevSettlementPrice, value)) > 5) {
      return 'New settlement price differs more than 5% from previous day settlement. Please review before submitting.';
    }
  }
  return '';
};
