import { UsersState } from 'redux/users/reducers';
import { DropCopySubscriptionType, SubscriptionLevel } from 'state/sharedEnums';
import {
  AdminUser,
  Credentials,
  DropCopyUser,
  MarketDataUser,
  TradingUser,
} from 'state/usersPageState';

// Users actions.
export const LOAD_USERS = 'users/LOAD_USERS';
export const LOAD_SELF_MATCH_PREVENTIONS = 'users/LOAD_SELF_MATCH_PREVENTIONS';
export const ADD_SELF_MATCH_PREVENTIONS = 'users/ADD_SELF_MATCH_PREVENTIONS';
export const REMOVE_SELF_MATCH_PREVENTIONS = 'users/REMOVE_SELF_MATCH_PREVENTIONS';
export const SET_STATE = 'users/SET_STATE';
export const ADD_TRADING_USER = 'users/ADD_TRADING_USER';
export const UPDATE_TRADING_USER = 'users/UPDATE_TRADING_USER';
export const ADD_DROP_COPY_USER = 'users/ADD_DROP_COPY_USER';
export const UPDATE_DROP_COPY_USER = 'users/UPDATE_DROP_COPY_USER';
export const UPDATE_DROP_COPY_USER_STATE = 'users/UPDATE_DROP_COPY_USER_STATE';
export const ADD_ADMIN_USER = 'users/ADD_ADMIN_USER';
export const UPDATE_ADMIN_USER = 'users/UPDATE_ADMIN_USER';
export const ADD_MARKET_DATA_USER = 'users/ADD_MARKET_DATA_USER';
export const UPDATE_MARKET_DATA_USER = 'users/UPDATE_MARKET_DATA_USER';
export const CHANGE_USER_PASSWORD = 'users/CHANGE_USER_PASSWORD';
export const SHOW_ADD_NEW_SMP_MODAL = 'users/SHOW_ADD_NEW_SMP_MODAL';
export const HIDE_ADD_NEW_SMP_MODAL = 'users/HIDE_ADD_NEW_SMP_MODAL';
export const SHOW_REMOVE_SMP_MODAL = 'users/SHOW_REMOVE_SMP_MODAL';
export const HIDE_REMOVE_SMP_MODAL = 'users/HIDE_REMOVE_SMP_MODAL';

// Drop copy subscription actions.
export const ADD_DROP_COPY_SUBSCRIPTION = 'users/ADD_DROP_COPY_SUBSCRIPTION';
export const UPDATE_DROP_COPY_SUBSCRIPTION = 'users/UPDATE_DROP_COPY_SUBSCRIPTION';
export const REMOVE_DROP_COPY_SUBSCRIPTION = 'users/REMOVE_DROP_COPY_SUBSCRIPTION';

export interface LoadUsersAction {
  type: typeof LOAD_USERS;
  payload: object;
}

export interface LoadSelfMatchPreventionsAction {
  type: typeof LOAD_SELF_MATCH_PREVENTIONS;
  payload: object;
}

interface SetStateAction {
  type: typeof SET_STATE;
  payload: Partial<UsersState>;
}

export interface AddTradingUserAction {
  type: typeof ADD_TRADING_USER;
  payload: TradingUser;
}

export interface AddNewSmpModalAction {
  type: typeof SHOW_ADD_NEW_SMP_MODAL | typeof HIDE_ADD_NEW_SMP_MODAL;
  payload: object;
}

export interface RemoveSmpModalAction {
  type: typeof SHOW_REMOVE_SMP_MODAL | typeof HIDE_REMOVE_SMP_MODAL;
  payload: object;
}

export interface AddNewSmpAction {
  type: typeof ADD_SELF_MATCH_PREVENTIONS;
  payload: object;
}

export interface RemoveSmpAction {
  type: typeof REMOVE_SELF_MATCH_PREVENTIONS;
  payload: object;
}

export interface UpdateTradingUserAction {
  type: typeof UPDATE_TRADING_USER;
  payload: TradingUser;
}

export interface AddDropCopyUserAction {
  type: typeof ADD_DROP_COPY_USER;
  payload: DropCopyUser;
}

export interface UpdateDropCopyUserAction {
  type: typeof UPDATE_DROP_COPY_USER;
  payload: DropCopyUser;
}

export interface UpdateDropCopyUserStateAction {
  type: typeof UPDATE_DROP_COPY_USER_STATE;
  payload: DropCopyUser;
}

export interface AddAdminUserAction {
  type: typeof ADD_ADMIN_USER;
  payload: AdminUser;
}

export interface UpdateAdminUserAction {
  type: typeof UPDATE_ADMIN_USER;
  payload: AdminUser;
}

export interface AddMarketDataUserAction {
  type: typeof ADD_MARKET_DATA_USER;
  payload: MarketDataUser;
}

export interface UpdateMarketDataUserAction {
  type: typeof UPDATE_MARKET_DATA_USER;
  payload: object;
}
export interface ChangePasswordAction {
  type: typeof CHANGE_USER_PASSWORD;
  payload: Partial<Credentials>;
}

// drop copy subscriptions below.

interface DropCopySubscriptionsParams {
  id?: number;
  dropCopyUserId: number;
  fcmId?: number;
  firmId?: number;
  tradingUserId?: number;
  subscriptionType: DropCopySubscriptionType;
}

export interface DropCopySubscriptionsPayload {
  params: DropCopySubscriptionsParams;
  subscriptionLevel: SubscriptionLevel;
}

export interface AddDropCopySubscriptionAction {
  type: typeof ADD_DROP_COPY_SUBSCRIPTION;
  payload: DropCopySubscriptionsPayload;
}

export interface UpdateDropCopySubscriptionAction {
  type: typeof UPDATE_DROP_COPY_SUBSCRIPTION;
  payload: DropCopySubscriptionsPayload;
}

export interface RemoveDropCopySubscriptionAction {
  type: typeof REMOVE_DROP_COPY_SUBSCRIPTION;
  payload: DropCopySubscriptionsPayload;
}

const usersActions = {
  LOAD_USERS,
  LOAD_SELF_MATCH_PREVENTIONS,
  SET_STATE,
  ADD_SELF_MATCH_PREVENTIONS,
  REMOVE_SELF_MATCH_PREVENTIONS,
  ADD_TRADING_USER,
  UPDATE_TRADING_USER,
  ADD_DROP_COPY_USER,
  UPDATE_DROP_COPY_USER,
  UPDATE_DROP_COPY_USER_STATE,
  ADD_ADMIN_USER,
  UPDATE_ADMIN_USER,
  ADD_MARKET_DATA_USER,
  UPDATE_MARKET_DATA_USER,
  CHANGE_USER_PASSWORD,
  SHOW_ADD_NEW_SMP_MODAL,
  HIDE_ADD_NEW_SMP_MODAL,
  SHOW_REMOVE_SMP_MODAL,
  HIDE_REMOVE_SMP_MODAL,
  ADD_DROP_COPY_SUBSCRIPTION,
  UPDATE_DROP_COPY_SUBSCRIPTION,
  REMOVE_DROP_COPY_SUBSCRIPTION,
};

const dropCopySubscriptionsActions = {
  ADD_DROP_COPY_SUBSCRIPTION,
  REMOVE_DROP_COPY_SUBSCRIPTION,
  UPDATE_DROP_COPY_SUBSCRIPTION,
};

const actions = { ...usersActions, ...dropCopySubscriptionsActions };

export type UsersAction =
  | LoadUsersAction
  | LoadSelfMatchPreventionsAction
  | SetStateAction
  | AddTradingUserAction
  | UpdateTradingUserAction
  | AddDropCopyUserAction
  | UpdateDropCopyUserAction
  | UpdateDropCopyUserStateAction
  | AddAdminUserAction
  | UpdateAdminUserAction
  | AddMarketDataUserAction
  | UpdateMarketDataUserAction
  | ChangePasswordAction
  | AddNewSmpModalAction
  | AddNewSmpAction
  | RemoveSmpAction
  | RemoveSmpModalAction

  // Drop copy subscriptions.
  | AddDropCopySubscriptionAction
  | UpdateDropCopySubscriptionAction
  | RemoveDropCopySubscriptionAction;

export default actions;
