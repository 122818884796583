const updateRowData = (
  updatedRowData: any,
  index: number,
  cellValue: any,
  isValidCell: boolean,
  columnName: string,
) => {
  if (isValidCell) updatedRowData[index][columnName] = cellValue;
  else updatedRowData[index][columnName] = null;

  return updatedRowData;
};

export default updateRowData;
