import { ActiveConnection } from 'state/activeConnectionsPageState';

import actions, { ActiveConnectionsAction } from './actions';

export interface ActiveConnectionsState {
  loading: boolean;
  activeConnections: ActiveConnection[];
}

export const initialState: ActiveConnectionsState = {
  loading: false,
  activeConnections: [],
};

export default function activeConnectionsReducer(
  state: ActiveConnectionsState = initialState,
  action: ActiveConnectionsAction,
): ActiveConnectionsState {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
