import { OrderBookState } from './reducers';

export const SET_STATE = 'orderBook/SET_STATE';
export const LOAD_ORDER_BOOK = 'orderBook/LOAD_ORDER_BOOK';
export const LOAD_ORDER_BOOK_RECREATION = 'orderBook/LOAD_ORDER_BOOK_RECREATION';

interface SetOrderBookStateAction {
  type: typeof SET_STATE;
  payload: Partial<OrderBookState>;
}

export interface LoadOrderBookAction {
  type: typeof LOAD_ORDER_BOOK | typeof LOAD_ORDER_BOOK_RECREATION;
  payload?: object;
}

const actions = {
  SET_STATE,
  LOAD_ORDER_BOOK,
  LOAD_ORDER_BOOK_RECREATION,
};

export type OrderBookAction = SetOrderBookStateAction | LoadOrderBookAction;

export default actions;
