import { all, call, put, takeEvery } from 'redux-saga/effects';
import getTrades from 'services/getTrades';

import { setLoadingSpinner, unsetLoadingSpinner } from '../loadingSpinners';
import { setErrorMessage } from '../setMessage';
import actions, { SET_STATE } from './actions';

type Params = {
  type: typeof actions.LOAD_TRADES;
  payload: object;
};

export function* LOAD_TRADES({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(getTrades, payload);

  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        trades: response.data,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_TRADES, LOAD_TRADES)]);
}
