const BUY = 'BUY';
const SELL = 'SELL';

export const SIDES = { BUY, SELL };

export type Side = 'BUY' | 'SELL';

const PARTIALLY_FILLED = 'PARTIALLY_FILLED';
const CANCELED = 'CANCELED';
const FILLED = 'FILLED';
const SUBMITTED = 'SUBMITTED';
const ACTIVE = 'ACTIVE';
const REPLACED = 'REPLACED';
const STOP_TRIGGERED = 'STOP_TRIGGERED';
const REJECTED = 'REJECTED';
const WORKING = 'WORKING';
const DONE = 'DONE';

export const permissionsConstants = {
  subjects: {
    ORDER: 'ORDER',
    FILL: 'FILL',
    ORDER_FILL: 'ORDER_FILL',
    FIRM: 'FIRM',
    ACTIVE_CONNECTIONS: 'ACTIVE_CONNECTIONS',
    USER: 'USER',
    PRODUCT: 'PRODUCT',
    INSTRUMENT: 'INSTRUMENT',
    CLEARING: 'CLEARING',
    POSITION: 'POSITION',
    POSITION_LIMIT_SUBSCRIPTION_ALERT: 'POSITION_LIMIT_SUBSCRIPTION_ALERT',
    SETTLEMENT: 'SETTLEMENT',
    AUDIT: 'AUDIT',
    USER_MANAGEMENT: 'USER_MANAGEMENT',
    ORDER_BOOK: 'ORDER_BOOK',
    TRANSITIONS: 'TRANSITIONS',
    EVENT_VIEWER: 'EVENT_VIEWER',
    ORDER_BOOK_RECREATION: 'ORDER_BOOK_RECREATION',
    ADMIN_CLI: 'ADMIN_CLI',
    LMM: 'LMM',
  },
  actions: {
    READ: 'READ',
    WRITE: 'WRITE',
    RISK: 'RISK',
    SESSION_KILL: 'SESSION_KILL',
  },
};

export const permissionsOperations = [
  `${permissionsConstants.subjects.FILL}_${permissionsConstants.actions.READ}`,
  `${permissionsConstants.subjects.ORDER}_${permissionsConstants.actions.READ}`,
  `${permissionsConstants.subjects.ORDER}_${permissionsConstants.actions.WRITE}`,
  permissionsConstants.actions.RISK,
  permissionsConstants.actions.SESSION_KILL,
];

export const STATUS = {
  PARTIALLY_FILLED,
  CANCELED,
  FILLED,
  SUBMITTED,
  REPLACED,
  STOP_TRIGGERED,
  ACTIVE,
  REJECTED,
};
export type OrderEventType =
  | 'SUBMITTED'
  | 'REPLACED'
  | 'CANCELED'
  | 'FILLED'
  | 'STOP_TRIGGERED'
  | 'REJECTED';

export const OrderStatus = { WORKING, DONE };
export type OrderStatus = 'WORKING' | 'DONE';

export const FillStatus = { ACTIVE, CANCELED };
export type FillStatus = 'ACTIVE' | 'CANCELED';

export const DEFAULT_FLASH_MESSAGE_TIMEOUT = 5000;

export type SUCCESS = 'success';
export type INFO = 'info';
export type WARNING = 'warning';
export type ERROR = 'error';
export type UNDEFINED = undefined;

export type FlashMessageType = 'success' | 'info' | 'warning' | 'error' | UNDEFINED;

export const POSITIONS_TRANSFER_REASON = [
  'Account Error',
  'Transfer at the request of a customer',
  'Transfer for liquidation (offset)',
  'Transfer due to clearing firm change',
];

export const SESSION_EXPIRY = 'session.expiry';
export const TIMEOUT_BEFORE_EXPIRY = 1800000; // 30 mins
export const PRICE_STEP = 0.01;

export const DEFAULT_PAGE_ROWS = 100;

const EXCHANGE = 'EXCHANGE';
const FIRM = 'FIRM';
const FCM = 'FCM';
const TRADING_USER = 'TRADING_USER';
const EXCHANGE_READ = 'EXCHANGE_READ';

export const ADMIN_USER_ROLE = { EXCHANGE, FIRM, FCM, TRADING_USER, EXCHANGE_READ };
export type AdminUserRole =
  | typeof EXCHANGE
  | typeof FIRM
  | typeof FCM
  | typeof TRADING_USER
  | typeof EXCHANGE_READ;
