import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { setLoadingSpinner, unsetLoadingSpinner } from 'redux/loadingSpinners';
import { setErrorMessage } from 'redux/setMessage';
import actions, { SET_STATE } from 'redux/settlements/actions';
import { getSettlementData, overrideSettlementPrices } from 'services/settlementData';
import {
  END_OF_DAY,
  FINAL,
  INTRA_DAY,
  MarketStatEnum,
  Settlement,
  SettlementType,
} from 'state/settlementsPageState';
import stringComparator from 'utils/comparators/stringComparator';

import { RootState } from '../reducers';

type Params = {
  type: typeof actions.LOAD_SETTLEMENT_DATA;
  payload: object;
};

type EndOfDayParams = {
  type: typeof actions.LOAD_END_OF_DAY_SETTLEMENTS;
  payload: object;
};

type FinalSettlementsParams = {
  type: typeof actions.LOAD_FINAL_SETTLEMENTS;
  payload: object;
};

type IntradayParams = {
  type: typeof actions.LOAD_INTRADAY_SETTLEMENTS;
  payload: object;
};

const getFilteredSettlementPricesBySettlementType = (
  settlementsPrices: Settlement[],
  settlementType: SettlementType,
) => settlementsPrices.filter((s: Settlement) => s.settlementType === settlementType);
const getSortedSettlementPrices = (settlements: Settlement[]) =>
  settlements.sort((a: Settlement, b: Settlement) => stringComparator(a.symbol, b.symbol));

export function* loadSettlementSaga({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);
  const { response, error } = yield call(getSettlementData, payload);
  const sortedSettlementPrices = getSortedSettlementPrices(response.data);

  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        settlementPrices: sortedSettlementPrices,
        intradaySettlementPrices: getFilteredSettlementPricesBySettlementType(
          sortedSettlementPrices,
          INTRA_DAY,
        ),
        endOfDaySettlementPrices: getFilteredSettlementPricesBySettlementType(
          sortedSettlementPrices,
          END_OF_DAY,
        ),
        finalSettlementPrices: getFilteredSettlementPricesBySettlementType(
          sortedSettlementPrices,
          FINAL,
        ),
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* loadIntradaySettlementSaga({ payload }: IntradayParams) {
  yield setLoadingSpinner(SET_STATE);
  const { response, error } = yield call(getSettlementData, payload);
  const sortedSettlementPrices = getSortedSettlementPrices(response.data);

  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        intradaySettlementPrices: getFilteredSettlementPricesBySettlementType(
          sortedSettlementPrices,
          INTRA_DAY,
        ),
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* loadEndOfDaySettlementSaga({ payload }: EndOfDayParams) {
  yield setLoadingSpinner(SET_STATE);
  const { response, error } = yield call(getSettlementData, payload);
  const sortedSettlementPrices = getSortedSettlementPrices(response.data);

  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        endOfDaySettlementPrices: getFilteredSettlementPricesBySettlementType(
          sortedSettlementPrices,
          END_OF_DAY,
        ),
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* loadFinalSettlementSaga({ payload }: FinalSettlementsParams) {
  yield setLoadingSpinner(SET_STATE);
  const { response, error } = yield call(getSettlementData, payload);
  const sortedSettlementPrices = getSortedSettlementPrices(response.data);

  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        finalSettlementPrices: getFilteredSettlementPricesBySettlementType(
          sortedSettlementPrices,
          FINAL,
        ),
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* overrideSettlementPriceSagas({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);

  const { error } = yield call(overrideSettlementPrices, payload);

  if (!error) {
    const state: RootState = yield select();
    const settlements = state.settlements;

    const updatedSettlementPrices = settlements.settlementPrices.map((s: Settlement) => {
      return s.symbol === (payload as any).symbol && s.settlementType === (payload as any).type
        ? {
            ...s,
            settlementPrice: (payload as any).price,
            marketStatSource: MarketStatEnum.Manual,
          }
        : s;
    });

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        settlementPrices: updatedSettlementPrices,
        intradaySettlementPrices: getFilteredSettlementPricesBySettlementType(
          updatedSettlementPrices,
          INTRA_DAY,
        ),
        endOfDaySettlementPrices: getFilteredSettlementPricesBySettlementType(
          updatedSettlementPrices,
          END_OF_DAY,
        ),
        finalSettlementPrices: getFilteredSettlementPricesBySettlementType(
          updatedSettlementPrices,
          FINAL,
        ),
        showOverrideSettlementPricesModal: false,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_SETTLEMENT_DATA, loadSettlementSaga),
    takeEvery(actions.OVERRIDE_SETTLEMENT_PRICES, overrideSettlementPriceSagas),
    takeEvery(actions.LOAD_INTRADAY_SETTLEMENTS, loadIntradaySettlementSaga),
    takeEvery(actions.LOAD_END_OF_DAY_SETTLEMENTS, loadEndOfDaySettlementSaga),
    takeEvery(actions.LOAD_FINAL_SETTLEMENTS, loadFinalSettlementSaga),
  ]);
}
