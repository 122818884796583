import filter from 'lodash/filter';
import omit from 'lodash/omit';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { setLoadingSpinner, unsetLoadingSpinner } from 'redux/loadingSpinners';
import { setErrorMessage, setMessage } from 'redux/setMessage';
import {
  addPositionLimitFcmSubscription,
  addPositionLimitFirmSubscription,
  getPositionLimitSubscriptions,
  removePositionLimitFcmSubscriptions,
  removePositionLimitFirmSubscriptions,
} from 'services/positionLimitSubscriptions';
import { FirmRole } from 'state/sharedEnums';

import { RootState } from '../reducers';
import actions, { AddPositionLimitSubscriptionsAction, SET_STATE } from './actions';

export function* loadPositionLimitSubscriptionsSaga() {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(getPositionLimitSubscriptions);
  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: { ...response },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* addPositionLimitSubscriptionsSaga({
  payload,
}: AddPositionLimitSubscriptionsAction) {
  yield setLoadingSpinner(SET_STATE);

  const state: RootState = yield select();
  const { positionLimitFcmSubscriptions, positionLimitFirmSubscriptions } =
    state.positionLimitSubscriptions;
  const isFCMLevel = (payload as any).level === FirmRole.FCM;
  const service = isFCMLevel ? addPositionLimitFcmSubscription : addPositionLimitFirmSubscription;
  const updatedPayload = {
    ...omit(payload, ['firmId', 'fcmId']),
    ...(isFCMLevel && { fcmId: (payload as any).fcmId }),
    ...(!isFCMLevel && { firmId: (payload as any).firmId }),
  };
  const { response, error } = yield call(service, updatedPayload);
  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        showCreateSubscriptionModal: false,
        ...(isFCMLevel && {
          positionLimitFcmSubscriptions: [...positionLimitFcmSubscriptions, response.data],
        }),
        ...(!isFCMLevel && {
          positionLimitFirmSubscriptions: [...positionLimitFirmSubscriptions, response.data],
        }),
      },
    });
    yield setMessage(response, 'Position Limit Subscription was successfully added', '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* removePositionLimitSubscriptionsSaga() {
  const state: RootState = yield select();
  const { selectedRow, positionLimitFirmSubscriptions, positionLimitFcmSubscriptions } =
    state.positionLimitSubscriptions;

  const isFCMLevel = !!selectedRow.fcmId;
  const service = isFCMLevel
    ? removePositionLimitFcmSubscriptions
    : removePositionLimitFirmSubscriptions;

  // @ts-ignore
  const { response, error } = yield call(service, selectedRow);
  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        showConfirmRemoveModal: false,
        ...(isFCMLevel && {
          positionLimitFcmSubscriptions: filter(
            positionLimitFcmSubscriptions,
            (record: any) => record.id !== selectedRow.id,
          ),
        }),
        ...(!isFCMLevel && {
          positionLimitFirmSubscriptions: filter(
            positionLimitFirmSubscriptions,
            (record: any) => record.id !== selectedRow.id,
          ),
        }),
      },
    });
    yield setMessage(response, 'Position Limit Subscription was removed', '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_POSITION_LIMIT_SUBSCRIPTIONS, loadPositionLimitSubscriptionsSaga),
    takeEvery(actions.ADD_POSITION_LIMIT_SUBSCRIPTIONS, addPositionLimitSubscriptionsSaga),
    takeEvery(actions.REMOVE_POSITION_LIMIT_SUBSCRIPTIONS, removePositionLimitSubscriptionsSaga),
  ]);
}
