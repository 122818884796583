import apiClient from 'services/apiClient';

export async function loadScheduledProductsTradingStateTransitionsList(params: object) {
  return apiClient
    .get(`/scheduled-product-trading-state-transitions`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function cancelScheduledProductsTransitionsState(transitionId: string) {
  return apiClient
    .post(`/cancel-product-trading-state-transition?transitionId=${transitionId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function scheduleProductsTransitionsState(params: object) {
  return apiClient
    .post(`/schedule-product-trading-state-transition`, params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
