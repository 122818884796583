export const toTitleCase = (str: string | undefined) => {
  if (str === undefined || str === '') {
    return '';
  }
  return str
    .split(/(?=[A-Z])/)
    .map((c) => c.charAt(0).toUpperCase() + c.slice(1))
    .join(' ');
};

export const getSearchFieldErrorMessage = (fieldName: string | undefined) => {
  if (!fieldName) {
    return '';
  }
  return `${toTitleCase(fieldName)} contains invalid characters`;
};
