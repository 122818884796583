import actions, { FirmsAction, SearchParam } from 'redux/firms/actions';
import { FirmPosition, FirmPositions, FirmProduct, FirmProductGroup } from 'state/firmPageState';

export interface FirmsState {
  loading: boolean;
  firmPositions: FirmPositions;
  firms: FirmPosition[];
  fcmList: FirmPosition[];
  showAddNewFirmModal: boolean;
  showAllowToTradeModal: boolean;
  searchText: string;
  firmProducts: (FirmProductGroup | FirmProduct)[];
  selectedProds: (FirmProductGroup | FirmProduct)[];
}

export const initialState: FirmsState = {
  loading: false,
  firmPositions: {},
  firms: [],
  fcmList: [],
  showAddNewFirmModal: false,
  showAllowToTradeModal: false,
  searchText: '',
  firmProducts: [],
  selectedProds: [],
};

export default function firmsReducer(
  state: FirmsState = initialState,
  action: FirmsAction,
): FirmsState {
  switch (action.type) {
    case actions.SHOW_ADD_NEW_FIRM_MODAL:
      return { ...state, showAddNewFirmModal: true };
    case actions.HIDE_ADD_NEW_FIRM_MODAL:
      return { ...state, showAddNewFirmModal: false };
    case actions.SHOW_MODAL:
      return { ...state, showAllowToTradeModal: true, ...action.payload };
    case actions.HIDE_MODAL:
      return { ...state, showAllowToTradeModal: false, ...action.payload };
    case actions.SET_SEARCH_TEXT:
      return { ...state, searchText: (action.payload as SearchParam).value };
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
