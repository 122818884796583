export const SET_STATE = 'events/SET_STATE';
export const LOAD_EVENTS = 'events/LOAD_EVENTS';

interface SetEventsAction {
  type: typeof SET_STATE;
  payload: unknown;
}

interface LoadEventsAction {
  type: typeof LOAD_EVENTS;
  payload?: object;
}

const actions = {
  SET_STATE,
  LOAD_EVENTS,
};

export type EventsAction = SetEventsAction | LoadEventsAction;

export default actions;
