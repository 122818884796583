import '@ant-design/compatible/assets/index.css';

import './index.scss';

import { SelectOption, Select as SingleSelect } from '@cbhq/cds-web/controls';
import { Checkbox } from '@cbhq/cds-web/controls/Checkbox';
import { TextInput } from '@cbhq/cds-web/controls/TextInput';
import { Box } from '@cbhq/cds-web/layout/Box';
import { TextBody } from '@cbhq/cds-web/typography';
import { DatePicker, Form, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import ButtonList from 'components/common/ButtonList';
import defaultButtons from 'components/common/formWrapper/defaultButtons';
import getFormValidationFunction from 'components/common/formWrapper/getFormValidationFunction';
import getPopupContainer from 'components/common/formWrapper/getPopupContainer';
import {
  CheckboxOptions,
  DataType,
  DateOptions,
  DynamicFieldState,
  FormField,
  LabelCaptionOptions,
  NumberOptions,
  SelectOptions,
  TextOptions,
} from 'components/common/formWrapper/types';
import { Moment } from 'moment';
import React, { ChangeEvent, Ref, forwardRef, memo, useState } from 'react';
import {
  datePickerFormat,
  fullDateFullTimeFormat,
  hoursMinsFormat,
  rangePickerFormat,
} from 'utils/dateFormatConstants';
import { getSearchFieldErrorMessage, toTitleCase } from 'utils/getSearchFieldErrorMessage';

import Label from '../labels/Label';
import LabelBody from '../labels/LabelBody';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface FormProps {
  formName?: string;
  ref?: React.Ref<any> | null;
  formFields: FormField[];
  onFinish: (values: any) => void;
  onValidateError?: Function;
  containerClassName?: string;
  formClassName?: string;
  formInputClassName?: string;
  layout?: 'horizontal' | 'inline' | 'vertical' | undefined;
  buttons?: any[];
  onValuesChange?: Function;
  error?: string;
  onResetFormState?: Function;
}

export const FormWrapper = memo(
  forwardRef(
    (
      {
        onFinish,
        formFields,
        onValidateError,
        onValuesChange,
        buttons,
        containerClassName,
        formClassName,
        layout,
        formName,
        error,
        onResetFormState,
        formInputClassName,
      }: FormProps,
      ref: Ref<FormInstance<any>>,
    ) => {
      const [dynamicFieldState, setDynamicFieldState] = useState<DynamicFieldState>({});
      const [searchButtonDisabled, setSearchButtonDisabled] = useState<boolean>(false);
      const formRef = ref || React.createRef();

      const handleChange = (
        fieldName: string,
        event: ChangeEvent<HTMLInputElement> | Moment | string,
      ) => {
        setDynamicFieldState((prevState) => ({
          ...prevState,
          [fieldName]: event || (event as unknown as ChangeEvent<HTMLInputElement>).target.value,
        }));
      };
      const handleDateInputChange = (
        date: Moment | null,
        fieldName: string,
        dateOptions: DateOptions | undefined,
      ) => {
        if (date) handleChange(fieldName, date);
        if (dateOptions && dateOptions?.onChangeDate) {
          return dateOptions?.onChangeDate(date);
        }
      };

      const handleTextInputChange = (
        event: ChangeEvent<HTMLInputElement>,
        fieldName: string,
        textOptions: TextOptions | undefined,
      ) => {
        handleChange(fieldName, event);
        if (textOptions?.onChangeInput) {
          return textOptions?.onChangeInput();
        }
      };

      const handleNumberInputChange = (
        event: ChangeEvent<HTMLInputElement>,
        fieldName: string,
        numberOptions: NumberOptions | undefined,
      ) => {
        handleChange(fieldName, event);
        if (numberOptions?.onChangeInput) {
          return numberOptions?.onChangeInput();
        }
      };

      const handleSelectChange = (
        selection: string,
        fieldName: string,
        selectOptions: SelectOptions | undefined,
      ) => {
        handleChange(fieldName, selection);
        if (selectOptions?.onChangeSelection) {
          return selectOptions?.onChangeSelection();
        }
      };

      const getFormInput = (
        // TODO: convert to named arguments.
        name: string,
        dataType: string,
        placeholder?: string,
        defaultValue: any[] = [],
        label?: string,
        format?: string,
        testID?: string,
        disabled?: boolean,
        textOptions?: TextOptions,
        dateOptions?: DateOptions,
        numberOptions?: NumberOptions,
        selectOptions?: SelectOptions,
        checkboxOptions?: CheckboxOptions,
        labelCaptionOptions?: LabelCaptionOptions,
      ) => {
        const selectPlaceholder = `Select a ${placeholder || label}`;
        const numPlaceholder = `Enter ${placeholder || label}`;

        switch (dataType) {
          case DataType.DATE_RANGE:
            return (
              <RangePicker
                showTime={{ format: dateOptions ? dateOptions.format : hoursMinsFormat }}
                format={rangePickerFormat}
                disabledDate={dateOptions?.disabledDate}
                size='large'
                placeholder={[rangePickerFormat, rangePickerFormat]}
                style={{ width: '100%' }}
              />
            );

          case DataType.DATE:
            return (
              <DatePicker
                style={{ width: '100%' }}
                format={datePickerFormat}
                size='large'
                placeholder={datePickerFormat}
                disabledDate={dateOptions?.disabledDate}
                onChange={(date: Moment | null, dateString: string) =>
                  handleDateInputChange(date, name, dateOptions)
                }
                getPopupContainer={getPopupContainer}
              />
            );

          case DataType.DATE_AND_TIME:
            return (
              <DatePicker
                format={fullDateFullTimeFormat}
                allowClear
                size='large'
                placeholder={fullDateFullTimeFormat}
                showTime={{ format: fullDateFullTimeFormat }}
                disabledDate={dateOptions?.disabledDate}
                showNow={false}
                getPopupContainer={getPopupContainer}
                style={{ width: '100%' }}
              />
            );

          case DataType.NUMBER:
            return (
              <TextInput
                placeholder={numPlaceholder}
                disabled={disabled}
                type='number'
                step={numberOptions?.step}
                accessibilityHint='number-input'
                testID={testID}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleNumberInputChange(event, name, numberOptions)
                }
              />
            );

          case DataType.SELECT:
            return (
              <Select
                showSearch
                allowClear
                mode={selectOptions?.multiple ? 'multiple' : undefined}
                placeholder={selectPlaceholder}
                optionFilterProp='children'
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                size='large'
                disabled={disabled}
                getPopupContainer={getPopupContainer}
                onChange={(selection: string) => handleSelectChange(selection, name, selectOptions)}
              >
                {(selectOptions?.options || []).map(([value, text]) => (
                  <Option key={`${value}${text}`} value={value}>
                    {(text || '').replace('_', ' ')}
                  </Option>
                ))}
              </Select>
            );

          case DataType.SELECT_WILDCARD:
            return (
              <Select
                className={`select_${name}`}
                showSearch
                allowClear
                mode='tags'
                placeholder={selectPlaceholder}
                optionFilterProp='children'
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                size='large'
                disabled={disabled}
                getPopupContainer={getPopupContainer}
              >
                {(selectOptions?.options || []).map(([value, text]) => (
                  <Option key={`${value}${text}`} value={value}>
                    {(text || '').replace('_', ' ')}
                  </Option>
                ))}
              </Select>
            );

          case DataType.SINGLE_SELECT:
            return (
              <SingleSelect
                placeholder={selectPlaceholder}
                onChange={(selection: string) => handleSelectChange(selection, name, selectOptions)}
                disabled={disabled}
              >
                {(selectOptions?.options || []).map((option: any) => (
                  <SelectOption value={option[1]} key={option[1]} title={option[1]} />
                ))}
              </SingleSelect>
            );

          case DataType.MULTI_WILDCARD:
            return (
              <Select
                className={`select_${name}`}
                key='1'
                showSearch
                allowClear
                mode='tags'
                tokenSeparators={[',']}
                placeholder={numPlaceholder}
                size='large'
                getPopupContainer={getPopupContainer}
              />
            );

          case DataType.MULTI_WILDCARD_COLON:
            return (
              <Select
                className={`select_${name}`}
                key='2'
                showSearch
                allowClear
                mode='tags'
                tokenSeparators={[',']}
                placeholder={numPlaceholder}
                size='large'
                getPopupContainer={getPopupContainer}
              />
            );

          case DataType.NULL:
            return null;

          case DataType.CHECKBOX:
            return (
              <Box as='label' width='20px' height='20px'>
                <Checkbox
                  checked={checkboxOptions?.checked}
                  onChange={checkboxOptions?.onChangeChecked}
                  testID='checkbox'
                  disabled={disabled}
                />
              </Box>
            );

          case DataType.DATE_TIME_NOW:
            return (
              <DatePicker
                format={fullDateFullTimeFormat}
                allowClear
                size='large'
                placeholder='NOW'
                showTime={{ format: fullDateFullTimeFormat }}
                disabledDate={dateOptions?.disabledDate}
                showNow={false}
                style={{ width: '100%' }}
                getPopupContainer={getPopupContainer}
              />
            );

          case DataType.LABEL_CAPTION:
            return (
              <LabelBody
                outerText={labelCaptionOptions?.outerText}
                innerText={labelCaptionOptions?.innerText}
              />
            );

          default:
            return (
              <TextInput
                placeholder={numPlaceholder}
                disabled={disabled}
                type='text'
                accessibilityHint='text-input'
                testID={testID}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleTextInputChange(event, name, textOptions)
                }
              />
            );
        }
      };

      const handleReset = () => {
        (formRef as React.RefObject<any>).current.resetFields();
        setSearchButtonDisabled(false);

        if (onResetFormState) {
          onResetFormState();
        }
      };

      const convertProductNameArrToStr = (valuesObj: any) => {
        if (Array.isArray(valuesObj?.productName))
          valuesObj.productName = valuesObj.productName.toString();
      };

      const initialValues: Object = {};
      formFields.forEach((field) => {
        if (field.defaultValue) {
          initialValues[field.name] = field.defaultValue;
        }
      });

      return (
        <Form
          ref={ref || formRef}
          name={formName || 'searchForm'}
          layout={layout}
          className={formClassName || 'antAdvancedSearchForm'}
          // We need more space here in order to RangePicker display all its digits
          onFinish={(values) => {
            convertProductNameArrToStr(values);
            onFinish(values);
          }}
          onValuesChange={(values) => {
            if (onValidateError) {
              onValidateError(values);
            } else if (onValuesChange) {
              onValuesChange(values);
            }
          }}
          initialValues={initialValues}
        >
          <div className={containerClassName || 'container'}>
            {formFields.map((field: FormField) => {
              const {
                dependencyCondition,
                dependency,
                name,
                placeholder,
                label,
                disabled,
                testID,
                dataType,
                required,
                conditionalRequired,
                validationFn,
                defaultValue,
                format,
                textOptions,
                dateOptions,
                selectOptions,
                numberOptions,
                checkboxOptions,
                labelCaptionOptions,
                rules,
              } = field;
              // @ts-ignore

              return (
                <>
                  {(!dependency ||
                    (dependency &&
                      dependencyCondition &&
                      dependencyCondition(dynamicFieldState[dependency]))) && (
                      <Form.Item
                        key={name}
                        name={name}
                        label={<Label text={label} />}
                        valuePropName={dataType === DataType.CHECKBOX ? 'checked' : 'value'}
                        hasFeedback
                        rules={[
                        {
                          required:
                            required ||
                            (conditionalRequired &&
                              dependency &&
                              conditionalRequired(dynamicFieldState[dependency])),
                          message: `${toTitleCase(name)} is required`,
                        },
                        () => ({
                          validator(rule, value) {
                            if (getFormValidationFunction(dataType, validationFn)(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(getSearchFieldErrorMessage((rule as any).field)),
                            );
                          },
                        }),
                      ]}
                        labelAlign='right'
                        className={formInputClassName || 'wrap'}
                      >
                        {getFormInput(
                        name,
                        dataType,
                        placeholder,
                        defaultValue,
                        label,
                        format,
                        testID,
                        disabled,
                        textOptions,
                        dateOptions,
                        numberOptions,
                        selectOptions,
                        checkboxOptions,
                        labelCaptionOptions,
                      )}
                      </Form.Item>
                  )}
                </>
              );
            })}
          </div>
          {error !== '' && (
            <TextBody as='p' color='negative' testID='error-text'>
              {error}
            </TextBody>
          )}
          <ButtonList
            buttons={buttons || defaultButtons({ searchButtonDisabled, onReset: handleReset })}
            layout='vertical'
          />
        </Form>
      );
    },
  ),
);

export default FormWrapper;
