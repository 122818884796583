import { all, call, put, takeEvery } from 'redux-saga/effects';
import { setLoadingSpinner, unsetLoadingSpinner } from 'redux/loadingSpinners';
import { setErrorMessage } from 'redux/setMessage';
import getActiveConnections from 'services/getActiveConnections';

import actions, { SET_STATE } from './actions';

export function* loadActiveConnections() {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(getActiveConnections);

  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        activeConnections: response.data,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_ACTIVE_CONNECTIONS, loadActiveConnections)]);
}
