import { Icon } from '@cbhq/cds-web/icons';
import { HStack } from '@cbhq/cds-web/layout/HStack';
import { Spacer } from '@cbhq/cds-web/layout/Spacer';
import { VStack } from '@cbhq/cds-web/layout/VStack';
import { TextHeadline } from '@cbhq/cds-web/typography/TextHeadline';
import FormWrapper from 'components/common/formWrapper';
import { DataType, FormField } from 'components/common/formWrapper/types';
import isEqual from 'lodash/isEqual';
import moment, { Moment } from 'moment';
import InstrumentInformationList from 'pages/fairx/products/InstrumentInformationList';
import React, { useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { ADD_HOLIDAY } from 'redux/holidays/actions';
import { RootState } from 'redux/reducers';
import { Holiday } from 'state/holidayState';
import { Instrument, ProductInstrumentInfo } from 'state/productPageState';
import { SHORT_DATE_FORMAT } from 'utils/renderHelper/getFormattedTime';
import { isInputValid } from 'utils/validators';

interface AddHolidayFormProps {
  onSubmit: Function;
  onCancel: Function;
}

const mapState = (state: RootState) => ({
  holidays: (state as any).holidays,
  products: state.products,
});

const mapDispatch = {
  onAddHoliday: (values: object) => ({
    type: ADD_HOLIDAY,
    payload: values,
  }),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & AddHolidayFormProps;

const isUniqueHolidayDate = (holidays: Holiday[], value: Moment) =>
  !holidays.find((h: Holiday) =>
    isEqual(h.date, { year: value.year(), month: value.month() + 1, day: value.date() }),
  );

const isUniqueHoliday = (holidays: Holiday[], value: Moment, country: string) =>
  !holidays.find(
    (h: Holiday) =>
      isEqual(h.date, { year: value.year(), month: value.month() + 1, day: value.date() }) &&
      h.country === country,
  );

export const AddHolidayForm = ({ onAddHoliday, onCancel, products, holidays }: Props) => {
  const [showInstrumentInformation, setShowInstrumentInformation] = useState<boolean>(false);
  const [holidayDate, setHolidayDate] = useState<Moment>(moment());
  const [error, setError] = useState<string>('');

  const memoizedAllInstruments = useMemo(() => {
    return products.allProducts.map((prod: ProductInstrumentInfo) => prod.instrumentList).flat();
  }, [products]);

  const allInstruments = memoizedAllInstruments.filter((inst: Instrument) => {
    if (!holidayDate) {
      return true;
    }
    const expiryDate = moment(inst.lastTradingSessionDate / (1000 * 1000));
    return holidayDate.isSame(expiryDate, 'day');
  });

  const disabledDate = (current: Moment) => {
    const weekday = current.format('dddd'); // Monday ... Sunday
    const isWeekend = weekday === 'Sunday' || weekday === 'Saturday';

    // Can not select days from the past or an already existing holiday or a day on the weekend
    return (
      (current && current < moment().endOf('day')) ||
      isWeekend ||
      !isUniqueHolidayDate(holidays.holidays, current)
    );
  };

  const onChangeDate = (momentDate: Moment) => {
    setHolidayDate(momentDate);
    setShowInstrumentInformation(true);
  };

  const buttons = [
    {
      key: 0,
      label: 'Cancel',
      execute: onCancel,
      visible: true,
      disabled: false,
      variant: 'secondary',
      testID: 'cancel-add-holiday',
    },
    {
      key: 1,
      label: 'Add Holiday',
      visible: true,
      disabled: error !== '',
      variant: 'primary',
      testID: 'add-holiday',
      type: 'submit',
    },
  ];

  const formFields: FormField[] = [
    {
      name: 'name',
      label: 'Holiday Name',
      dataType: DataType.STRING,
      validationFn: isInputValid,
      required: true,
    },
    {
      name: 'country',
      label: 'Country',
      dataType: DataType.SINGLE_SELECT,
      validationFn: isInputValid,
      required: true,
      selectOptions: {
        multiple: false,
        options: [
          ['US', 'US'],
          ['UK', 'UK'],
        ],
      },
    },
    {
      name: 'date',
      label: 'Date',
      dataType: DataType.DATE,
      required: true,
      dateOptions: { onChangeDate, disabledDate },
    },
  ];

  const onAdd = (values: any) => {
    if (!isUniqueHoliday(holidays.holidays, values.date, values.country)) {
      const errorMsg = `A holiday already exists for '${values.country}' on ${values.date.format(
        SHORT_DATE_FORMAT,
      )}`;
      setError(errorMsg);
      return;
    }
    if (!showInstrumentInformation) {
      setShowInstrumentInformation(true);
    }
    if (onAddHoliday && showInstrumentInformation) {
      onAddHoliday({
        ...values,
        date: {
          year: values.date.year(),
          month: values.date.month() + 1,
          day: values.date.date(),
        },
      });
      onCancel();
    }
  };

  return (
    <>
      <FormWrapper
        formFields={formFields}
        onFinish={(values: any) => onAdd(values)}
        layout="vertical"
        buttons={buttons}
        containerClassName="form-container"
        formInputClassName="form-no-wrap"
        error={error}
      />
      {showInstrumentInformation && (
        <VStack width="100%" height="100%">
          <HStack width="100%" height="100%">
            <Icon size="m" name="warning" testID="warning-instruments-expiry" />
            <Spacer horizontal={2} />
            <TextHeadline as="p">Instruments expiring on selected day</TextHeadline>
          </HStack>
          <Spacer vertical={2} />
          <InstrumentInformationList list={allInstruments} showDataInTable />
        </VStack>
      )}
    </>
  );
};

export default connector(AddHolidayForm);
