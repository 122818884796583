import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { setLoadingSpinner, unsetLoadingSpinner } from 'redux/loadingSpinners';
import { setErrorMessage, setMessage } from 'redux/setMessage';
import actions, {
  GrantOperationAction,
  GrantResourceAction,
  RevokeOperationAction,
  RevokeResourceAction,
  SET_STATE,
} from 'redux/userManagement/actions';
import {
  getPermissions,
  grantOperation,
  grantResource,
  revokeOperation,
  revokeResource,
} from 'services/permissions';

import { RootState } from '../reducers';

export function* loadPermissions() {
  yield setLoadingSpinner(SET_STATE);
  const { response, error } = yield call(getPermissions);
  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        permissions: response.data,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* grantResourceSaga({ payload }: GrantResourceAction) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(grantResource, payload);

  const state: RootState = yield select();
  const { adminUserId } = payload as any;
  const oldPermissions = state.userManagement.permissions[adminUserId];

  const updatedResources = [
    ...((oldPermissions || {}).resources || []),
    {
      type: (payload as any).resourceType,
      id: (payload as any).resourceId,
    },
  ];

  const updatedPermissions = {
    ...oldPermissions,
    [adminUserId]: {
      ...oldPermissions,
      resources: updatedResources,
    },
  };

  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        permissions: updatedPermissions,
      },
    });
    yield setMessage(response, 'Permissions were set', '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* revokeResourceSaga({ payload }: RevokeResourceAction) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(revokeResource, payload);
  const state: RootState = yield select();
  const { adminUserId } = payload as any;
  const oldPermissions = state.userManagement.permissions[adminUserId];

  const updatedResources = ((oldPermissions || {}).resources || []).map((res: any) => {
    if ((payload as any).resourceId !== res.id) {
      return res;
    }
    return null;
  });

  const updatedPermissions = {
    ...oldPermissions,
    [adminUserId]: {
      ...oldPermissions,
      resources: updatedResources.filter(Boolean),
    },
  };

  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        permissions: updatedPermissions,
      },
    });
    yield setMessage(response, 'Permissions were revoked', '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* grantOperationSaga({ payload }: GrantOperationAction) {
  yield setLoadingSpinner(SET_STATE);
  const { response, error } = yield call(grantOperation, payload);

  const state: RootState = yield select();
  const { adminUserId } = payload as any;
  const oldPermissions = state.userManagement.permissions[adminUserId];

  const updatedOperations = [
    ...((oldPermissions || {}).adminOperations || []),
    (payload as any).adminOperation,
  ];

  const updatedPermissions = {
    ...state.userManagement.permissions,
    [adminUserId]: {
      ...oldPermissions,
      adminOperations: updatedOperations,
    },
  };

  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        permissions: updatedPermissions,
      },
    });
    yield setMessage(response, 'Operations were set', '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* revokeOperationSaga({ payload }: RevokeOperationAction) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(revokeOperation, payload);
  const state: RootState = yield select();
  const { adminUserId } = payload as any;
  const oldPermissions = state.userManagement.permissions[adminUserId];
  const updatedOperations = ((oldPermissions || {}).adminOperations || []).map((op: any) => {
    if ((payload as any).adminOperation !== op) {
      return op;
    }
    return null;
  });

  const updatedPermissions = {
    ...state.userManagement.permissions,
    [adminUserId]: {
      ...oldPermissions,
      adminOperations: updatedOperations.filter(Boolean),
    },
  };

  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        permissions: updatedPermissions,
      },
    });
    yield setMessage(response, 'Operations were revoked', '', 'info');
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_PERMISSIONS, loadPermissions),
    takeEvery(actions.GRANT_RESOURCE, grantResourceSaga),
    takeEvery(actions.REVOKE_RESOURCE, revokeResourceSaga),
    takeEvery(actions.GRANT_OPERATION, grantOperationSaga),
    takeEvery(actions.REVOKE_OPERATION, revokeOperationSaga),
  ]);
}
