import { TextHeadline } from '@cbhq/cds-web/typography/TextHeadline';
import React from 'react';

type Props = {
  text: string | undefined;
};

const Label = ({ text }: Props) => {
  return <TextHeadline as="label">{text}</TextHeadline>;
};

export default Label;
