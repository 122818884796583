import { ADMIN_USER_ROLE, permissionsConstants } from 'fairxConstants';
import { UserPermissions } from 'redux/userPermissions/reducers';

const { actions: actionsConstants } = permissionsConstants;

const can = (
  subject: string = '',
  permittedActions: string[],
  permissions: UserPermissions = { adminOperations: [], adminUserRole: 'TRADING_USER' },
) => {
  const { adminOperations, adminUserRole } = permissions;
  if (adminUserRole === ADMIN_USER_ROLE.EXCHANGE) return true;
  if (
    adminUserRole === ADMIN_USER_ROLE.EXCHANGE_READ &&
    permittedActions.includes(actionsConstants.READ)
  ) {
    return true;
  }

  const permissionsNeeded: string[] = permittedActions.map((action: string) => {
    switch (action) {
      case actionsConstants.RISK:
      case actionsConstants.SESSION_KILL:
        return action;
      default:
        return `${subject}_${action}`;
    }
  });

  let found: boolean = false;
  permissionsNeeded.forEach((permission) => {
    if (adminOperations.includes(permission)) {
      found = true;
    }
  });

  return found;
};

export default can;
