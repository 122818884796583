import { LeadMarketMakerUser } from 'state/lmmPageState';

import actions, { LMMAction } from './actions';

export interface LMMState {
  loading: boolean;
  lmmuList: LeadMarketMakerUser[];
}

export const initialState: LMMState = {
  loading: false,
  lmmuList: [],
};

export default function lmmReducers(state: LMMState = initialState, action: LMMAction): LMMState {
  switch (action.type) {
    case actions.SET_STATE: {
      if (typeof action.payload === 'string') return { ...state };
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}
