import { useToggler } from '@cbhq/cds-web';
import { IconButton } from '@cbhq/cds-web/buttons/IconButton';
import { LogoMark } from '@cbhq/cds-web/icons';
import { Icon } from '@cbhq/cds-web/icons/Icon';
import { Box } from '@cbhq/cds-web/layout/Box';
import { HStack } from '@cbhq/cds-web/layout/HStack';
import { VStack } from '@cbhq/cds-web/layout/VStack';
import { Sidebar } from '@cbhq/cds-web/navigation';
import { Pressable } from '@cbhq/cds-web/system/Pressable';
import { permissionsConstants } from 'fairxConstants';
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SET_STATE } from 'redux/application/actions';
import { RootState } from 'redux/reducers';
import { MenuItem } from 'services/menu';
import createPageName from 'utils/createPageName';
import can from 'utils/permissionsHandler';

import style from './index.scss';
import SidebarItemWrapper from './SidebarItemWrapper';

const { actions } = permissionsConstants;

const mapState = ({ menu, userPermissions, router }: RootState) => ({
  menuData: menu.menuData,
  userPermissions,
  pathname: router.location.pathname,
});

const mapDispatch = {
  onUpdateHeader: (values: object) => ({ type: SET_STATE, payload: values }),
};

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const SidebarWrapper = ({
  menuData: menuDataAll = [],
  userPermissions,
  onUpdateHeader,
  pathname,
}: Props) => {
  useEffect(() => {
    const currentActiveIndex = getPermittedMenuItems().findIndex(
      (item: MenuItem) => item.url === pathname,
    );
    const pageName = createPageName(pathname);
    setActiveIndex(currentActiveIndex);
    onUpdateHeader({ pageName });
  });

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [collapsed, { toggle }] = useToggler();

  const getPermittedMenuItems = () => {
    return menuDataAll.filter((option: MenuItem) =>
      can(
        option.resource || '',
        option.permittedActions && option.permittedActions.length > 0
          ? option.permittedActions
          : [actions.READ],
        userPermissions,
      ),
    );
  };

  const handlePress = (index: number, url: string) => {
    const pageName = createPageName(url);

    setActiveIndex(index);
    onUpdateHeader({ pageName });
  };

  const generateSidebarItem = () => {
    const menuData = getPermittedMenuItems();

    const menuItem = (item: MenuItem, index: number) => {
      const { key, title, imgSrcPath, url, testID } = item;

      // Wrote our own version of the SidebarItem to get rid of the mandatory icon restriction.
      // CDS hasn't provided a rich set of icons yet to take care of our needs.
      // TODO: Remove imgSrcPath and use the "icon" prop provided by SidebarItem.
      return (
        <Link to={url} key={key}>
          <SidebarItemWrapper
            title={title}
            tooltipContent={title}
            imgSrcPath={imgSrcPath}
            testID={testID}
            key={`sidebar-item--${item.title}`}
            active={index === activeIndex}
            onPress={() => handlePress(index, url)}
          />
        </Link>
      );
    };

    return menuData.map((item: MenuItem, index: number) => menuItem(item, index));
  };

  const items = generateSidebarItem();

  const collapseBtn = (
    <IconButton
      color="secondaryForeground"
      name={collapsed ? 'forwardArrow' : 'backArrow'}
      onPress={toggle}
    />
  );

  const CDLogoEl = (
    <Link to="/fairx/orders">
      {!collapsed ? (
        <HStack alignItems="center" spacingEnd={5} gap={1}>
          <img
            className={style.cdLogo}
            src="/resources/images/coinbase-derivatives-logo.svg"
            alt="Coinbase Derivatives Logo"
          />
          {collapseBtn}
        </HStack>
      ) : (
        <VStack alignItems="center">
          <Box spacingBottom={2}>
            <LogoMark size={32} />
          </Box>
          {collapseBtn}
        </VStack>
      )}
    </Link>
  );

  return (
    <Sidebar collapsed={collapsed} logo={CDLogoEl} autoCollapse testID="sidebar">
      {items}
    </Sidebar>
  );
};

export default connector(SidebarWrapper);
