import removeEmpty from 'utils/removeEmpty';

import apiClient from './apiClient';

export default async function getMarketOrderBook(params: any) {
  const reqParams = removeEmpty(params);

  return apiClient
    .post('market-data-book-recreation', reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
