import { PortalProvider } from '@cbhq/cds-web/overlays/PortalProvider';
import { DevicePreferencesProvider } from '@cbhq/cds-web/system';
import { ThemeProvider } from '@cbhq/cds-web/system/ThemeProvider';
import { ConnectedRouter } from 'connected-react-router';
import Layout from 'layouts';
import { PAGES } from 'pageConstants';
import NotFoundPage from 'pages/system/404';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import Switch from 'react-router-transition-switch';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { signOut } from 'services/auth';
import can from 'utils/permissionsHandler';

import FlashMessage from './FlashMessage';
import Callback from './pages/system/callback';
import { routes, secureRoutes } from './routes';

const mapStateToProps = ({ settings, user, userPermissions }) => ({
  settings,
  user,
  userPermissions,
});

const connector = connect(mapStateToProps);

class Router extends React.Component {
  onLogout = () => {
    const {
      user: { auth0 },
    } = this.props;
    if (auth0 !== undefined) signOut(auth0);
  };

  render() {
    const {
      history,
      settings: { routerAnimation },
      user,
      userPermissions,
    } = this.props;

    const { isExchange, adminOperations, failed } = userPermissions;
    if (!isExchange && adminOperations.length === 0 && user.authenticated) {
      if (!failed) return null;
      return (
        <FlashMessage
          flashMessage={
            <div className="text-center">
              <span>You are not authorized to access this page.</span>
              <a href="#" onClick={this.onLogout}>
                <i className="fe fe-log-out ml-3" />
                Logout
              </a>
            </div>
          }
          onDismiss={() => {}}
          type="error"
          shouldDismissAutomatically={false}
        />
      );
    }

    const allowedPages = secureRoutes.filter(({ subject, permittedActions }) =>
      can(subject, permittedActions, userPermissions),
    );

    const landingPage = allowedPages ? ((allowedPages || [])[0] || {}).path : '';

    return (
      <DevicePreferencesProvider>
        <ThemeProvider spectrum="light">
          <PortalProvider>
            <ConnectedRouter history={history}>
              <Layout>
                <Switch
                  render={(props) => {
                    const {
                      children,
                      location: { pathname },
                    } = props;
                    return (
                      <SwitchTransition>
                        <CSSTransition
                          key={pathname}
                          classNames={routerAnimation}
                          timeout={routerAnimation === 'none' ? 0 : 300}
                        >
                          {children}
                        </CSSTransition>
                      </SwitchTransition>
                    );
                  }}
                >
                  <Route
                    exact
                    path="/"
                    render={() => (
                      <Redirect to={user.authenticated ? landingPage : PAGES.LOGIN_PAGE} />
                    )}
                  />
                  {routes.map(({ path, Component, exact }) => (
                    <Route path={path} key={path} exact={exact} component={Component} />
                  ))}
                  <Route path="/implicit/callback" component={Callback} />
                  {user.authenticated ? (
                    allowedPages.map(({ path, Component, exact }) => {
                      return <Route path={path} key={path} exact={exact} component={Component} />;
                    })
                  ) : (
                    <Redirect to={PAGES.LOGIN_PAGE} />
                  )}
                  <Route component={NotFoundPage} />
                </Switch>
              </Layout>
            </ConnectedRouter>
          </PortalProvider>
        </ThemeProvider>
      </DevicePreferencesProvider>
    );
  }
}

export default connector(Router);
