import { ClearingPositions, ClearingTrades } from 'state/clearingPositionsState';

import actions, { ClearingPositionsAction } from './actions';

export interface ClearingPositionsState {
  loading: boolean;
  positions: ClearingPositions[];
  trades: ClearingTrades[];
}

export const initialState: ClearingPositionsState = {
  loading: false,
  positions: [],
  trades: [],
};

export default function positionsReducer(
  state: ClearingPositionsState = initialState,
  action: ClearingPositionsAction,
): ClearingPositionsState {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
