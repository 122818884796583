import actions, { SettlementsAction } from 'redux/settlements/actions';
import { Settlement, SettlementType } from 'state/settlementsPageState';

export interface SettlementsState {
  loading: boolean;
  settlementPrices: Settlement[];
  intradaySettlementPrices: Settlement[];
  endOfDaySettlementPrices: Settlement[];
  finalSettlementPrices: Settlement[];
  showOverrideSettlementPricesModal: boolean;
  instrumentId: number | undefined;
  symbol: string | undefined;
  settlementType: SettlementType | undefined;
  settlementPrice: number | undefined;
}

export const initialState: any = {
  loading: false,
  settlementPrices: [],
  intradaySettlementPrices: [],
  endOfDaySettlementPrices: [],
  finalSettlementPrices: [],
  showOverrideSettlementPricesModal: false,
  instrumentId: undefined,
  symbol: undefined,
  settlementType: undefined,
  settlementPrice: undefined,
};

export default function settlementsReducer(
  state: SettlementsState = initialState,
  action: SettlementsAction,
): SettlementsState {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SHOW_MODAL: {
      const { instrumentId, symbol, settlementPrice, settlementType } = action.payload as any;
      return {
        ...state,
        instrumentId,
        symbol,
        settlementType,
        settlementPrice,
        showOverrideSettlementPricesModal: true,
      };
    }
    case actions.HIDE_MODAL:
      return {
        ...state,
        instrumentId: undefined,
        symbol: undefined,
        settlementType: undefined,
        settlementPrice: undefined,
        showOverrideSettlementPricesModal: false,
      };
    default:
      return state;
  }
}
