import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actions, { SET_STATE } from 'redux/holidays/actions';
import { setLoadingSpinner, unsetLoadingSpinner } from 'redux/loadingSpinners';
import { setErrorMessage, setMessage } from 'redux/setMessage';
import { addNewHoliday, getHolidayList } from 'services/holidays';

import { RootState } from '../reducers';

type Params = {
  type: typeof actions.LOAD_HOLIDAYS;
  payload: object;
};

export function* loadHolidaysSaga() {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(getHolidayList);
  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        holidays: response.data,
        loading: false,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* addNewHolidaySaga({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(addNewHoliday, payload);
  if (!error) {
    const state: RootState = yield select();
    const { holidays } = state;

    yield put({
      type: actions.SET_STATE,
      payload: {
        holidays: [response.data, ...holidays.holidays],
        loading: false,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield setMessage(response, 'Successfully added a new holiday', '', 'info');
  yield unsetLoadingSpinner(SET_STATE);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_HOLIDAYS, loadHolidaysSaga),
    takeEvery(actions.ADD_HOLIDAY, addNewHolidaySaga),
  ]);
}
