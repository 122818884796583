import { Trade } from 'state/ordersFillsPageState';

import actions from '../ordersFills/actions';
import { OrdersFillsAction } from './actions';

export interface OrdersFillsState {
  loading: boolean;
  trades: Trade[];
}
export const initialState: OrdersFillsState = {
  loading: false,
  trades: [],
};

export default function ordersFillsReducer(
  state: OrdersFillsState = initialState,
  action: OrdersFillsAction,
): OrdersFillsState {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
