import { ActiveConnectionsState } from './reducers';

export const SET_STATE = 'connections/SET_STATE';
export const LOAD_ACTIVE_CONNECTIONS = 'connections/LOAD_ACTIVE_CONNECTIONS';

interface SetActiveConnectionsStateAction {
  type: typeof SET_STATE;
  payload: Partial<ActiveConnectionsState>;
}

interface LoadActiveConnectionsAction {
  type: typeof LOAD_ACTIVE_CONNECTIONS;
  payload?: object;
}

const actions = {
  SET_STATE,
  LOAD_ACTIVE_CONNECTIONS,
};

export type ActiveConnectionsAction = SetActiveConnectionsStateAction | LoadActiveConnectionsAction;

export default actions;
