const DESCENDING_ORDER = 'desc';
const ASCENDING_ORDER = 'asc';

const numberComparator = (item1: number, item2: number, order: string = ASCENDING_ORDER) => {
  if (order === DESCENDING_ORDER) {
    return item2 - item1;
  }
  return item1 - item2;
};

export default numberComparator;
