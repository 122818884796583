import apiClient from 'services/apiClient';
import clearingApiClient from 'services/clearingApiClient';
import { TradeReportStatus } from 'state/clearingDataPageState';

export default async function getClearingData() {
  return Promise.allSettled([
    apiClient.get('/ncps'),
    apiClient.get('/itms'),
    apiClient.get('/itm-details'),
    apiClient.get('/ncp-fee-groups'),
    clearingApiClient.post('/filtered-trades', {
      statuses: [TradeReportStatus.Pending, TradeReportStatus.Queued, TradeReportStatus.Rejected],
    }),
  ])
    .then((responses) => {
      const responseArr: any = [];
      responses.forEach((response) => {
        if (response.status === 'fulfilled') {
          responseArr.push(response.value.data);
        } else if (response.status === 'rejected') {
          responseArr.push({ reason: response.reason });
        }
      });
      return {
        response: {
          ncpList: responseArr[0],
          itmList: responseArr[1],
          itmDetailsList: responseArr[2],
          ncpFeeGroupList: responseArr[3],
          trades: responseArr[4],
        },
      };
    })
    .catch((error) => ({ error }));
}
