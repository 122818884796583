import actions from './actions';

interface MenuDataState {
  menuData: any[];
}

export const initialState: MenuDataState = {
  menuData: [],
};

export default function menuReducer(state = initialState, action: any) {
  if (action) {
    switch (action.type) {
      case actions.SET_STATE:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  }
  return state;
}
