// eslint-disable-next-line
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { setLoadingSpinner, unsetLoadingSpinner } from 'redux/loadingSpinners';
import { RootState } from 'redux/reducers';
import { setErrorMessage, setMessage } from 'redux/setMessage';
import {
  addLeadMarketMakerUser,
  deleteLeadMarketMakerUser,
  getAllLeadMarketMakerUsers,
  getLeadMarketMakerUsersByProductAndTradingUser,
} from 'services/leadMarketMakers';
import { LeadMarketMakerUser } from 'state/lmmPageState';
import { Status } from 'state/sharedEnums';

import actions, { SET_STATE } from './actions';

type DeleteParams = {
  type: typeof actions.DELETE_LEAD_MARKET_MAKER_USER;
  payload: { lmmu: LeadMarketMakerUser };
};

type Params = {
  type: typeof actions.LOAD_LEAD_MARKET_MAKER_USERS;
  payload?: { productId?: number; tradingUserId?: number };
};

export function* LOAD_LEAD_MARKET_MAKERS({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);

  const { productId, tradingUserId } = payload || {};
  const { response, error } = payload
    ? yield call(getLeadMarketMakerUsersByProductAndTradingUser, {
        productId: Number(productId),
        tradingUserId,
      })
    : yield call(getAllLeadMarketMakerUsers);
  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        lmmuList: response.data,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* ADD_NEW_LEAD_MARKET_MAKER_USER({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);
  const state: RootState = yield select();

  const { response, error } = yield call(addLeadMarketMakerUser, {
    productId: Number((payload || {}).productId),
    tradingUserId: Number((payload || {}).tradingUserId),
    status: Status.Active,
  });
  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        lmmuList: [...state.lmm.lmmuList, response.data],
      },
    });
    yield setMessage(
      response,
      'A new session based Lead Market Maker was successfully added',
      '',
      'info',
    );
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* DELETE_LEAD_MARKET_MAKER_USER({ payload }: DeleteParams) {
  yield setLoadingSpinner(SET_STATE);
  const state: RootState = yield select();
  const { response, error } = yield call(deleteLeadMarketMakerUser, payload);
  if (!error) {
    const updatedLmmuList = state.lmm.lmmuList.filter(
      (lmmu: LeadMarketMakerUser) => lmmu.id !== payload.lmmu.id,
    );
    yield put({
      type: actions.SET_STATE,
      payload: {
        lmmuList: updatedLmmuList,
      },
    });
    yield setMessage(
      response,
      'Deleted the session based Lead Market Maker successfully',
      '',
      'info',
    );
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_LEAD_MARKET_MAKER_USERS, LOAD_LEAD_MARKET_MAKERS),
    takeEvery(actions.ADD_NEW_LEAD_MARKET_MAKER_USER, ADD_NEW_LEAD_MARKET_MAKER_USER),
    takeEvery(actions.DELETE_LEAD_MARKET_MAKER_USER, DELETE_LEAD_MARKET_MAKER_USER),
  ]);
}
