import { Moment } from 'moment';
import { InstrumentsState } from 'redux/instruments/reducers';

export const SET_STATE = 'instruments/SET_STATE';
export const LOAD_INSTRUMENTS = 'instruments/LOAD_INSTRUMENTS';
export const LOAD_INSTRUMENTS_FAILED = 'instruments/LOAD_INSTRUMENTS_FAILED';
export const EDIT_INSTRUMENTS = 'instruments/EDIT_INSTRUMENTS';

export const SHOW_MODAL = 'instruments/SHOW_MODAL';
export const HIDE_MODAL = 'instruments/HIDE_MODAL';

export interface EditInstrumentsParam {
  instrumentIds: number[];
  priceBand: number;
  overrideLastEndOfDaySettlementPrice: number;
  maxOrderSize: number;
  marketOrderProtectionTicks: number;
  initialMargin: number;
  lowLimitPercentage: number;
  lowLimitPrice: number;
  highLimitPercentage: number;
  highLimitPrice: number;
  activationTime: Moment;
  expirationTime: Moment;
}

interface SetInstrumentsStateAction {
  type: typeof SET_STATE;
  payload: Partial<InstrumentsState>;
}

interface LoadInstrumentsAction {
  type: typeof LOAD_INSTRUMENTS;
  payload: object;
}

export interface EditInstrumentsAction {
  type: typeof EDIT_INSTRUMENTS;
  payload: Partial<EditInstrumentsParam>;
}

interface SetInstrumentsStateAction {
  type: typeof SET_STATE;
  payload: Partial<InstrumentsState>;
}

interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: object;
}

interface HideModalAction {
  type: typeof HIDE_MODAL;
  payload: object;
}

const actions = {
  SET_STATE,
  LOAD_INSTRUMENTS,
  LOAD_INSTRUMENTS_FAILED,
  EDIT_INSTRUMENTS,
  SHOW_MODAL,
  HIDE_MODAL,
};

export type InstrumentsAction =
  | SetInstrumentsStateAction
  | LoadInstrumentsAction
  | SetInstrumentsStateAction
  | EditInstrumentsAction
  | ShowModalAction
  | HideModalAction;

export default actions;
