import apiClient from 'services/apiClient';

import {
  PositionLimitFcmSubscription,
  PositionLimitFirmSubscription,
} from '../state/positionLimitSubscriptionsPage';
import removeEmpty from '../utils/removeEmpty';

export async function getPositionLimitFirmSubscriptions() {
  return apiClient
    .get('/position-limit-firm-subscriptions')
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function getPositionLimitFcmSubscriptions() {
  return apiClient
    .get('/position-limit-fcm-subscriptions/')
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function getPositionLimitSubscriptions() {
  return Promise.allSettled([
    apiClient.get('/position-limit-firm-subscriptions'),
    apiClient.get('/position-limit-fcm-subscriptions'),
  ])
    .then((responses) => {
      const responseArr: any = [];
      responses.forEach((response) => {
        if (response.status === 'fulfilled') {
          responseArr.push(response.value.data);
        } else if (response.status === 'rejected') {
          responseArr.push({ reason: response.reason });
        }
      });
      return {
        response: {
          positionLimitFirmSubscriptions: responseArr[0],
          positionLimitFcmSubscriptions: responseArr[1],
        },
      };
    })
    .catch((error) => ({ error }));
}

export async function addPositionLimitFirmSubscription(params: object) {
  const reqParams = removeEmpty(params);

  return apiClient
    .post('/position-limit-firm-subscriptions/', reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function addPositionLimitFcmSubscription(params: object) {
  const reqParams = removeEmpty(params);

  return apiClient
    .post('/position-limit-fcm-subscriptions/', reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function removePositionLimitFirmSubscriptions(
  selectedRow: PositionLimitFirmSubscription,
) {
  return apiClient
    .post(`/position-limit-firm-subscriptions/${selectedRow.id}/delete/`, selectedRow)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function removePositionLimitFcmSubscriptions(
  selectedRow: PositionLimitFcmSubscription,
) {
  return apiClient
    .post(`/position-limit-fcm-subscriptions/${selectedRow.id}/delete/`, selectedRow)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
