import { Fill } from 'state/fillPageState';

import actions, { FillAction } from './actions';

export interface FillsState {
  loading: boolean;
  fills: Fill[];
  showCancelTradeBustModal: boolean;
  showAmendBustModal: boolean;
  currentFill: Fill;
}

export const initialState: FillsState = {
  loading: false,
  fills: [],
  showCancelTradeBustModal: false,
  showAmendBustModal: false,
  currentFill: {} as Fill,
};

export default function fillsReducer(
  state: FillsState = initialState,
  action: FillAction,
): FillsState {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SHOW_MODAL: {
      switch ((action as any).payload.modalType) {
        case 'CANCEL_TRADE_BUST':
          return { ...state, showCancelTradeBustModal: true, ...action.payload };
        case 'AMEND_TRADE':
          return { ...state, showAmendBustModal: true, ...action.payload };
        default:
          return state;
      }
    }
    case actions.HIDE_MODAL: {
      switch ((action as any).payload.modalType) {
        case 'CANCEL_TRADE_BUST':
          return { ...state, showCancelTradeBustModal: false, currentFill: {} as Fill };
        case 'AMEND_TRADE':
          return { ...state, showAmendBustModal: false, currentFill: {} as Fill };
        default:
          return state;
      }
    }
    default:
      return state;
  }
}
