import { PositionLimitSubscriptionsState } from './reducers';

export const SET_STATE = 'positionLimitSubscriptions/SET_STATE';
export const LOAD_POSITION_LIMIT_SUBSCRIPTIONS =
  'positionLimitSubscriptions/LOAD_POSITION_LIMIT_SUBSCRIPTIONS';
export const SHOW_CREATE_SUBSCRIPTION_MODAL =
  'positionLimitSubscriptions/SHOW_CREATE_SUBSCRIPTION_MODAL';
export const HIDE_CREATE_SUBSCRIPTION_MODAL =
  'positionLimitSubscriptions/HIDE_CREATE_SUBSCRIPTION_MODAL';
export const SHOW_CONFIRM_REMOVE_MODAL = 'positionLimitSubscriptions/SHOW_CONFIRM_REMOVE_MODAL';
export const HIDE_CONFIRM_REMOVE_MODAL = 'positionLimitSubscriptions/HIDE_CONFIRM_REMOVE_MODAL';

export const ADD_POSITION_LIMIT_SUBSCRIPTIONS =
  'positionLimitSubscriptions/ADD_POSITION_LIMIT_SUBSCRIPTIONS';
export const REMOVE_POSITION_LIMIT_SUBSCRIPTIONS =
  'positionLimitSubscriptions/REMOVE_POSITION_LIMIT_SUBSCRIPTIONS';

export interface SetPositionLimitSubscriptionAction {
  type: typeof SET_STATE;
  payload: Partial<PositionLimitSubscriptionsState>;
}

export interface LoadPositionLimitSubscriptionsAction {
  type: typeof LOAD_POSITION_LIMIT_SUBSCRIPTIONS;
  payload?: object;
}

export interface ToggleModalPayloadObject {
  showCreateSubscriptionModal: boolean;
  showConfirmRemoveModal: boolean;
}

export interface ToggleModal {
  type:
    | typeof SHOW_CREATE_SUBSCRIPTION_MODAL
    | typeof HIDE_CREATE_SUBSCRIPTION_MODAL
    | typeof SHOW_CONFIRM_REMOVE_MODAL
    | typeof HIDE_CONFIRM_REMOVE_MODAL;
  payload?: Partial<ToggleModalPayloadObject>;
}

export interface AddPositionLimitSubscriptionsAction {
  type: typeof ADD_POSITION_LIMIT_SUBSCRIPTIONS;
  payload: object;
}

export interface RemovePositionLimitSubscriptionsAction {
  type: typeof REMOVE_POSITION_LIMIT_SUBSCRIPTIONS;
  payload: object;
}

const actions = {
  SET_STATE,
  LOAD_POSITION_LIMIT_SUBSCRIPTIONS,
  SHOW_CREATE_SUBSCRIPTION_MODAL,
  HIDE_CREATE_SUBSCRIPTION_MODAL,
  SHOW_CONFIRM_REMOVE_MODAL,
  HIDE_CONFIRM_REMOVE_MODAL,
  ADD_POSITION_LIMIT_SUBSCRIPTIONS,
  REMOVE_POSITION_LIMIT_SUBSCRIPTIONS,
};

export type PositionLimitSubscriptionsAction =
  | SetPositionLimitSubscriptionAction
  | LoadPositionLimitSubscriptionsAction
  | ToggleModal
  | AddPositionLimitSubscriptionsAction
  | RemovePositionLimitSubscriptionsAction;

export default actions;
