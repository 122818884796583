import '@ant-design/compatible/assets/index.css';

import './FlashMessage.scss';

import Alert from 'antd/lib/alert';
import { DEFAULT_FLASH_MESSAGE_TIMEOUT, FlashMessageType } from 'fairxConstants';
import React, { useEffect } from 'react';

interface FlashMessageProps {
  flashMessage: string | React.ReactNode;
  description?: string | React.ReactNode;
  onDismiss: React.MouseEventHandler<HTMLButtonElement>;
  type?: FlashMessageType;
  shouldDismissAutomatically: boolean;
}

const FlashMessage = ({
  flashMessage,
  description,
  onDismiss,
  type,
  shouldDismissAutomatically,
  ...props
}: FlashMessageProps) => {
  useEffect(() => {
    if (shouldDismissAutomatically) {
      setTimeout(onDismiss, DEFAULT_FLASH_MESSAGE_TIMEOUT);
    }
  }, []);

  return (
    <Alert
      message={flashMessage}
      description={description}
      onClose={onDismiss}
      type={type}
      closable={!shouldDismissAutomatically}
      showIcon
    />
  );
};

export default FlashMessage;

FlashMessage.defaultProps = {
  description: '',
  type: 'info',
};
