import actions, { UsersAction } from 'redux/users/actions';
import {
  AdminUser,
  AllDropCopySubscriptionsArray,
  DropCopyFcmSubscription,
  DropCopyFirmSubscription,
  DropCopySubscription,
  DropCopyUser,
  MarketDataUser,
  TradingUser,
} from 'state/usersPageState';

export interface UsersState {
  loading: boolean;
  allTradingUsers: Record<string, TradingUser>;
  allDropCopyUsers: Record<string, DropCopyUser>;
  allAdminUsers: Record<string, AdminUser>;
  allMarketDataUsers: Record<string, MarketDataUser>;
  selfMatchPreventions: any[];
  showAddSmpModal: boolean;
  showRemoveSmpModal: boolean;
  allDropCopySubscriptions: AllDropCopySubscriptionsArray;
  dropCopySubscriptions: DropCopySubscription[];
  dropCopyFirmSubscriptions: DropCopyFirmSubscription[];
  dropCopyFcmSubscriptions: DropCopyFcmSubscription[];
  globalDropCopyUser: DropCopyUser;
}

export const initialState: UsersState = {
  loading: false,
  allTradingUsers: {},
  allDropCopyUsers: {},
  allAdminUsers: {},
  allMarketDataUsers: {},
  selfMatchPreventions: [],
  showAddSmpModal: false,
  showRemoveSmpModal: false,
  allDropCopySubscriptions: [],
  dropCopySubscriptions: [],
  dropCopyFirmSubscriptions: [],
  dropCopyFcmSubscriptions: [],
  globalDropCopyUser: {} as DropCopyUser,
};

export default function usersReducer(
  state: UsersState = initialState,
  action: UsersAction,
): UsersState {
  if (action) {
    switch (action.type) {
      case actions.LOAD_USERS:
        return { ...state, loading: true };
      case actions.SET_STATE:
        return { ...state, ...action.payload };
      case actions.SHOW_ADD_NEW_SMP_MODAL:
        return { ...state, showAddSmpModal: true, ...action.payload };
      case actions.HIDE_ADD_NEW_SMP_MODAL:
        return { ...state, showAddSmpModal: false, ...action.payload };
      case actions.SHOW_REMOVE_SMP_MODAL:
        return { ...state, showRemoveSmpModal: true, ...action.payload };
      case actions.HIDE_REMOVE_SMP_MODAL:
        return { ...state, showRemoveSmpModal: false, ...action.payload };
      default:
        return state;
    }
  }
  return state;
}
