import { ResendPendingTradesPayload } from 'redux/clearing/actions';
import clearingApiClient from 'services/clearingApiClient';
import removeEmpty from 'utils/removeEmpty';

export async function resendPendingTrades(params: object) {
  const { tradeIds, resendAll } = params as ResendPendingTradesPayload;
  if (resendAll) {
    return clearingApiClient
      .post('/resend-pending-trades/all')
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  }

  return clearingApiClient
    .post(`/resend-pending-trades`, tradeIds)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function resendRejectedTrades(params: object) {
  return clearingApiClient
    .post(`/resend-rejected-trades`, params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function editRejectedTrades(params: object) {
  const reqParams = removeEmpty(params);
  return clearingApiClient
    .post(`/update-rejected-trade`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
