import apiClient from 'services/apiClient';
import removeEmpty from 'utils/removeEmpty';

export default async function getOrderDetails(params: any) {
  const reqParams = removeEmpty(params);

  const { id } = params;
  return apiClient
    .get(`/order-details/${id}`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
