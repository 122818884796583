import moment, { Moment } from 'moment';

export const FULL_DATE_FORMAT_WITH_TIMEZONE_24_HOURS: string = 'MM/DD/YYYY, HH:mm:ss.SSSSSS Z';
export const FULL_DATE_FORMAT_WITHOUT_TIMEZONE_24_HOURS: string = 'MM/DD/YYYY, HH:mm:ss.SSSSSS A';
export const DATE_FORMAT_WITH_TIMEZONE_24_HOURS: string = 'MM/DD/YYYY, HH:mm:ss A Z';
export const DATE_FORMAT_WITHOUT_TIMEZONE: string = 'MM/DD/YYYY, hh:mm:ss A';
export const DATE_FORMAT_WITH_TIMEZONE: string = 'MM/DD/YYYY, hh:mm:ss A Z';
export const DATE_FORMAT: string = 'MM/DD/YYYY, hh:mm:ss A';
export const SHORT_DATE_FORMAT: string = 'MM/DD/YYYY';
export const SHORT_DATE_FORMAT2: string = 'MMM DD, YYYY';

interface FormattedTimeArgs {
  time: number;
  showTimezone: boolean;
  isUTC: boolean;
  isNanos?: boolean;
  dateFormat?: string;
}
// TODO: Change isUTC argument to actually pass in timezone string, e.g: 'America/Los_Angeles' or 'CDT' in the future
const getFormattedTime = ({
  time,
  showTimezone = false,
  isUTC = false,
  isNanos = true,
  dateFormat,
}: FormattedTimeArgs) => {
  if (!time || time < 0) {
    return '';
  }
  const micros = isNanos ? time.toString().slice(-6, -3) : '000';
  const millisTimestamp = isNanos ? time / 1000000 : time;
  const date: Moment = isUTC ? moment.utc(millisTimestamp) : moment(millisTimestamp);

  let result;
  if (dateFormat) {
    result = date.format(dateFormat);
  } else if (!showTimezone) {
    result = date.format(FULL_DATE_FORMAT_WITHOUT_TIMEZONE_24_HOURS);
  } else {
    // Default to a 24 hr full date/time with milliseconds date format.
    result = date.format(FULL_DATE_FORMAT_WITH_TIMEZONE_24_HOURS);
  }
  return result.replace(/(\.\d{3})(\d+)/, `$1${micros}`);
};

export default getFormattedTime;
