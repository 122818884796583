import { LeadMarketMakerUser } from 'state/lmmPageState';

import { LMMState } from './reducers';

export const SET_STATE = 'lmmu/SET_STATE';
export const LOAD_LEAD_MARKET_MAKER_USERS = 'lmmu/LOAD_LEAD_MARKET_MAKER_USERS';
export const ADD_NEW_LEAD_MARKET_MAKER_USER = 'lmmu/ADD_NEW_LEAD_MARKET_MAKER_USER';
export const DELETE_LEAD_MARKET_MAKER_USER = 'lmmu/DELETE_LEAD_MARKET_MAKER_USER';

interface SetLMMStateAction {
  type: typeof SET_STATE;
  payload: Partial<LMMState>;
}

interface LoadLMMUAction {
  type: typeof LOAD_LEAD_MARKET_MAKER_USERS;
  payload: object;
}

interface AddNewLMMUser {
  type: typeof ADD_NEW_LEAD_MARKET_MAKER_USER;
  payload: Partial<LeadMarketMakerUser>;
}

interface DeleteLMMUser {
  type: typeof DELETE_LEAD_MARKET_MAKER_USER;
  payload: string;
}

const actions = {
  SET_STATE,
  LOAD_LEAD_MARKET_MAKER_USERS,
  ADD_NEW_LEAD_MARKET_MAKER_USER,
  DELETE_LEAD_MARKET_MAKER_USER,
};

export type LMMAction = SetLMMStateAction | LoadLMMUAction | AddNewLMMUser | DeleteLMMUser;

export default actions;
