import { all, call, takeEvery } from 'redux-saga/effects';
import { setLoadingSpinner, unsetLoadingSpinner } from 'redux/loadingSpinners';
import { setErrorMessage, setFlashMessage } from 'redux/setMessage';
import getAudit from 'services/getAudit';
import { exportFile } from 'utils/downloadFile';

import actions, { SET_STATE } from './actions';

type Params = {
  type: typeof actions.LOAD_AUDIT;
  payload: object;
};

export function* LOAD_AUDIT({ payload }: Params) {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(getAudit, payload);
  if (!error) {
    if (response.data && response.data.csv) {
      yield exportFile(response.data.csv, 'Audit Trail.csv');
    } else {
      yield setFlashMessage({
        message: 'Audit Trail data error',
        description: 'Description: CSV data could not be read',
        type: 'error',
      });
    }
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_AUDIT, LOAD_AUDIT)]);
}
