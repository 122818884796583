import apiClient from 'services/apiClient';
import { Firm, FirmPositions } from 'state/firmPageState';
import removeEmpty from 'utils/removeEmpty';

export const combineFirmsAndPositions = (firms: any, positions: any) => {
  const firmNames = firms.map((firm: Firm) => firm.name);
  let firmPositions: FirmPositions = {};
  firmPositions = firmNames.reduce(
    (ac: any, currentVal: any) => ({
      ...ac,
      [currentVal]: {
        firmName: currentVal,
        firmDetails: firms.find((f: Firm) => f.name === currentVal),
      },
    }),
    firmPositions,
  );

  // We load the positions data conditionally if a user has the appropriate permissions to view the Firms page or is an exchange admin,
  // If the positions endpoint is called, only then attach the positions to the firm.
  if (positions) {
    Object.entries(positions).forEach(([key, value]: [string, any]) => {
      const allPositions = {};
      Object.entries(value.positions).forEach(([posKey, posVal]: [string, any]) => {
        allPositions[posKey] = {
          ...posVal,
          // calculate the computed values(totalFuturesLong, totalFuturesShort) here when we first receive all the positions from the server.
          totalFuturesLong:
            posVal.longWorkingPosition + posVal.longFilledPosition - posVal.shortFilledPosition,
          totalFuturesShort:
            posVal.shortWorkingPosition + posVal.shortFilledPosition - posVal.longFilledPosition,
        };
      });

      firmPositions[key] = {
        ...firmPositions[key],
        ...value,
        positions: allPositions,
      };
    });
  }

  const sortedFirmPositions = {};
  // sort firms alphabetically.
  Object.keys(firmPositions)
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    .forEach((key) => {
      sortedFirmPositions[key] = firmPositions[key];
    });
  return sortedFirmPositions;
};

export default async function getFirmPositions(params: object, hasFirmsPagePermissions: boolean) {
  const reqParams = removeEmpty(params);
  const { expiredFilter } = reqParams;

  return Promise.all([
    apiClient.get('/firms'),
    ...(hasFirmsPagePermissions
      ? [apiClient.get(`/positions?expiredFilter=${expiredFilter}`, params)]
      : []),
  ])
    .then((response) => {
      const firmsData = response[0].data;
      // since positions endpoint is only called when the user has firms page permissions, we pass in an empty object otherwise
      const positionsData = (response[1] || {}).data;

      return { response: combineFirmsAndPositions(firmsData, positionsData) };
    })
    .catch((error) => ({ error }));
}

export const requests = {
  getFirmPositions,
};
