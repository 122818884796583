import apiClient from 'services/apiClient';
import removeEmpty from 'utils/removeEmpty';

export async function addTradingUser(params: any) {
  const reqParams = removeEmpty(params);
  return apiClient
    .post(`/users`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => {
      return error;
    });
}

export async function updateTradingUser(params: any) {
  const reqParams = removeEmpty(params);
  const { id } = params;
  return apiClient
    .post(`/users/${id}`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function addDropCopyUser(params: any) {
  const reqParams = removeEmpty(params);
  return apiClient
    .post(`/drop-copy-users`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function updateDropCopyUser(params: any) {
  const reqParams = removeEmpty(params);
  const { id } = params;
  return apiClient
    .post(`/drop-copy-users/${id}`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function addAdminUser(params: any) {
  const reqParams = removeEmpty(params);
  return apiClient
    .post(`/admin-users`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function updateAdminUser(params: any) {
  const reqParams = removeEmpty(params);
  const { id } = params;
  return apiClient
    .post(`/admin-users/${id}`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function addMarketDataUser(params: any) {
  const reqParams = removeEmpty(params);
  return apiClient
    .post(`/market-data-users`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function updateMarketDataUser(params: any) {
  const reqParams = removeEmpty(params);
  const { id } = params;
  return apiClient
    .post(`/market-data-users/${id}`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function updateUserCredentials(params: any) {
  const reqParams = removeEmpty(params);
  const { userId } = params;
  return apiClient
    .post(`/credentials/${userId}`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
