import removeEmpty from '../utils/removeEmpty';
import apiClient from './apiClient';

export default async function getFirmProductLimitsByFirm(params: object) {
  const reqParams = removeEmpty(params);
  const { firmId } = reqParams;

  return apiClient
    .get(`/firm-product-limits/${firmId}`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
