import { call, put, select, takeEvery } from 'redux-saga/effects';
import { setErrorMessage, setMessage } from 'redux/setMessage';
import {
  addDropCopySubscription,
  removeDropCopySubscription,
  updateDropCopySubscription,
} from 'services/users/dropCopySubscriptions';
import { SubscriptionLevel } from 'state/sharedEnums';
import { AnyDropCopySubscription, DropCopyUser } from 'state/usersPageState';

import { setLoadingSpinner, unsetLoadingSpinner } from '../../loadingSpinners';
import { RootState } from '../../reducers';
import actions, {
  AddDropCopySubscriptionAction,
  RemoveDropCopySubscriptionAction,
  SET_STATE,
  UpdateDropCopySubscriptionAction,
  UpdateDropCopyUserStateAction,
} from '../actions';
// @ts-ignore
import getDropCopySubscriptionsForDropCopyUser from './getDropCopySubscriptionsForDropCopyUser';

export function* addDropCopySubscriptionSaga({ payload }: AddDropCopySubscriptionAction) {
  const { params, subscriptionLevel } = payload;
  const { fcmId, firmId, tradingUserId, dropCopyUserId, subscriptionType } = params;

  const updatedPayload = {
    dropCopyUserId,
    subscriptionType,
    ...(SubscriptionLevel.FCM === subscriptionLevel && { fcmId }),
    ...(SubscriptionLevel.Firm === subscriptionLevel && { firmId }),
    ...(SubscriptionLevel.TradingUser === subscriptionLevel && { tradingUserId }),
  };
  const { response, error } = yield call(addDropCopySubscription, {
    params: updatedPayload,
    subscriptionLevel,
  });

  if (!error) {
    const state: RootState = yield select();
    const updatedAllDropCopySubscriptions = [
      response.data,
      ...state.users.allDropCopySubscriptions,
    ];
    const oldDropCopyUser: unknown = Object.values(state.users.allDropCopyUsers).find(
      (dcu: any) => dcu.id === dropCopyUserId,
    );

    const updatedDropCopyUser: DropCopyUser = {
      // @ts-ignore
      ...oldDropCopyUser,
      allDropCopySubscriptions: getDropCopySubscriptionsForDropCopyUser(
        updatedAllDropCopySubscriptions,
        (params as any).dropCopyUserId,
      ),
    };

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        allDropCopyUsers: {
          ...state.users.allDropCopyUsers,
          [(oldDropCopyUser as any).name]: updatedDropCopyUser,
        },
        allDropCopySubscriptions: updatedAllDropCopySubscriptions,
        ...(SubscriptionLevel.FCM === subscriptionLevel && {
          dropCopyFcmSubscriptions: [...state.users.dropCopyFcmSubscriptions, response.data],
        }),
        ...(SubscriptionLevel.Firm === subscriptionLevel && {
          dropCopyFirmSubscriptions: [...state.users.dropCopyFirmSubscriptions, response.data],
        }),
        ...(SubscriptionLevel.TradingUser === subscriptionLevel && {
          dropCopySubscriptions: [...state.users.dropCopySubscriptions, response.data],
        }),
      },
    });

    const updatedSubscriptions = [
      response.data,
      ...state.users.globalDropCopyUser.allDropCopySubscriptions,
    ];

    const updatedGlobalDropCopyUser = {
      ...state.users.globalDropCopyUser,
      allDropCopySubscriptions: updatedSubscriptions,
    };

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        globalDropCopyUser: updatedGlobalDropCopyUser,
      },
    });
    yield setMessage(
      response,
      `Successfully added a drop copy subscription at the ${payload.subscriptionLevel} level`,
      '',
      'info',
    );
  } else {
    yield setErrorMessage(error);
  }
}

export function* updateDropCopySubscriptionSaga({ payload }: UpdateDropCopySubscriptionAction) {
  const { response, error } = yield call(updateDropCopySubscription, payload);

  if (!error) {
    const state: RootState = yield select();
    const updatedAllDropCopySubscriptions = state.users.allDropCopySubscriptions.map(
      (dcs: AnyDropCopySubscription) => (dcs?.id === payload.params.id ? response.data : dcs),
    );
    const oldDropCopyUser = Object.values(state.users.allDropCopyUsers).find(
      (dcu: any) => dcu.id === payload.params.dropCopyUserId,
    );

    const updatedDropCopyUser = {
      // @ts-ignore
      ...oldDropCopyUser,
      allDropCopySubscriptions: getDropCopySubscriptionsForDropCopyUser(
        updatedAllDropCopySubscriptions,
        payload.params.dropCopyUserId,
      ),
    };

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        allDropCopySubscriptions: updatedAllDropCopySubscriptions,
        allDropCopyUsers: {
          ...state.users.allDropCopyUsers,
          [(oldDropCopyUser as any).name]: updatedDropCopyUser,
        },
      },
    });
    yield setMessage(response, `Successfully updated the drop copy subscription`, '', 'info');
  } else {
    yield setErrorMessage(error);
  }
}

export function* removeDropCopySubscriptionSaga({ payload }: RemoveDropCopySubscriptionAction) {
  const { response, error } = yield call(removeDropCopySubscription, payload);
  const removedSubId = response && response.data.id;

  if (!error) {
    const state: RootState = yield select();
    const updatedAllDropCopySubscriptions = state.users.allDropCopySubscriptions
      .map((dcs: AnyDropCopySubscription) => {
        if (dcs?.id !== payload.params.id) {
          return dcs;
        }
        return null;
      })
      .filter(Boolean);
    const oldDropCopyUser = Object.values(state.users.allDropCopyUsers).find(
      (dcu: any) => dcu.id === payload.params.dropCopyUserId,
    );

    const updatedDropCopyUser = {
      // @ts-ignore
      ...oldDropCopyUser,
      allDropCopySubscriptions: getDropCopySubscriptionsForDropCopyUser(
        updatedAllDropCopySubscriptions,
        payload.params.dropCopyUserId,
      ),
    };

    const dropCopyUserSubs = state.users.globalDropCopyUser.allDropCopySubscriptions;
    const item =
      dropCopyUserSubs.find((sub: any) => sub.id === removedSubId) ||
      ({} as AnyDropCopySubscription);
    const updatedSubscriptions = [...dropCopyUserSubs];
    const index = updatedSubscriptions.indexOf(item);
    updatedSubscriptions.splice(index, 1);

    const updatedGlobalDropCopyUser = {
      ...state.users.globalDropCopyUser,
      allDropCopySubscriptions: updatedSubscriptions,
    };

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        allDropCopySubscriptions: updatedAllDropCopySubscriptions,
        allDropCopyUsers: {
          ...state.users.allDropCopyUsers,
          [(oldDropCopyUser as any).name]: updatedDropCopyUser,
        },
        globalDropCopyUser: updatedGlobalDropCopyUser,
      },
    });
    yield setMessage(response, `Successfully removed the drop copy subscription`, '', 'info');
  } else {
    yield setErrorMessage(error);
  }
}

export function* setDropCopyUser({ payload }: UpdateDropCopyUserStateAction) {
  const dropCopyUser = payload;
  yield setLoadingSpinner(SET_STATE);

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      globalDropCopyUser: dropCopyUser,
    },
  });

  yield unsetLoadingSpinner(SET_STATE);
}

export default function dropCopySagas() {
  return [
    takeEvery(actions.ADD_DROP_COPY_SUBSCRIPTION, addDropCopySubscriptionSaga),
    takeEvery(actions.UPDATE_DROP_COPY_SUBSCRIPTION, updateDropCopySubscriptionSaga),
    takeEvery(actions.REMOVE_DROP_COPY_SUBSCRIPTION, removeDropCopySubscriptionSaga),
    takeEvery(actions.UPDATE_DROP_COPY_USER_STATE, setDropCopyUser),
  ];
}
