import '@ant-design/compatible/assets/index.css';

import { VStack } from '@cbhq/cds-web/layout/VStack';
import { TextTitle4 } from '@cbhq/cds-web/typography/TextTitle4';
import DetailRow from 'components/common/cards/DetailRow';
import React from 'react';
import { Instrument } from 'state/productPageState';
import capitalizeText from 'utils/capitalizeText';
import getFormattedTime from 'utils/renderHelper/getFormattedTime';

interface InstrumentInformationProps {
  instrument: Instrument | any;
  showTitle: boolean;
}

export const InstrumentInformation = ({ instrument, showTitle }: InstrumentInformationProps) => {
  return (
    <VStack spacing={2} width="100%" bordered borderRadius="rounded">
      {showTitle && <TextTitle4 as="p">Instrument Specs</TextTitle4>}
      <VStack>
        <DetailRow label="Contract Type" value={capitalizeText(instrument?.contractType)} topRow />
        <DetailRow label="State" value={capitalizeText(instrument?.state.status)} />
        <DetailRow label="Trading State" value={capitalizeText(instrument?.state.tradingState)} />
        <DetailRow
          label="Last Trading Session Date"
          value={getFormattedTime({
            time: instrument?.lastTradingSessionDate,
            showTimezone: true,
            isUTC: false,
          })}
        />
      </VStack>
    </VStack>
  );
};

export default InstrumentInformation;
