import removeEmpty from '../utils/removeEmpty';
import apiClient from './apiClient';

export default async function killActiveSessions(params: object) {
  const reqParams = removeEmpty(params);

  return apiClient
    .post(`/kill-switch`, reqParams)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
