import { FlashMessageState } from 'redux/application/reducers';

export const SET_STATE = 'app/SET_STATE';
export const SET_FLASH_MESSAGE = 'app/SET_FLASH_MESSAGE';
export const LOAD_ALL_DATA = 'app/LOAD_ALL_DATA';
export const DISMISS_FLASH_MESSAGE = 'app/DISMISS_FLASH_MESSAGE';
export const SET_PAGE_NAME = 'app/SET_PAGE_HEADER';
export const LOAD_VERSION_INFO = 'app/LOAD_VERSION_INFO';

export interface AppPayload {
  flashMessage: FlashMessageState;
  pageName: string;
  versionInfo: string;
}

interface SetStateAction {
  type: typeof SET_STATE;
  payload: Partial<AppPayload>;
}

export interface DismissFlashMessageAction {
  type: typeof DISMISS_FLASH_MESSAGE;
  payload: Partial<FlashMessageState>;
}

export interface SetFlashMessageAction {
  type: typeof SET_FLASH_MESSAGE;
  payload: Partial<FlashMessageState>;
}

export interface LoadAllDataAction {
  type: typeof LOAD_ALL_DATA;
  payload: object;
}

export interface LoadVersionInfoAction {
  type: typeof LOAD_VERSION_INFO;
  payload: object;
}

const generalActions = {
  SET_STATE,
  SET_FLASH_MESSAGE,
  DISMISS_FLASH_MESSAGE,
  LOAD_VERSION_INFO,
  LOAD_ALL_DATA,
  SET_PAGE_NAME,
};

export type AppAction = SetFlashMessageAction | DismissFlashMessageAction | LoadVersionInfoAction;

export type GeneralActions =
  | SetStateAction
  | SetFlashMessageAction
  | DismissFlashMessageAction
  | LoadAllDataAction
  | LoadVersionInfoAction
  | AppAction;

export default generalActions;
