import actions, { UserManagementAction } from 'redux/userManagement/actions';
import { ResourceType } from 'state/sharedEnums';

export interface Resource {
  type: ResourceType;
  id: number;
}

export interface Permission {
  adminOperations: ('FILL_READ' | 'ORDER_READ' | 'ORDER_WRITE' | 'RISK' | 'SESSION_KILL')[];
  resources: Resource[];
}

export interface UserManagementState {
  loading: boolean;
  permissions: Permission;
}

export const initialState: any = {
  loading: false,
  permissions: {} as Permission,
};

export default function userManagementReducer(
  state: UserManagementState = initialState,
  action: UserManagementAction,
): UserManagementState {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
