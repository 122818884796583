import { ProductsState } from 'state/productPageState';

export const SET_STATE = 'products/SET_STATE';
export const LOAD_PRODUCTS = 'products/LOAD_PRODUCTS';
export const LOAD_PRODUCTS_FAILED = 'products/LOAD_PRODUCTS_FAILED';
export const UPDATE_PRODUCT_LIMITS = 'products/UPDATE_PRODUCT_LIMITS';
export const SET_SEARCH_TEXT = 'products/SET_SEARCH_TEXT';
export const SET_INSTRUMENT_ID = 'products/SET_INSTRUMENT_ID';

export const LOAD_SCHEDULED_PRODUCTS_TRANSITIONS_LIST =
  'products/LOAD_SCHEDULED_PRODUCTS_TRANSITIONS_LIST';
export const CANCEL_SCHEDULED_PRODUCT_TRADING_STATE_TRANSITION =
  'products/CANCEL_SCHEDULED_PRODUCT_TRADING_STATE_TRANSITION';
export const SCHEDULE_PRODUCT_TRADING_STATE_TRANSITION =
  'products/SCHEDULE_PRODUCT_TRADING_STATE_TRANSITION';

interface SetProductsStateAction {
  type: typeof SET_STATE;
  payload: Partial<ProductsState>;
}

interface LoadProductsAction {
  type: typeof LOAD_PRODUCTS;
  payload: object;
}

interface LoadScheduledProductsTransitionsListAction {
  type: typeof LOAD_SCHEDULED_PRODUCTS_TRANSITIONS_LIST;
  payload: object;
}

interface CancelScheduledProductTradingStateTransitionAction {
  type: typeof CANCEL_SCHEDULED_PRODUCT_TRADING_STATE_TRANSITION;
  payload: object;
}

interface ScheduleProductsTradingStateTransitionAction {
  type: typeof SCHEDULE_PRODUCT_TRADING_STATE_TRANSITION;
  payload: object;
}

export interface UpdateProductLimitsAction {
  type: typeof UPDATE_PRODUCT_LIMITS;
  payload: object;
}

export const actions = {
  SET_STATE,
  LOAD_PRODUCTS,
  LOAD_PRODUCTS_FAILED,
  UPDATE_PRODUCT_LIMITS,
  SET_SEARCH_TEXT,
  LOAD_SCHEDULED_PRODUCTS_TRANSITIONS_LIST,
  CANCEL_SCHEDULED_PRODUCT_TRADING_STATE_TRANSITION,
  SCHEDULE_PRODUCT_TRADING_STATE_TRANSITION,
  SET_INSTRUMENT_ID,
};

export type ProductsAction =
  | SetProductsStateAction
  | LoadProductsAction
  | UpdateProductLimitsAction
  | LoadScheduledProductsTransitionsListAction
  | CancelScheduledProductTradingStateTransitionAction
  | ScheduleProductsTradingStateTransitionAction;

export default actions;
