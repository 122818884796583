import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import menu from 'redux/menu/reducers';

import activeConnections from './activeConnections/reducers';
import application from './application/reducers';
import user from './auth/reducers';
import clearing from './clearing/reducers';
import clearingPositions from './clearingPositions/reducers';
import events from './events/reducers';
import fills from './fills/reducers';
import firms from './firms/reducers';
import holidays from './holidays/reducers';
import instruments from './instruments/reducers';
import lmm from './lmm/reducers';
import orderBook from './orderBook/reducers';
import orderDetails from './orderDetails/reducers';
import orders from './orders/reducers';
import ordersFills from './ordersFills/reducers';
import positionLimitSubscriptions from './positionLimitSubscriptions/reducers';
import products from './products/reducers';
import settings from './settings/reducers';
import settlements from './settlements/reducers';
import userManagement from './userManagement/reducers';
import userPermissions from './userPermissions/reducers';
import users from './users/reducers';

const reducers = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    user,
    orders,
    orderDetails,
    fills,
    ordersFills,
    firms,
    users,
    products,
    instruments,
    clearing,
    holidays,
    application,
    clearingPositions,
    positionLimitSubscriptions,
    settlements,
    userManagement,
    menu,
    settings,
    userPermissions,
    activeConnections,
    orderBook,
    events,
    lmm,
  });

export default reducers;

export type RootState = ReturnType<ReturnType<typeof reducers>>;
