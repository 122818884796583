import { ClearingPositionsState } from './reducers';

export const SET_STATE = 'clearingPositions/SET_STATE';
export const LOAD_CLEARING_POSITIONS = 'clearingPositions/LOAD_POSITIONS';
export const LOAD_CLEARING_POSITIONS_FAILED = 'clearingPositions/LOAD_POSITIONS_FAILED';
export const LOAD_TRADES = 'clearingPositions/LOAD_TRADES';
export const TRANSFER_PORTFOLIO = 'clearingPositions/TRANSFER_PORTFOLIO';
export const BULK_TRANSFER_PORTFOLIO = 'clearingPositions/BULK_TRANSFER_PORTFOLIO';

interface SetClearingPositionsStateAction {
  type: typeof SET_STATE;
  payload: Partial<ClearingPositionsState>;
}

interface LoadClearingPositionsAction {
  type: typeof LOAD_CLEARING_POSITIONS;
  payload: object;
}

interface LoadTradesAction {
  type: typeof LOAD_TRADES;
  payload: object;
}

interface TransferAction {
  type: typeof TRANSFER_PORTFOLIO;
  payload: object;
}

interface BulkTransferAction {
  type: typeof BULK_TRANSFER_PORTFOLIO;
  payload: [];
}

const actions = {
  SET_STATE,
  LOAD_CLEARING_POSITIONS,
  LOAD_CLEARING_POSITIONS_FAILED,
  LOAD_TRADES,
  TRANSFER_PORTFOLIO,
  BULK_TRANSFER_PORTFOLIO,
};

export type ClearingPositionsAction =
  | SetClearingPositionsStateAction
  | LoadClearingPositionsAction
  | LoadTradesAction
  | TransferAction
  | BulkTransferAction;

export default actions;
