import { OrdersState } from './reducers';

export const SET_STATE = 'orders/SET_STATE';
export const LOAD_ORDERS = 'orders/LOAD_ORDERS';
export const LOAD_ORDERS_FAILED = 'orders/LOAD_ORDERS_FAILED';
export const CANCEL_ORDER = 'orders/CANCEL_ORDER';
export const SHOW_MODAL = 'orders/SHOW_MODAL';
export const HIDE_MODAL = 'orders/HIDE_MODAL';

interface SetOrdersStateAction {
  type: typeof SET_STATE;
  payload: Partial<OrdersState>;
}

interface LoadOrdersAction {
  type: typeof LOAD_ORDERS;
  payload: object;
}

interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: object;
}

interface HideModalAction {
  type: typeof HIDE_MODAL;
  payload: object;
}

interface CancelOrdersAction {
  type: typeof CANCEL_ORDER;
  payload: object;
}

const actions = {
  SET_STATE,
  LOAD_ORDERS,
  LOAD_ORDERS_FAILED,
  CANCEL_ORDER,
  SHOW_MODAL,
  HIDE_MODAL,
};

export type OrderAction =
  | SetOrdersStateAction
  | LoadOrdersAction
  | ShowModalAction
  | HideModalAction
  | CancelOrdersAction;

export default actions;
