import { DropCopySubscriptionsPayload } from 'redux/users/actions';
import { Status, SubscriptionLevel } from 'state/sharedEnums';

import apiClient from '../apiClient';

const geDCSEndpointURL = (subscriptionLevel: SubscriptionLevel) => {
  switch (subscriptionLevel) {
    case SubscriptionLevel.TradingUser:
      return '/drop-copy-subscriptions';
    case SubscriptionLevel.FCM:
      return '/drop-copy-fcm-subscriptions';
    case SubscriptionLevel.Firm:
      return '/drop-copy-firm-subscriptions';
    default:
      return null;
  }
};

export async function addDropCopySubscription(dcsp: DropCopySubscriptionsPayload) {
  return apiClient
    .post(`${geDCSEndpointURL(dcsp.subscriptionLevel)}`, { ...dcsp.params, status: Status.Active })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function updateDropCopySubscription(dcsp: DropCopySubscriptionsPayload) {
  return apiClient
    .post(`${geDCSEndpointURL(dcsp.subscriptionLevel)}/${dcsp.params.id}`, dcsp.params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export async function removeDropCopySubscription(dcsp: DropCopySubscriptionsPayload) {
  return apiClient
    .post(`${geDCSEndpointURL(dcsp.subscriptionLevel)}/${dcsp.params.id}/delete`, dcsp.params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
