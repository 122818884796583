import { VStack } from '@cbhq/cds-web/layout';
import { HStack } from '@cbhq/cds-web/layout/HStack';
import Footer from 'components/layout/Footer';
import SidebarWrapper from 'components/layout/Sidebar';
import TopBar from 'components/layout/TopBar';
import FlashMessage from 'FlashMessage';
import React from 'react';
import { connect } from 'react-redux';
import { DISMISS_FLASH_MESSAGE } from 'redux/application/actions';

const mapStateToProps = ({ application }) => ({ flashMessage: application.flashMessage });

const mapDispatchToProps = {
  onDismissFlashMessage: () => ({
    type: DISMISS_FLASH_MESSAGE,
    payload: { flashMessage: { message: '', description: '', type: '' } },
  }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const AppLayout = ({ children, flashMessage, onDismissFlashMessage }) => {
  const { type, message, description } = flashMessage;

  const renderFlashMessage = (shouldDismissAutomatically, type) => {
    return (
      <FlashMessage
        flashMessage={message}
        description={description}
        onDismiss={onDismissFlashMessage}
        type={type}
        shouldDismissAutomatically={shouldDismissAutomatically}
      />
    );
  };

  return (
    <HStack>
      <SidebarWrapper />
      <VStack width="100%">
        <TopBar />
        <VStack width="100%">
          {message !== '' && type !== 'error' && renderFlashMessage(true, 'info')}
          {message !== '' && type === 'error' && renderFlashMessage(false, 'error')}
          <div style={{ width: '100%', padding: '2rem' }}>{children}</div>
          <Footer />
        </VStack>
      </VStack>
    </HStack>
  );
};

export default connector(AppLayout);
