import Loader from 'components/layout/Loader';
import { permissionsConstants } from 'fairxConstants';
import { PAGES } from 'pageConstants';
import React from 'react';
import Loadable from 'react-loadable';

const { subjects, actions } = permissionsConstants;

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  });

export const secureRoutes = [
  {
    path: PAGES.ORDERS_PAGE,
    Component: loadable(() => import('pages/fairx/orders')),
    exact: true,
    subject: subjects.ORDER,
    permittedActions: [actions.READ, actions.WRITE],
  },
  {
    path: PAGES.ORDER_DETAILS_PAGE,
    Component: loadable(() => import('pages/fairx/orderDetails')),
    exact: false,
    subject: subjects.ORDER,
    permittedActions: [actions.READ, actions.WRITE],
  },
  {
    path: PAGES.FILLS_PAGE,
    Component: loadable(() => import('pages/fairx/fills')),
    exact: true,
    subject: subjects.FILL,
    permittedActions: [actions.READ],
  },
  {
    path: PAGES.ORDERS_FILLS_PAGE,
    Component: loadable(() => import('pages/fairx/ordersFills')),
    exact: true,
    subject: subjects.ORDER_FILL,
    permittedActions: [actions.READ, actions.WRITE],
  },
  {
    path: PAGES.FIRMS_PAGE,
    Component: loadable(() => import('pages/fairx/firms')),
    exact: true,
    subject: subjects.FIRM,
    permittedActions: [actions.READ, actions.RISK, actions.SESSION_KILL],
  },
  {
    path: PAGES.USERS_PAGE,
    Component: loadable(() => import('pages/fairx/users')),
    exact: true,
    subject: subjects.USER,
    permittedActions: [actions.READ],
  },
  {
    path: PAGES.PRODUCTS_PAGE,
    Component: loadable(() => import('pages/fairx/products')),
    exact: true,
    subject: subjects.PRODUCT,
    permittedActions: [actions.READ],
  },
  {
    path: PAGES.PRODUCT_DETAILS_PAGE,
    Component: loadable(() => import('pages/fairx/productDetails')),
    exact: false,
    subject: subjects.PRODUCT,
    permittedActions: [actions.READ],
  },
  {
    path: PAGES.INSTRUMENTS_PAGE,
    Component: loadable(() => import('pages/fairx/instruments')),
    exact: true,
    subject: subjects.INSTRUMENT,
    permittedActions: [actions.READ],
  },
  {
    path: PAGES.INSTRUMENT_DETAILS_PAGE,
    Component: loadable(() => import('pages/fairx/instrumentDetails')),
    exact: false,
    subject: subjects.INSTRUMENT,
    permittedActions: [actions.READ],
  },
  {
    path: PAGES.CLEARING_PAGE,
    Component: loadable(() => import('pages/fairx/clearing')),
    exact: true,
    subject: subjects.CLEARING,
    permittedActions: [actions.READ],
  },
  {
    path: PAGES.POSITIONS_PAGE,
    Component: loadable(() => import('pages/fairx/clearingPositions')),
    exact: true,
    subject: subjects.POSITION,
    permittedActions: [actions.READ],
  },
  {
    path: PAGES.POSITION_LIMIT_SUBSCRIPTIONS_PAGE,
    Component: loadable(() => import('pages/fairx/positionLimitSubscriptions')),
    exact: true,
    subject: subjects.POSITION_LIMIT_SUBSCRIPTION_ALERT,
    permittedActions: [actions.READ, actions.write],
  },
  {
    path: PAGES.SETTLEMENTS_PAGE,
    Component: loadable(() => import('pages/fairx/settlements')),
    exact: true,
    subject: subjects.SETTLEMENT,
    permittedActions: [actions.READ],
  },
  {
    path: PAGES.AUDIT_PAGE,
    Component: loadable(() => import('pages/fairx/audit')),
    exact: true,
    subject: subjects.AUDIT,
    permittedActions: [actions.READ],
  },
  {
    path: PAGES.USER_MANAGEMENT_PAGE,
    Component: loadable(() => import('pages/fairx/userManagement')),
    exact: true,
    subject: subjects.USER,
    permittedActions: [actions.READ],
  },
  {
    path: PAGES.ACTIVE_CONNECTIONS_PAGE,
    Component: loadable(() => import('pages/fairx/activeConnections')),
    exact: true,
    subject: subjects.ACTIVE_CONNECTIONS,
    permittedActions: [actions.READ],
  },
  {
    path: PAGES.ORDER_BOOK_PAGE,
    Component: loadable(() => import('pages/fairx/orderBook')),
    exact: true,
    subject: subjects.ORDER_BOOK,
    permittedActions: [actions.READ],
  },
  {
    path: PAGES.TRANSITIONS_PAGE,
    Component: loadable(() => import('pages/fairx/transitions')),
    subject: subjects.TRANSITIONS,
    permittedActions: [actions.READ, actions.WRITE],
  },
  {
    path: PAGES.EVENT_VIEWER,
    Component: loadable(() => import('pages/fairx/eventViewer')),
    subject: subjects.EVENT_VIEWER,
    permittedActions: [actions.READ, actions.WRITE],
  },
  {
    path: PAGES.ORDER_BOOK_RECREATION_PAGE,
    Component: loadable(() => import('pages/fairx/orderBookRecreation')),
    subject: subjects.ORDER_BOOK_RECREATION,
    permittedActions: [actions.READ],
  },
  {
    path: PAGES.ADMIN_CLI,
    Component: loadable(() => import('pages/fairx/adminCli')),
    subject: subjects.ADMIN_CLI,
    permittedActions: [actions.READ, actions.WRITE],
  },
  {
    path: PAGES.LMM,
    Component: loadable(() => import('pages/fairx/lmm')),
    subject: subjects.LMM,
    permittedActions: [actions.READ, actions.WRITE],
  },
  // Dashboards
  /*
  {
    path: PAGES.DASHBOARD_ANALYTICS_PAGE,
    Component: loadable(() => import('pages/dashboard/analytics')),
    exact: true,
  },
  */
];

export const routes = [
  // System Pages
  {
    path: PAGES.LOGIN_PAGE,
    Component: loadable(() => import('pages/system/login')),
    exact: true,
  },
  {
    path: PAGES.NOT_FOUND_ERROR_PAGE,
    Component: loadable(() => import('pages/system/404')),
    exact: true,
  },
  {
    path: PAGES.INTERNAL_ERROR_PAGE,
    Component: loadable(() => import('pages/system/500')),
    exact: true,
  },
];
