import { all, call, put, select, spawn, takeEvery } from 'redux-saga/effects';
import { SET_STATE } from 'redux/holidays/actions';
import { setLoadingSpinner, unsetLoadingSpinner } from 'redux/loadingSpinners';
import getVersionInfo from 'services/getVersionInfo';
import { isExchangeAdminUser } from 'utils/isExchangeUser';

import actions, { SetFlashMessageAction } from '../application/actions';
import { loadClearingDataSaga } from '../clearing/sagas';
import { loadFirmsPositions } from '../firms/sagas';
import { loadHolidaysSaga } from '../holidays/sagas';
import { LOAD_PRODUCTS } from '../products/sagas';
import { RootState } from '../reducers';
import { setErrorMessage } from '../setMessage';
import { loadPermissions } from '../userManagement/sagas';
import { LOAD_USERS } from '../users/sagas/userSagas';

export function* loadVersionInfo() {
  yield setLoadingSpinner(SET_STATE);

  const { response, error } = yield call(getVersionInfo);
  if (!error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        versionInfo: response.data,
        loading: false,
      },
    });
  } else {
    yield setErrorMessage(error);
  }
  yield unsetLoadingSpinner(SET_STATE);
}

export function* getAllData() {
  const state: RootState = yield select();
  const { adminUserRole, adminOperations } = state.userPermissions;
  const isExchangeUser = isExchangeAdminUser(adminUserRole);
  const loadUsersData = adminOperations.includes('SESSION_KILL') || isExchangeUser;

  // Only load that data which is permitted to be seen by the user.
  const sagas = [
    LOAD_PRODUCTS,
    loadFirmsPositions,
    loadVersionInfo,
    ...(loadUsersData ? [LOAD_USERS] : []),
    // the below data is strictly exchange admin only data.
    ...(isExchangeUser ? [loadPermissions, loadHolidaysSaga, loadClearingDataSaga] : []),
  ];

  yield all(
    sagas.map((saga: any) =>
      spawn(function* callSagas() {
        try {
          yield call(saga, {});
        } catch (e) {
          yield setErrorMessage(e);
        }
      }),
    ),
  );
}

export function* dismissFlashMessage() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      flashMessage: {
        message: '',
        description: '',
        type: '',
      },
    },
  });
}

export function* setFlashMessage({ payload }: SetFlashMessageAction) {
  yield put({
    type: actions.SET_STATE,
    payload,
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_ALL_DATA, getAllData),
    takeEvery(actions.DISMISS_FLASH_MESSAGE, dismissFlashMessage),
    takeEvery(actions.SET_FLASH_MESSAGE, setFlashMessage),
    takeEvery(actions.LOAD_VERSION_INFO, loadVersionInfo),
  ]);
}
